import React, { useEffect, useRef, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import { Row, Col } from 'reactstrap';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate ,Link} from 'react-router-dom';
import { useGetSiteLayoutsMutation } from '../../features/property/propertySlice';
import DataTable from 'react-data-table-component';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AddPropertyCategoryModal from '../../components/property/category/addPropertyCategoryModal';
import ViewPropertyDetailModal from '../../components/property/viewPropertyDetailModal';
import EditPropertyCategoryModal from '../../components/property/category/editPopertyCategoryModal';
import DeletePropertyModal from '../../components/property/deletePropertyModal';
import AddNewLayoutModal from '../../components/settings/addNewLayoutModal';
import ViewLayoutModal from '../../components/settings/viewLayoutModal';
import EditLayoutModal from '../../components/settings/editLayoutModal';
import DeleteLayoutModal from '../../components/settings/deleteLayoutModal';
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

function WebsiteLayout() {
    const [getSettings, { isLoading }] = useGetSiteLayoutsMutation();
    const formRef = useRef(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();
    const [siteLayout,setSiteLayout]=useState([]);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const[pending,setPending]=useState(false);
    const [layoutDetail, setLayoutDetail] = useState(null);

    const [viewLayoutModal, setViewLayoutModal] = useState(false);
    const [editlayoutModal, setEditModal] = useState(false);
    const [deleteLayoutModal, setDeleteLayoutModal] = useState(false);


    const handleGetLayouts=async() => {

        try{
          
            const response = await getSettings().unwrap();
            console.log(response)
            setSiteLayout(response);
          

        }
        catch(e){
            console.error("Error fetching site layouts", e);
        }
    }

    useEffect(()=>{
        handleGetLayouts();
    },[])

    const toggleViewModal = () => {
        setViewLayoutModal(!viewLayoutModal);
    }

    const toggleDeleteModal = () => {
        setDeleteLayoutModal(!deleteLayoutModal);
        handleGetLayouts();
    }

    const handleGetLayoutDetail = (row) => {
        setLayoutDetail(row);
        setViewLayoutModal(true);
    }

    const handleEditLayout = (row) => {
        setLayoutDetail(row);
        setEditModal(true);
    }

    const handleDeleteLayout = (row) => {
        setLayoutDetail(row);
        setDeleteLayoutModal(true);
    }
    const [layoutModal, setLayoutModal] = useState(false);

    const toggleModal = () => {
        setLayoutModal(!layoutModal);
        handleGetLayouts();
    }
    const toggleEditModal = () => {
        setEditModal(!editlayoutModal);
        handleGetLayouts();
    }


    const columns = [
        {
            id: 'id',
            name: 'Image 1',
            selector: row => (
                <div style={{ width: "45px", height: "45px" }} className='rounded-circle my-2'>
                <img src={row.image1} style={{ objectFit: "cover", height: "100%", width: "100%" }} alt="image" />
            </div>
            ),
            width: "fit-content", // Set width for Image column
        },
        {
        
            name: 'Image 2',
            selector: row => (
                <div style={{ width: "45px", height: "45px" }} className='rounded-circle my-2'>

{
                    row.image2?
                    <img src={row.image2} style={{ objectFit: "cover", height: "100%", width: "100%" }} alt="image" />
                    :""

                }
            </div>
            ),
            width: "fit-content", // Set width for Image column
        },
        {
          
            name: 'Image 3',
            selector: row => (
                <div style={{ width: "45px", height: "45px" }} className='rounded-circle my-2'>
                {
                    row.image3?
                    <img src={row.image3} style={{ objectFit: "cover", height: "100%", width: "100%" }} alt="image" />
                    :""

                }

            </div>
            ),
            width: "fit-content", // Set width for Image column
        },
        {
            name: 'Title',
            selector: row => (
                <div >
                <h5 className='text-wrap'>{row.title}</h5>
                
                
            </div>
            ),
            width:'25%', 
        },
        {
            name: 'Description',
            selector: row => (
                <div >
                <p className='text-wrap'>{row.description}</p>
              
                
            </div>
            ),
            width: '25%', 
        },

       
   
        
        
        {
            name: 'Actions',
            cell: row => (
                
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div
                        className='text-secondary action-link'
                        size="sm"
                        onClick={() => handleGetLayoutDetail(row)}
                    >
                        <i className="ri-eye-fill mr-1"></i>
                        View
                    </div>
                    <div onClick={()=>{handleEditLayout(row)}} className='text-primary action-link mx-3' size="sm"  to={`/properties/edit/${row.id}`}>
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </div>
                    <div className='text-danger action-link' onClick={() => handleDeleteLayout(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),

            width: "fit-content",
        },
    ];
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

  
    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const filteredItems = siteLayout.filter(
        item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex justify-content-between mb-4'>
                                <h4 className="card-title">Layouts</h4>
                                <button  type="button" onClick={toggleModal}  className="btn btn-primary btn-sm mr-2">New Layout </button>

                                <AddNewLayoutModal layoutModal={layoutModal}  toggleModal={toggleModal} />
                                <ViewLayoutModal viewLayoutModal={viewLayoutModal} toggleViewModal={toggleViewModal} layoutDetail={layoutDetail} />
                                <EditLayoutModal editlayoutModal={editlayoutModal} toggleEditModal={toggleEditModal} layoutDetail={layoutDetail} />
                                <DeleteLayoutModal deleteLayoutModal={deleteLayoutModal} toggleDeleteModal={toggleDeleteModal} layoutDetail={layoutDetail} />


                       
                            </div>
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className='text-primary d-flex align-content-center hover-icon'>
                                        <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                    </div>
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Title..."
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ minWidth: '1000px' }}>
                                        <DataTable
                                            columns={columns}
                                            data={filteredItems}
                                            pagination
                                            selectableRows
                                            onSelectedRowsChange={handleChange}
                                            clearSelectedRows={toggledClearRows}
                                            selectableRowsComponentProps={{
                                                type: 'checkbox',
                                            }}
                                            selectableRowsHighlight
                                            progressPending={pending}
                                            progressComponent={
                                                <div style={{ width: "100%" }}>
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default WebsiteLayout;
