import React from 'react';
import ReactApexChart from 'react-apexcharts';

function FinancialChart({ data }) {
    const chartData = {
        series: [
            {
                name: 'Commission from Hosts',
                data: Object.values(data.commission_from_hosts)
            },
            {
                name: 'Commission from Users',
                data: Object.values(data.commission_from_users)
            }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%',
                    endingShape: 'rounded'
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: Object.keys(data.commission_from_hosts)
            },
            yaxis: {
                title: {
                    text: 'Price ($)'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$" + val + "k";
                    }
                }
            }
        }
    };

    return (
        <div className="w-100">
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={400}
            />
        </div>
    );
}

export default FinancialChart;
