import React,{useEffect, useState} from 'react'
import { Modal } from 'reactstrap'
import { useViewCategoryDetailMutation } from '../../../features/property/propertySlice';
function ViewPropertyCategoryModal({viewPropertyCategoryModal,toggleViewModal,categoryDetail}) {





    const [formData, setFormData] = useState({
        name: " ",
        icon: " ",
        slug: " ",
        description: " "
      });
  

      useEffect(()=>{

        setFormData(categoryDetail)

        console.log(categoryDetail)
      },[categoryDetail])




  return (
    <Modal size='lg' className='modal-content' isOpen={viewPropertyCategoryModal} toggle={toggleViewModal}>
    <div className="modal-header">
      <h3 className="card-title">{categoryDetail&&categoryDetail.name}</h3>
      <button type="button" className="close" onClick={toggleViewModal} aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div className='p-2 ' >
      <div className='modal-body'>


      



        <div className="row border p-2 rounded-sm">
          <div className="col">
            <label>Name <span className='text-danger'>*</span></label>
            <input type="text" name='name' className="form-control" required placeholder="" value={ formData&& formData.name} disabled/>
          </div>
          <div className="col">
            <label>Slug <span className='text-danger'>*</span></label>
            <input type="text" name='slug' className="form-control" required placeholder="" value={formData&& formData.slug} disabled />
          </div>
          <div className='col-12 mt-2'>
            <div className="form-group">
              <label>Icon <span className='text-danger'>*</span></label>
              <select className="form-control" required name="icon" value={formData&& formData.icon} disabled>
                <option selected disabled={true} hidden={true} value="">Select an option</option>
            
                <option value="fi-real-estate-house">
                          Real Estate House
                      </option>
                                          <option value="fi-apartment">
                          Apartment
                      </option>
                                          <option value="fi-shop">
                          Shop
                      </option>
                                          <option value="fi-rent">
                          Rent
                      </option>
                                          <option value="fi-house-chosen">
                          House Chosen
                      </option>
                                          <option value="fi-single-bed">
                          Single Bed
                      </option>
                                          <option value="fi-computer">
                          Computer
                      </option>
                                          <option value="fi-real-estate-buy">
                          Real Estate Buy
                      </option>
                                          <option value="fi-parking">
                          Parking
                      </option>
                                          <option value="fi-car">
                          Car
                      </option>
                                          <option value="fi-building">
                          Building
                      </option>
                                          <option value="fi-swimming-pool">
                          Swimming Pool
                      </option>
              </select>
            </div>
          </div>
        </div>

        <div className="row border p-2 rounded-sm mt-5">
          <div className="col-12">
            <label>Description</label>
            <textarea className="form-control" disabled name="description" rows="5" value={formData&&formData.description} ></textarea>
          </div>
        </div>
      </div>

      <div className='modal-footer'>
      

       
        <button className="btn iq-bg-primary" onClick={toggleViewModal}>Close</button>
      </div>
    </div>
  </Modal>
  )
}

export default ViewPropertyCategoryModal