import React, { useEffect, useState, useRef } from 'react';
import { FilePond } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useGetUserDetailMutation,useEditUserMutation } from '../../features/property/propertySlice';
import { useParams } from 'react-router-dom';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import { Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

function EditUserPage() {
  const [files, setFiles] = useState([]);
  const [getuserDetail, { isLoading }] = useGetUserDetailMutation();
  const [editUser, { isLoading:isEditing }] = useEditUserMutation();
  const [userDetail, setUserDetail] = useState({});
  const { id } = useParams();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const handleGetUserDetail = async () => {
    try {
      const response = await getuserDetail(id).unwrap();
      console.log(response);
      setUserDetail(response);

      if (response.profile) {
        fetch(`https://dev-api.shortstaybuddy.com${response.profile}`)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], 'first_image.jpg', { type: blob.type });

            setFiles([
              {
                source: file,
                options: {
                  type: 'local'
                }
              }
            ]);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetail((prevDetail) => ({
      ...prevDetail,
      [name]: value,
    }));
  };


  const handleSubmit = async(e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      const formData = new FormData();
      
      // Append fields that are in the form but not in userDetail
      formData.append('role', userDetail.role || '');
      formData.append('pk', userDetail.id|| '');

      formData.append('dob', userDetail.dob || '');
      formData.append('company_name', userDetail.company_name || '');
      formData.append('national_id_type', userDetail.national_id_type || '');
      formData.append('national_id_number', userDetail.national_id_number || '');
      formData.append('description', userDetail.description || '');
      formData.append('facebook_link', userDetail.facebook_link || '');
      formData.append('twitter_link', userDetail.twitter_link || '');
      formData.append('instagram_link', userDetail.instagram_link || '');
      formData.append('tiktok_link', userDetail.tiktok_link || '');
      formData.append('linkedin_link', userDetail.linkedin_link || '');
      formData.append('whatsapp_number', userDetail.whatsapp_number || '');
      formData.append('address', userDetail.address || '');
      formData.append('nationality', userDetail.nationality || '');
      formData.append('gender', userDetail.gender || '');
      
      // Handle the profile file (if a new file is uploaded)
      if (files.length > 0) {
        formData.append('profile', files[0].file);
      }
      
      // You can now use formData to make an API request
      console.log('Form Data prepared:', formData);
      // Example: You can send this formData via an API request here
      try {

        const response=await editUser({id:userDetail.id,formData:formData});
        console.log(response)
        

      }
      catch (error) {
        console.error('Error editing user:', error);
      }
  
    } else {
      formRef.current.classList.add("was-validated");
    }
  };
  

  useEffect(() => {
    handleGetUserDetail();
  }, []);

  return (
    <>
      <NavbarAndSideBar />
      <div className="content-page" id="content-page">
        <div className="container-fluid">
          <Row style={{ marginBottom: "100px" }}>
            <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
              <div className='d-flex justify-content-between'>
                <div>
                  <h3 className="card-title">
                    {userDetail?.first_name + " " + userDetail?.last_name}
                  </h3>
                </div>
              </div>
            </Col>
            <Col sm="12 mb-5">
              <form onSubmit={handleSubmit} ref={formRef} className='w-100 needs-validation px-2' noValidate>
                <div className='w-100'>
               
                  <div className="row border p-2 rounded-sm mt-3">
                    <div className="col-12">
                      <label>Image <span className='text-danger'>*</span></label>
                      <FilePond
                        files={files}
                        allowMultiple={false}
                        name="profile"
                        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                        onupdatefiles={setFiles}
                      />
                    </div>

                    <div className='col-md-6'>
                      <label>First Name<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='first_name'
                        className="form-control"
                        value={userDetail?.first_name || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Last Name<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='last_name'
                        className="form-control"
                        value={userDetail?.last_name || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Email<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='email'
                        className="form-control"
                        value={userDetail?.email || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Phone<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='phone_number'
                        className="form-control"
                        value={userDetail?.phone_number || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Address<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='address'
                        className="form-control"
                        value={userDetail?.address || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Company Name<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='company_name'
                        className="form-control"
                        value={userDetail?.company_name || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row border p-2 rounded-sm mt-3">
                    <div className='col-md-6'>
                      <label>ID Type<span className='text-danger'>*</span></label>
                      <select
                        name="national_id_type"
                        className="form-control"
                        value={userDetail?.national_id_type || ''}
                        onChange={handleChange}
                      >
                        <option value=" " disabled hidden>Select ID Type</option>
                        <option value="national_id">National ID</option>
                        <option value="passport">Passport</option>
                        <option value="driving_license">Driving License</option>
                        <option value="student_id">Student ID</option>
                      </select>
                    </div>
                    <div className='col-md-6'>
                      <label>ID Number<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='national_id_number'
                        className="form-control"
                        value={userDetail?.national_id_number || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Gender<span className='text-danger'>*</span></label>
                      <select
                        name="gender"
                        className="form-control"
                        value={userDetail?.gender || ''}
                        onChange={handleChange}
                      >
                        <option value=" " hidden disabled>Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                    <div className='col-md-6'>
                      <label>Date Of Birth<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='dob'
                        className="form-control"
                        value={userDetail?.dob || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Nationality<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='nationality'
                        className="form-control"
                        value={userDetail?.nationality || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Role<span className='text-danger'>*</span></label>
                      <select
                        name='role'
                        className="form-control"
                        value={userDetail?.role || ''}
                        onChange={handleChange}
                      >
                        <option value=" " disabled hidden>Select role</option>
                        <option value="user">User</option>
                        <option value="host">Host</option>
                      </select>
                    </div>
                  </div>

                  <div className="row border p-2 rounded-sm mt-3">
                    <label>Bio<span className='text-danger'>*</span></label>
                    <textarea
                      className="form-control"
                      name="description"
                      rows="3"
                      value={userDetail?.description || ''}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="row border p-2 rounded-sm mt-3">
                    <div className='col-md-6'>
                      <label>Facebook Link<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='facebook_link'
                        className="form-control"
                        value={userDetail?.facebook_link || ''}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='col-md-6'>
                      <label>Twitter Link<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='twitter_link'
                        className="form-control"
                        value={userDetail?.twitter_link || ''}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='col-md-6'>
                      <label>Instagram Link<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='instagram_link'
                        className="form-control"
                        value={userDetail?.instagram_link || ''}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='col-md-6'>
                      <label>Tiktok Link<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='tiktok_link'
                        className="form-control"
                        value={userDetail?.tiktok_link || ''}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='col-md-6'>
                      <label>LinkedIn Link<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='linkedin_link'
                        className="form-control"
                        value={userDetail?.linkedin_link || ''}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='col-md-6'>
                      <label>WhatsApp Number<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='whatsapp_number'
                        className="form-control"
                        value={userDetail?.whatsapp_number || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className='my-3 px-2'>
                  {
                    isEditing?

                    <button type="submit" className="btn btn-primary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Saving...</button>
                    :
                    <button type="submit" className="btn mb-3 btn-primary">Save Changes</button>


                  }
                  </div>
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default EditUserPage;
