import React, { useEffect, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import DataTable from 'react-data-table-component';
import { Col, Row } from "reactstrap";
import { useGetPostTagsMutation } from '../../features/property/propertySlice';
import AddBlogTagsModal from '../../components/blog/addBogTagModal';
import EditPostTagModal from '../../components/blog/editPostTagModal';
import DeleteBlogTagModal from '../../components/blog/deleteBlogTagModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CustomPagination from '../../components/basic/customPagination';

function PostTags() {
    const [getTags, { isLoading }] = useGetPostTagsMutation();
    const [tags, setTags] = useState([]);
    const [tagsDetail, setTagDetail] = useState(null);
    const [pending, setPending] = useState(true);

    const handleGetCategories = async () => {
        try {
            const response = await getTags().unwrap();
            setTags(response);
            console.log(response);
        } catch (e) {
            console.log(e);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        handleGetCategories();
    }, []);

    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const filteredItems = tags.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const [addBlogModal, setAddBlogModal] = useState(false);
    const toggleModal = () => {
        setAddBlogModal(!addBlogModal);
        handleGetCategories();
    }

    const [editCategoryModal, setEditCategoryModal] = useState(false);
    const [deleteBlogCategoryModal, setDeleteBlogCategoryModal] = useState(false);

    const toggleEditModal = (row) => {
        setEditCategoryModal(!editCategoryModal);
    
        handleGetCategories();
    }

    const toggleDeleteModal = () => {
        setDeleteBlogCategoryModal(!deleteBlogCategoryModal);
        handleGetCategories();
    }


    const handleEditCategory = (row) => {
        setTagDetail(row);
        setEditCategoryModal(true);
    }

    const handleDeleteCategory = (row) => {
        setTagDetail(row);
        setDeleteBlogCategoryModal(true);
    }


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
      
        return `${String(day).padStart(2, '0')} ${month}, ${year}`;
      };

    const columns = [
      
        {
            name: 'Name',
            selector: row => row.name,
        },
      
        {
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
   
                    <div className='text-primary action-link mx-3' size="sm" onClick={() => handleEditCategory(row)}>
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </div>
                    <div className='text-danger action-link' onClick={() => handleDeleteCategory(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex justify-content-between mb-4'>
                                <h4 className="card-title">Post Tags</h4>
                                <button type="button" onClick={toggleModal} className="btn btn-primary btn-sm mr-2">New Post Tag</button>
                                <AddBlogTagsModal addBlogModal={addBlogModal} toggleModal={toggleModal} />
                                <EditPostTagModal editCategoryModal={editCategoryModal} toggleEditModal={toggleEditModal} tagsDetail={tagsDetail} />
                                <DeleteBlogTagModal deleteBlogCategoryModal={deleteBlogCategoryModal} toggleDeleteModal={toggleDeleteModal} tagsDetail={tagsDetail} />
                            </div>
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className='text-primary d-flex align-content-center hover-icon'>
                                        <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                    </div>
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Name..."
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ minWidth: '1000px' }}>
                                        <DataTable
                                            columns={columns}
                                            data={filteredItems}
                                            pagination
                                            selectableRows
                                            onSelectedRowsChange={handleChange}
                                            clearSelectedRows={toggledClearRows}
                                            selectableRowsComponentProps={{
                                                type: 'checkbox',
                                            }}
                                            selectableRowsHighlight
                                            progressPending={pending}
                                            progressComponent={
                                                <div style={{ width: "100%",padding:"10px" }}>
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                </div>
                                            }
                                            
                                            defaultSortFieldId="id" // Sorting by 'Name' column
                                            defaultSortAsc={false}
                                            paginationComponent={CustomPagination}

                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default PostTags;
