import React, { useEffect, useState, useRef } from 'react';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useParams, useNavigate } from 'react-router-dom';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import { Col, Row } from 'reactstrap';
import { useRegisterUserMutation } from '../../features/property/propertySlice';

function AddUserPage() {
  const [files, setFiles] = useState([]);
  const [registeruser, { isLoading }] = useRegisterUserMutation();
  const [userDetail, setUserDetail] = useState({});
  const { id } = useParams();
  const formRef = useRef(null);
  const navigate = useNavigate();

  // Generic handleChange function to update state
  const handleChange = (e) => {
    const { name, value, type } = e.target;

    let finalValue = value;
    if (type === 'checkbox') {
      finalValue = e.target.checked;
    }

    setUserDetail((prevDetail) => ({
      ...prevDetail,
      [name]: finalValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      const formData = new FormData();

      // Append all fields from userDetail to formData
      for (const key in userDetail) {
        if (userDetail.hasOwnProperty(key)) {
          formData.append(key, userDetail[key]);
        }
      }

      // Handle the profile file (if a new file is uploaded)
      if (files.length > 0) {
        formData.append('profile', files[0].file);
      }

      console.log('Form Data prepared:', formData);
      try {
        const response = await registeruser(formData);
        console.log(response);
        // Navigate to another page or show a success message
      } catch (error) {
        console.error('Error editing user:', error);
      }
    } else {
      formRef.current.classList.add('was-validated');
    }
  };

  return (
    <>
      <NavbarAndSideBar />
      <div className="content-page" id="content-page">
        <div className="container-fluid">
          <Row style={{ marginBottom: "100px" }}>
            <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
              <div className='d-flex justify-content-between'>
                <div>
                  <h3 className="card-title">
                    Add New User
                  </h3>
                </div>
              </div>
            </Col>
            <Col sm="12 mb-5">
              <form onSubmit={handleSubmit} ref={formRef} className='w-100 needs-validation px-2' noValidate>
                <div className='w-100'>

                  <div className="row border p-2 rounded-sm mt-3">
                    <div className="col-12">
                      <label>Image <span className='text-danger'>*</span></label>
                      <FilePond
                        files={files}
                        allowMultiple={false}
                        name="profile"
                        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                        onupdatefiles={setFiles}
                      />
                    </div>

                    <div className='col-md-6'>
                      <label>First Name<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='first_name'
                        className="form-control"
                        value={userDetail?.first_name || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Last Name<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='last_name'
                        className="form-control"
                        value={userDetail?.last_name || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Email<span className='text-danger'>*</span></label>
                      <input
                        type="email"
                        name='email'
                        className="form-control"
                        value={userDetail?.email || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Phone<span className='text-danger'>*</span></label>
                      <input
                        type="tel"
                        name='phone_number'
                        className="form-control"
                        value={userDetail?.phone_number || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>


                    <div className='col-md-6'>
                      <label>Password<span className='text-danger'>*</span></label>
                      <input
                        type="email"
                        name='password'
                        className="form-control"
                        value={userDetail?.password || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className='col-md-6'>
                      <label>Address<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='address'
                        className="form-control"
                        value={userDetail?.address || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Company Name<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='company_name'
                        className="form-control"
                        value={userDetail?.company_name || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row border p-2 rounded-sm mt-3">
                    <div className='col-md-6'>
                      <label>ID Type<span className='text-danger'>*</span></label>
                      <select
                        name="national_id_type"
                        className="form-control"
                        value={userDetail?.national_id_type || ''}
                        onChange={handleChange}
                      >
                        <option value="" disabled hidden>Select ID Type</option>
                        <option value="national_id">National ID</option>
                        <option value="passport">Passport</option>
                        <option value="driving_license">Driving License</option>
                        <option value="student_id">Student ID</option>
                      </select>
                    </div>
                    <div className='col-md-6'>
                      <label>ID Number<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='national_id_number'
                        className="form-control"
                        value={userDetail?.national_id_number || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Gender<span className='text-danger'>*</span></label>
                      <select
                        name="gender"
                        className="form-control"
                        value={userDetail?.gender || ''}
                        onChange={handleChange}
                      >
                        <option value="" hidden disabled>Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                    <div className='col-md-6'>
                      <label>Date Of Birth<span className='text-danger'>*</span></label>
                      <input
                        type="date"
                        name='dob'
                        className="form-control"
                        value={userDetail?.dob || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Nationality<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='nationality'
                        className="form-control"
                        value={userDetail?.nationality || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Role<span className='text-danger'>*</span></label>
                      <select
                        name='role'
                        className="form-control"
                        value={userDetail?.role || ''}
                        onChange={handleChange}
                      >
                        <option value="" disabled hidden>Select role</option>
                        <option value="user">User</option>
                        <option value="host">Host</option>
                      </select>
                    </div>
                  </div>

                  <div className="row border p-2 rounded-sm mt-3">
                    <label>Bio<span className='text-danger'>*</span></label>
                    <textarea
                      className="form-control"
                      name="description"
                      rows="3"
                      value={userDetail?.description || ''}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="row border p-2 rounded-sm mt-3">
                    <div className='col-md-6'>
                      <label>Facebook Link<span className='text-danger'>*</span></label>
                      <input
                        type="url"
                        name='facebook_link'
                        className="form-control"
                        value={userDetail?.facebook_link || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Instagram Link<span className='text-danger'>*</span></label>
                      <input
                        type="url"
                        name='instagram_link'
                        className="form-control"
                        value={userDetail?.instagram_link || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Twitter Link<span className='text-danger'>*</span></label>
                      <input
                        type="url"
                        name='twitter_link'
                        className="form-control"
                        value={userDetail?.twitter_link || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>LinkedIn Link<span className='text-danger'>*</span></label>
                      <input
                        type="url"
                        name='linkedin_link'
                        className="form-control"
                        value={userDetail?.linkedin_link || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className='my-3 px-2'>
                  {
                    isLoading?

                    <button type="submit" className="btn btn-primary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Saving...</button>
                    :
                    <button type="submit" className="btn mb-3 btn-primary">Save </button>


                  }
                  </div>

                </div>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AddUserPage;
