import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import DataTable from 'react-data-table-component';
import { Col, Row } from "reactstrap";
import { useGetEventsMutation } from '../../features/property/propertySlice';
import AddPropertyCategoryModal from '../../components/property/category/addPropertyCategoryModal';
import ViewEventModal from '../../components/enquiryandfaqs/events/viewEventModal';
import EditPropertyCategoryModal from '../../components/property/category/editPopertyCategoryModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { format } from 'date-fns';
import CustomPagination from '../../components/basic/customPagination';
import DeleteContactsModal from '../../components/enquiryandfaqs/contacts/deleteContactsModal';
import DeleteEventModal from '../../components/enquiryandfaqs/events/deleteEventModal';
import { Link } from 'react-router-dom';

function Event() {
    const navigate = useNavigate();
    const [getEvents, { isLoading }] = useGetEventsMutation();
    const [events, setEvents] = useState([]);
    const [eventDetail, setEventDetail] = useState(null);
    const [pending, setPending] = useState(true);

    const handleGetCategories = async () => {
        try {
            const response = await getEvents().unwrap();
            setEvents(response.results);
            console.log(response);
        } catch (e) {
            console.log(e);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        handleGetCategories();
    }, []);

    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const filteredItems = events.filter(
        item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
    );

    const [propertyCategoryModal, setPropertyCategoryModal] = useState(false);
    const toggleModal = () => {
        setPropertyCategoryModal(!propertyCategoryModal);
        handleGetCategories();
    }

    const [viewEventModal, setViewEventModal] = useState(false);
    const [editPropertyCategoryModal, setEditPropertyCategoryModal] = useState(false);
    const [deleteEventModal, setDeleteEventModal] = useState(false);

    const toggleViewModal = () => {
        setViewEventModal(!viewEventModal);
    }

    const toggleEditModal = () => {
        setEditPropertyCategoryModal(!editPropertyCategoryModal);
        handleGetCategories();
    }

    const toggleDeleteModal = () => {
        setDeleteEventModal(!deleteEventModal);
        handleGetCategories();
    }

    const handleGetCategoryDetail = (row) => {
        setEventDetail(row);
        setViewEventModal(true);
    }

    const handleDeleteCategory = (row) => {
        setEventDetail(row);
        setDeleteEventModal(true);
    }

    const handleRowClick = (row) => {
        localStorage.setItem("event",row.id)
        navigate(`/events/edit/${row.id}`); // Navigate to the event detail page
    }

    const columns = [
        {
            id: 'id',
            name: 'Title',
            selector: row => (
                <div onClick={() => handleRowClick(row)}>
                    <h6>{row.title}</h6>
                    <p className='text-muted'>{row.location}</p>
                </div>
            ),
            wrap:true,
            width: '20%', // Set width for Title column
        },
        {
            name: 'Image',
            selector: row => (
                <div style={{ width: "45px", height: "45px" }} className='rounded-circle my-2'>
                    <img src={row.featured_image} style={{ objectFit: "cover", height: "100%", width: "100%" }} alt="event" />
                </div>
            ),
            width: '10%', // Set width for Image column
        },
        {
            name: 'Start & End Date',
            selector: row => (
                <div>
                    <h6>{format(new Date(row.start_date), 'dd/MM/yyyy HH:mm')}</h6>
                    <h6 className='text-muted'>{format(new Date(row.end_date), 'dd/MM/yyyy HH:mm')}</h6>
                </div>
            ),
            width: '20%', // Set width for Start & End Date column
        },
        {
            name: 'Price',
            selector: row => <div>₵{row.price}</div>,
            width: '10%', // Set width for Price column
        },
        {
            name: 'Status',
            selector: row => (
                <div style={{ width: "100px" }}>
                    <button type="button" className="btn mb-1 iq-bg-primary">
                        {row.event_type}
                    </button>
                </div>
            ),
            width: '10%', // Set width for Status column
        },
        {
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div
                        className='text-secondary action-link'
                        size="sm"
                        onClick={() => handleGetCategoryDetail(row)}
                    >
                        <i className="ri-eye-fill mr-1"></i>
                        View
                    </div>
                    <div className='text-primary action-link mx-3' size="sm" onClick={() => handleRowClick(row)}>
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </div>
                    <div className='text-danger action-link' onClick={() => handleDeleteCategory(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
            width: '30%', // Set width for Actions column
        },
    ];


    

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2' >
                            <div className='d-flex justify-content-between mb-4'>
                                <h4 className="card-title">Events</h4>
                                <Link to="/events/create" type="button"  className="btn btn-primary btn-sm mr-2">New event</Link>
                                <AddPropertyCategoryModal propertyCategoryModal={propertyCategoryModal} toggleModal={toggleModal} />
                                <ViewEventModal viewEventModal={viewEventModal} toggleViewModal={toggleViewModal} eventDetail={eventDetail} />
                                <EditPropertyCategoryModal editPropertyCategoryModal={editPropertyCategoryModal} toggleEditModal={toggleEditModal} eventDetail={eventDetail} />
                                <DeleteEventModal deleteEventModal={deleteEventModal} toggleDeleteModal={toggleDeleteModal} eventDetail={eventDetail} />
                            </div>
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className='text-primary d-flex align-content-center hover-icon'>
                                        <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                    </div>
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Title..."
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <div>
                                        <DataTable
                                            columns={columns}
                                            data={filteredItems}
                                            pagination
                                            selectableRows
                                            onSelectedRowsChange={handleChange}
                                            clearSelectedRows={toggledClearRows}
                                            selectableRowsComponentProps={{
                                                type: 'checkbox',
                                            }}
                                            selectableRowsHighlight
                                            progressPending={pending}
                                            progressComponent={
                                                <div style={{ width: "100%", padding: "10px" }}>
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                </div>
                                            }
                                 
                                                            defaultSortFieldId="id"
                                            defaultSortAsc={false}
                                            onRowClicked={handleRowClick}
                                            pointerOnHover={true}
                                            highlightOnHover
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Event;
