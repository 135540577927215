import React, { useEffect, useState } from 'react'
import { useViewPostDetailMutation } from '../../features/property/propertySlice'
import { useParams } from 'react-router-dom'
import { Col, Row } from "reactstrap";
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';


function ViewPostDetailsPage() {
    const { id } = useParams()
    const [viewPostDetail, {isLoading:isLoading}] = useViewPostDetailMutation()
    const [post,setPost]=useState()
    const [files, setFiles] = useState();
    const [tags,setTags]=useState();


    const handleGetPosts=async()=>{

        try{
            const response = await viewPostDetail(id).unwrap();
            console.log(response)
            setPost(response)

            const firstFileResponse = await fetch(`https://dev-api.shortstaybuddy.com${response.image}`);
            const firstBlob = await firstFileResponse.blob();
            const firstFile = new File([firstBlob], 'featured_image.jpg', { type: firstBlob.type });
            setFiles([{
              source: firstFile,
              options: {
                type: 'local'
              }
            }]);
      
            const tagNamesArray = ()=>{
                
              const   tags=Array.from(response.tags).map(element => element.name.toLowerCase());
                console.log(tags);
            return tags.join(', ');
            }
            setTags(tagNamesArray)
        }catch(error){
            console.error(error);
        }
    }


    useEffect(()=>{
        handleGetPosts();
    },[])

  return (
    <>
    <NavbarAndSideBar />
    <div className="content-page" id="content-page">
      <div className="container-fluid">
        <Row style={{ marginBottom: "100px" }}>
          <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
            <div className='mb-4 d-flex flex-column'>
            
                <div className="">
                  <h3 className="card-title">{post && post.title}</h3>
                </div>

                <form className='w-100 col-12 needs-validation p-2' noValidate >
                 
               <div>
               <div className="row  border p-2 rounded-sm">
                     
                     <div className="col-md-6 mb-2">
                       <label>Author <span className='text-danger'>*</span></label>
                       <input
                         type="text"
                         name='author'
                         className="form-control"
                         required
                         disabled
                         placeholder=""
                         value={post&&post.author.first_name+" "+post.author.last_name}
                       />
                     </div>
     <div className="col-md-6">
                       <label>Category <span className='text-danger'>*</span></label>
                       <input
                         type="category"
                         name='author'
                         className="form-control"
                         required
                         value={post&&post.category.name}
                         disabled
                       />
                     </div>
                   </div>
               <div className="row  border p-2 rounded-sm">
                     
                     <div className="col-md-6 mb-2">
                       <label>Title <span className='text-danger'>*</span></label>
                       <input
                         type="text"
                         name='title'
                         className="form-control"
                         required
                         placeholder=""
                         value={post&&post.title}
                         disabled
                       />
                     </div>
     <div className="col-md-6">
                       <label>Slug <span className='text-danger'>*</span></label>
  
                       <input
                         type="text"
                         name='title'
                         className="form-control"
                         required
                         placeholder=""
                         value={post&&post.slug}
                         disabled
                       />
                     </div>

                     <div className="col-12">
                       <label>Excerpt <span className='text-danger'>*</span></label>
  
                       <textarea
                         type="text"
                         name='excerpt'
                         className="form-control"
                         required
                         placeholder=""
                        value={post&&post.excerpts}
                         disabled
                       >{post&&post.excerpts}</textarea>
                     </div>


                     <div className='col-12 mb-2'>
                        <label>Body<span className='text-danger'>*</span></label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={post&&post.body}
                          onReady={(editor) => {
                            console.log('Editor is ready to use!', editor);
                          }}
                          className="form-control"
                          disabled
                          
                        />


                      </div>

                      <div className="col-12 mb-2">
                        <label>Featured Image  <span className='text-danger'>*</span></label>
                        <FilePond
                          files={files}
                          allowMultiple={false}
                          onupdatefiles={setFiles}
                          required
                          disabled
                          labelIdle='<span className="filepond--label-action">Browse One</span>'
                        />
                      </div>
                   </div>

                   <div className="row  border p-2 rounded-sm">
                     
                     <div className="col-12 mb-2">
                       <label>Tags <span className='text-danger'>*</span></label>
                       <input
                         type="tag"
                         name='author'
                         className="form-control"
                         required
                         placeholder=""
                         value={tags&&tags}
                       />
                     </div>
     <div className="col-md-12">
                       <label>Meta Description
                       <span className='text-danger'>*</span></label>
                       <textarea
                         type="category"
                         name='author'
                         className="form-control"
                         required
                         value={post&&post.meta_description
                         }
                         disabled
                       ></textarea>
                     </div>


                     <div className="col-md-12">
                       <label>Meta keywords
                       <span className='text-danger'>*</span></label>
                       <textarea
                         type="category"
                         name='author'
                         className="form-control"
                         required
                         value={post&&post.meta_keywords
                         }
                         disabled
                       ></textarea>
                     </div>
                     <div className="col-md-6 mb-2">
                       <label>Seo Title <span className='text-danger'>*</span></label>
                       <input
                         type="tag"
                         name='author'
                         className="form-control"
                         required
                         placeholder=""
                         value={post&&post.seo_title}
                       />
                     </div>
                     <div className="col-md-6 mb-2">
                       <label>Status <span className='text-danger'>*</span></label>
                       <input
                         type="tag"
                         name='author'
                         className="form-control"
                         required
                         placeholder=""
                         value={post&&post.status}
                       />
                     </div>
                   </div>
               </div>
                </form>
         
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </>
  )
}

export default ViewPostDetailsPage