import React,{useEffect, useState} from 'react'
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar'
import { Row,Col } from 'reactstrap'
import { useNavigate } from 'react-router-dom';
import AddNewEventCategory from '../../components/enquiryandfaqs/events/newEventCategoryModal';
import ViewEventCategory from '../../components/enquiryandfaqs/events/viewEventCategory';
import DeleteEventCategoryModal from '../../components/enquiryandfaqs/events/deleteEventCategory';
import EditEventCategoryModal from '../../components/enquiryandfaqs/events/editEventCategoryModal';
import { useGetEventCategoryMutation } from '../../features/property/propertySlice';
import DataTable from 'react-data-table-component';
import Skeleton from 'react-loading-skeleton';

function EventsCategory() {
  const navigate = useNavigate();
  const [eventCatgories, setEventCategories] = useState([]);
  const [eventCategoryDetail, setEventCategoryDetail] = useState(null);
  const [pending, setPending] = useState(true);
  const [addEventCategoryModal, setAddEventCategoryModal] = useState(false);
  const [getEventCategory, {isLoading}]=useGetEventCategoryMutation()


  const toggleModal = () => {
    setAddEventCategoryModal(!addEventCategoryModal);
}


const handlegetEventCategory=async()=>{

  try{

    const response = await getEventCategory().unwrap();
    setEventCategories(response.results);
    console.log(response);

  }
  catch(e){
    console.log(e)
  }finally {
  setPending(false);
}

}

useEffect(()=>{
  handlegetEventCategory()
},[])


const [filterText, setFilterText] = useState('');
const [selectedRows, setSelectedRows] = useState([]);
const [toggledClearRows, setToggleClearRows] = React.useState(false)


const filteredItems = eventCatgories.filter(
  item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
);

const columns = [

  {
    name: 'Image',
    selector: row => (
        <div style={{ width: "45px", height: "45px" }} className='rounded-circle my-2'>
            <img src={row.image} style={{ objectFit: "cover", height: "100%", width: "100%" }} alt="event" />
        </div>
    ),
    width: '10%', // Set width for Image column
},
  {
      id: 'id',
      name: 'Title',
      selector: row => (
          <div >
              <h6>{row.title}</h6>
          </div>
      ),
      wrap:true,
      width: '30%', // Set width for Title column
  },
 
  
  {
      name: 'Slug',
      selector: row => <div>{row.slug}</div>,
      width: '20%', // Set width for Price column
  },

  {
      name: 'Actions',
      cell: row => (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div
                  className='text-secondary action-link'
                  size="sm"
                  onClick={() => handleGetEventCategoryDetail(row)}

              >
                  <i className="ri-eye-fill mr-1"></i>
                  View
              </div>
              <div onClick={()=>{handleEditEventCategory(row)}} className='text-primary action-link mx-3' size="sm" >
                  <i className="ri-pencil-fill mr-1"></i>
                  Edit
              </div>
              <div onClick={()=>{handleDeleteCategory(row)}} className='text-danger action-link'>
                  <i className="ri-delete-bin-fill mr-1"></i>Delete
              </div>
          </div>
      ),
      width: 'fit-content', // Set width for Actions column
  },
];



const [viewEventCategoryModal, setViewEventCategoryModal] = useState(false);
const [editEventCategoryModal, setEditEventCategoryModal] = useState(false);
const [deleteEventCategoryModal, setDeleteEventCategoryModal] = useState(false);

const toggleViewModal = () => {
    setViewEventCategoryModal(!viewEventCategoryModal);
}

const toggleEditModal = () => {
    setEditEventCategoryModal(!editEventCategoryModal);
    handlegetEventCategory();
}

const toggleDeleteModal = () => {
    setDeleteEventCategoryModal(!deleteEventCategoryModal);
    handlegetEventCategory();
}

const handleGetEventCategoryDetail = (row) => {
    setEventCategoryDetail(row);
    setViewEventCategoryModal(true);
}

const handleDeleteCategory = (row) => {
    setEventCategoryDetail(row);
    setDeleteEventCategoryModal(true);
}

const handleEditEventCategory = (row) => {
    setEventCategoryDetail(row);
    setEditEventCategoryModal(true);
}


  return (
  
    <>
    <NavbarAndSideBar />
    <div className="content-page" id="content-page">
        <div className="container-fluid">
            <Row style={{ marginBottom: "100px" }}>
                <Col sm="12" className='px-lg-5 px-md-3 px-sm-2' >
                    <div className='d-flex justify-content-between mb-4'>
                        <h4 className="card-title">Events Category</h4>
                        <button onClick={toggleModal}  type="button"  className="btn btn-primary btn-sm mr-2">New Event Category</button>
                        <AddNewEventCategory addEventCategoryModal={addEventCategoryModal} toggleModal={toggleModal}/>
                        <ViewEventCategory viewEventCategoryModal={viewEventCategoryModal} toggleViewModal={toggleViewModal} eventCategoryDetail={eventCategoryDetail} />
                        <EditEventCategoryModal editEventCategoryModal={editEventCategoryModal} toggleEditModal={toggleEditModal}  eventCategoryDetail={eventCategoryDetail} />
                        <DeleteEventCategoryModal deleteEventCategoryModal={deleteEventCategoryModal} toggleDeleteModal={toggleDeleteModal} eventCategoryDetail={eventCategoryDetail} />


                    </div>
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className='text-primary d-flex align-content-center hover-icon'>
                            </div>
                            <div className="search-box d-flex justify-content-end">
                                <input type="text" className="text search-input" placeholder="Search By Title..."
                                   
                                />
                            </div>
                        </div>
                        

                        <div style={{ overflowX: 'auto' }}>
                                    <div>
                                        <DataTable
                                            columns={columns}
                                            data={filteredItems}
                                            pagination
                                            selectableRows
                                            clearSelectedRows={toggledClearRows}
                                            selectableRowsComponentProps={{
                                                type: 'checkbox',
                                            }}
                                            selectableRowsHighlight
                                            progressPending={pending}
                                            progressComponent={
                                                <div style={{ width: "100%", padding: "10px" }}>
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                </div>
                                            }
                                 
                                                            defaultSortFieldId="id"
                                            defaultSortAsc={false}
                                            pointerOnHover={true}
                                            highlightOnHover
                                        />
                                    </div>
                                </div>


                    </div>
                </Col>
            </Row>
        </div>
    </div>
</>
  )
}

export default EventsCategory