import React, { useEffect, useState } from 'react'
import Navbar from './navbar'
import Sidebar from './sidebar'
function NavbarAndSideBar() {
    const [toogleSidebar,setToggleSidebar]=useState(false)

    const handletoggleSidebar=()=>{
        setToggleSidebar(!toogleSidebar)
        if(toogleSidebar){
            document.body.classList.remove('sidebar-main');
        }
        else{
            document.body.classList.add('sidebar-main');
        }
    }


    
    const handletoggleSidebar2=()=>{
        setToggleSidebar(false)
        if(toogleSidebar){
            document.body.classList.remove('sidebar-main');
        }
        else{
            document.body.classList.add('sidebar-main');
        }
    }





    useEffect(()=>{
       
if( document.body.classList.contains("sidebar-main")){
    setToggleSidebar(true);
}
    },[]);
  return (
    <>
    <Sidebar handletoggleSidebar={handletoggleSidebar2}/>
        <Navbar toogleSidebar={toogleSidebar} handletoggleSidebar={handletoggleSidebar}  />
    </>
  )
}

export default NavbarAndSideBar