import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useGetDashboardDetailsMutation } from '../../features/property/propertySlice';
import Skeleton from 'react-loading-skeleton';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function RegionCarousel() {
  const [getDetails, { isLoading }] = useGetDashboardDetailsMutation();
  const [regionData, setRegionData] = useState([]);

  const handlegetDetails = async () => {
    try {
      const response = await getDetails().unwrap();
      setRegionData(response.region_counts);
      console.log(response.region_counts, "hhh");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handlegetDetails();
  }, []);

  return (
    <>
      {isLoading ? (
        <Skeleton style={{ height: "200px" }} />
      ) : (
        
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={false}
              keyBoardControl={true}
              showDots={false}
              arrows={true}
              customLeftArrow={<i className="las la-caret-left" />}
              customRightArrow={<i className="las la-caret-right " />}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              itemClass="carousel-item-padding-40-px"
            >
              {regionData &&
                regionData.map((region, index) => (
                  <div key={index} className="card iq-mb-3 shadow-sm" style={{ width: "250px" }}>
                    <div className="card-body text-center">
                      <h6>{region.region}</h6>
                    </div>
                    <div className="card-footer text-center">
                      {region.count}
                    </div>
                  </div>
                ))}
            </Carousel>
        
      )}
    </>
  );
}

export default RegionCarousel;
