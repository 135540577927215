import React, { useRef, useState, useEffect } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import { Row, Col } from 'reactstrap';
import { FilePond, registerPlugin } from 'react-filepond';
import { useAddPostMutation, useGetUsersMutation, useGetAllPostCategoriesMutation ,useGetPostTagsMutation} from '../../features/property/propertySlice';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

import { useNavigate } from 'react-router-dom';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

function AddNewBlog() {
  const [addPost, { isLoading }] = useAddPostMutation();
  const [addPost1, { isLoading: isLoading1 }] = useAddPostMutation();
  const [getUser, { isLoading: isLoadingUsers }] = useGetUsersMutation();
  const [getPostCategories, { isLoading: isLoadingCategories }] = useGetAllPostCategoriesMutation();
  const [getPostTags, { isLoading: isLoadingTags }] = useGetPostTagsMutation();

  const [editorData, setEditorData] = useState('');

  const formRef = useRef(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  const [formData, setFormData] = useState({
    author: "",
    category: [],
    title: "",
    slug: "",
    excerpts: "",
    body: "",
    tags: [],
    meta_description: "",
    meta_keywords: "",
    seo_title: "",
    status: "",
    image:""
  });
  const [files, setFiles] = useState([]);

  const handleChange = (e) => {
    const { name, value, options } = e.target;

    if (name === 'tags') {
      const selectedTags = Array.from(options)
        .filter(option => option.selected)
        .map(option => option.value);

      setFormData({
        ...formData,
        tags: selectedTags
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleGetUsers = async () => {
    try {
      const users = await getUser().unwrap();
      setUsers(users.results);
    } catch (e) {
      console.log(e);
    }
  };

  const handleGetTags = async () => {
    try {
      const tags = await getPostTags().unwrap();
      setTags(tags);
      console.log(tags,'tags');

    } catch (e) {
      console.log(e);
    }
  }

  const handleGetCategories = async () => {
    try {
      const categories = await getPostCategories().unwrap();
      setCategories(categories);
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddTag = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      console.log(formData);
      const formDataObj = new FormData();
      for (const key in formData) {
          formDataObj.append(key, formData[key]);
          console.log(formDataObj);
      }
      if (files.length > 0) {
          files.forEach((file) => {
              formDataObj.append('image', file.file);
          });
          console.log(formDataObj);

          const response = await addPost(formDataObj).unwrap();
          console.log(response);
          setShowSuccess(true);

          setFormData({
           author: "",
          category: [],
          title: "",
          slug: "",
          excerpts: "",
          body: "",
          tags: [],
          meta_description: "",
          meta_keywords: "",
          seo_title: "",
          status: "",
    image:""
        });
        setFiles([]);
        navigate("/posts");
      }

    
    } else {
      formRef.current.classList.add("was-validated");
    }
  };

  const handleAddEventAndCreateAnother = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      console.log(formData);

      const formDataObj = new FormData();
      for (const key in formData) {
          formDataObj.append(key, formData[key]);
          console.log(formDataObj);
      }
      if (files.length > 0) {
          files.forEach((file) => {
              formDataObj.append('image', file.file);
          });
        }
          console.log(formDataObj);

          const response = await addPost(formDataObj).unwrap();
          console.log(response);
          setShowSuccess(true);

          setFormData({
           author: "",
          category: [],
          title: "",
          slug: "",
          excerpts: "",
          body: "",
          tags: [],
          meta_description: "",
          meta_keywords: "",
          seo_title: "",
          status: "",
    image:""
        });
        setFiles([]);
    } else {
      formRef.current.classList.add("was-validated");
    }
  }

  useEffect(() => {
    handleGetUsers();
    handleGetCategories();
    handleGetTags();
  }, []);

  return (
    <>
      <NavbarAndSideBar />
      <div className="content-page" id="content-page">
        <div className="container-fluid">
          <Row style={{ marginBottom: "100px" }}>
            <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
              <div className=''>
                <div className="">
                  <h3 className="card-title">Create Blog Post</h3>
                </div>
                <div className='p-2'>
                  {showSuccess &&
                    <div className="alert alert-success" role="alert">
                      <div className="iq-alert-text">Blog created successfully</div>
                      <button onClick={() => { setShowSuccess(false) }} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <i className="ri-close-line" style={{ color: "black" }}></i>
                      </button>
                    </div>
                  }

                  {showError &&
                    <div className="alert alert-danger" role="alert">
                      <div className="iq-alert-text">Enter Valid data,Try again</div>
                      <button onClick={() => { setShowError(false) }} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <i className="ri-close-line" style={{ color: "black" }}></i>
                      </button>
                    </div>
                  }
                  <form ref={formRef} className='w-100 needs-validation' noValidate>
                    <div className="row border p-2 rounded-sm py-4">
                      <div className="col">
                        <label>Author <span className='text-danger'>*</span></label>
                        <select type="text" name='author' className="form-control" required placeholder="" value={formData.author} onChange={handleChange} >
                          <option value="" hidden>select an option</option>
                          {users && users.map((user, index) => (
                            <option key={index} value={user.id}>{user.first_name} {user.last_name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <label>Post Category <span className='text-danger'>*</span></label>
                        <select type="text" name='category' className="form-control" required placeholder="" value={formData.category} onChange={handleChange} >
                          <option value="" hidden>select an option</option>
                          {categories && categories.map((category, index) => (
                            <option key={index} value={category.id}>{category.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row border p-2 rounded-sm mt-5">
                      <div className="col-md-6">
                        <label>Title <span className='text-danger'>*</span></label>
                        <input type="text" name='title' className="form-control" required placeholder="" value={formData.title} onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <label>Slug <span className='text-danger'>*</span></label>
                        <input type="text" name='slug' className="form-control" required placeholder="" value={formData.slug} onChange={handleChange} />
                      </div>
                      <div className="col-12 mb-2">
                        <label>Excerpt <span className='text-danger'>*</span></label>
                        <textarea className="form-control" required name="excerpts" onChange={handleChange} value={formData.excerpts}></textarea>
                      </div>
                      </div>
                      <div className="row border p-2 rounded-sm mt-5">
                      <div className='col-12 mb-2'>
                        <label>Body<span className='text-danger'>*</span></label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={editorData}
                          onReady={(editor) => {
                            console.log('Editor is ready to use!', editor);
                          }}
                          className="form-control"
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorData(data);
                            handleChange({
                              target: {
                                name: 'body',
                                value: data
                              }
                            });
                          }}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        />

<div className="col-12 mb-2">
                        <label>Featured Image  <span className='text-danger'>*</span></label>
                        <FilePond
                          files={files}
                          allowMultiple={false}
                          onupdatefiles={setFiles}
                          required
                          labelIdle='<span className="filepond--label-action">Browse One</span>'
                        />
                      </div>
                      </div>
                      </div>
                      <div className="row border p-2 rounded-sm mt-5">
                      <div className='col-12 mb-2'>
                        <label>Tags<span className='text-danger'>*</span></label>
                        <select multiple={true}  className="form-control" name="tags" onChange={handleChange}>
                          {tags.map((tag, index) => (
                            <option key={index} value={tag.id}>{tag.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className='col-12 mb-2'>
                        <label>Meta Description<span className='text-danger'>*</span></label>
                        <textarea className="form-control" name="meta_description" value={formData.meta_description} onChange={handleChange}></textarea>
                      </div>
                      <div className='col-12 mb-2'>
                        <label>Meta Keywords<span className='text-danger'>*</span></label>
                        <textarea className="form-control" name="meta_keywords" value={formData.meta_keywords} onChange={handleChange}></textarea>
                      </div>
                      <div className='col-md-6 mb-2'>
                        <label>SEO Title<span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" name="seo_title" value={formData.seo_title} onChange={handleChange} />
                      </div>
                   
                      <div className='col-md-6'>

                     
                        <label>Status<span className='text-danger'>*</span></label>

                        <select type="text" name='status' className="form-control" required placeholder="" value={formData.status} onChange={handleChange} >
                          <option value="" hidden>select an option</option>
                          <option  value='' selected hidden>Select an option</option>
                            <option  value='draft'>Draft</option>
                            <option  value='published'>Publihed</option>

                        
                        </select>
                        </div>

  
                    
                     
                      </div>
                    
                    <div className='d-flex'>
                      <button type="submit" className="btn btn-primary mt-2" onClick={handleAddTag}>Create Blog</button>
                      <button type="submit" className="btn btn-secondary mt-2 mx-3" onClick={handleAddEventAndCreateAnother}>Create and Add another</button>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AddNewBlog;
