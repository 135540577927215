import React, { useRef, useState,useEffect } from 'react';
import {  Modal } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
function ViewNewsLetterModal({ viewNewsLetterModal,newsLetterDetail, toggleViewModal }) {
   

  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    email:""
  });


  useEffect(()=>{

    setFormData({
        email: newsLetterDetail&&newsLetterDetail.email

    })

    
  },[newsLetterDetail])







  return (
    <Modal size='lg' className='modal-content' isOpen={viewNewsLetterModal} toggle={toggleViewModal}>
      <div className="modal-header">
        <h5 className="card-title">View {newsLetterDetail&&newsLetterDetail.email}</h5>
        <button type="button" className="close" onClick={toggleViewModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div  className='p-2 needs-validation' noValidate ref={formRef}>
        <div className='modal-body'>


        



<div className="row  p-2 rounded-sm">
         
          <div className="col">
            <label>Email <span className='text-danger'>*</span></label>
            <input type="text" name='email' disabled className="form-control" required placeholder="" value={formData.email}  />
          </div>
        
        </div>
       
        </div>

        <div className='modal-footer'>
          
      

        
         
          <button className="btn iq-bg-danger" onClick={toggleViewModal}>Close</button>
        </div>
      </div>
    </Modal>
  );
}

export default ViewNewsLetterModal;







