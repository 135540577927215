import React, { useRef, useState,useEffect } from 'react';
import {  Modal } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

function ViewLayoutModal({ viewLayoutModal,layoutDetail, toggleViewModal }) {
   

  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    layout: "",
    title:"",
    description:""
  });



  const [file1,setFile1]=useState();
  const [file2,setFile2]=useState();
  const [file3,setFile3]=useState();
  useEffect(()=>{

    setFormData({
        layout: layoutDetail&&layoutDetail.layout,
        title: layoutDetail&&layoutDetail.title,
        description: layoutDetail&&layoutDetail.description,


    })


    fetch(layoutDetail?.image1)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], 'first_image.jpg', { type: blob.type });
      
      setFile1([
        {
          source: file,
          options: {
            type: 'local'
          }
        }
      ]);
    });

    if (layoutDetail?.image2){
        fetch(layoutDetail.image2)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'first_image.jpg', { type: blob.type });
          
          setFile2([
            {
              source: file,
              options: {
                type: 'local'
              }
            }
          ]);
        });
    }

    if (layoutDetail?.image3){
        fetch(layoutDetail.image3)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'first_image.jpg', { type: blob.type });
          
          setFile3([
            {
              source: file,
              options: {
                type: 'local'
              }
            }
          ]);
        });
    }

    
  },[layoutDetail])







  return (
    <Modal size='lg' className='modal-content' isOpen={viewLayoutModal} toggle={toggleViewModal}>
      <div className="modal-header">
        <h5 className="card-title">View {layoutDetail&&layoutDetail.layout}</h5>
        <button type="button" className="close" onClick={toggleViewModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div  className='p-2 needs-validation' noValidate ref={formRef}>
        <div className='modal-body'>


        



        <div className="row  border  p-2 rounded-sm">
         
         <div className="col-12">
           <label>Layout <span className='text-danger'>*</span></label>
           <input type="number" min={1} name='layout' disabled className="form-control" required placeholder="" value={formData.layout}  />
         </div>

         <div className="col-12">
           <label>Title <span className='text-danger'>*</span></label>
           <input type="text" name='title' disabled className="form-control" required placeholder="" value={formData.title}  />
         </div>

         <div className="col-12">
           <label>Description <span className='text-danger'>*</span></label>
           <textarea
                           className="form-control"
                           name="description"
                           rows="3"
                           required
                           value={formData.description}
                           disabled
                         ></textarea>
         </div>
       
       </div>

       
       <div className="row border p-2 rounded-sm mt-5">
                       <div className="col-md-6">
                         <label>Image 1 <span className='text-danger'>*</span></label>
                         <FilePond
                           files={file1}
                           allowMultiple={false}
                           name="avatar_url"
                           stylePanelLayout="compact"
                           styleButtonRemoveItemPosition="left"
                           styleButtonProcessItemPosition="right"
                           styleLoadIndicatorPosition="right"
                           styleProgressIndicatorPosition="right"
                           styleButtonRemoveItemAlign={false}
                           acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                           onupdatefiles={setFile1}
                           required
                           disabled
                         />
                       </div>

                       <div className="col-md-6">
                         <label>Image 2 <span className='text-danger'>*</span></label>
                         <FilePond
                           files={file2}
                           disabled
                           allowMultiple={false}
                           name="avatar_url"
                           stylePanelLayout="compact"
                           styleButtonRemoveItemPosition="left"
                           styleButtonProcessItemPosition="right"
                           styleLoadIndicatorPosition="right"
                           styleProgressIndicatorPosition="right"
                           styleButtonRemoveItemAlign={false}
                           acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                           onupdatefiles={setFile2}
                           
                         />
                       </div>


                       <div className="col-md-6">
                         <label>Image 3 <span className='text-danger'>*</span></label>
                         <FilePond
                           files={file3}
                           disabled
                           allowMultiple={false}
                           name="avatar_url"
                           stylePanelLayout="compact"
                           styleButtonRemoveItemPosition="left"
                           styleButtonProcessItemPosition="right"
                           styleLoadIndicatorPosition="right"
                           styleProgressIndicatorPosition="right"
                           styleButtonRemoveItemAlign={false}
                           acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                           onupdatefiles={setFile3}
                           
                         />
                       </div>
                     </div>

       
        </div>

        <div className='modal-footer'>
          
      

        
         
          <button className="btn iq-bg-danger" onClick={toggleViewModal}>Close</button>
        </div>
      </div>
    </Modal>
  );
}

export default ViewLayoutModal;







