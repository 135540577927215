import React from 'react'

function Card3({title,total}) {
  return (
    <div className="card iq-mb-3">
                        <div className="card-body d-flex  justify-content-between">
                           <h5 className="card-title mt-2">{title}</h5>
                           <h6 href="#" className="btn mb-1 iq-bg-primary d-flex justify-content-center align-items-center" >{total}</h6>
                        </div>
                     </div>
  )
}

export default Card3