import React, { useEffect, useState,useRef } from 'react'
import { useEditPostMutation,useViewPostDetailMutation, useGetUsersMutation, useGetAllPostCategoriesMutation ,useGetPostTagsMutation} from '../../features/property/propertySlice';

import { useParams } from 'react-router-dom'
import { Col, Row } from "reactstrap";
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FilePond, registerPlugin } from 'react-filepond';
import { useNavigate } from 'react-router-dom';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';


function EditPostPage() {
    const { id } = useParams()
    const [viewPostDetail, {isLoading:isLoading}] = useViewPostDetailMutation()
    const [post,setPost]=useState()
    const [files, setFiles] = useState();
    const [tags,setTags]=useState();
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [getUser, { isLoading: isLoadingUsers }] = useGetUsersMutation();
    const [editPost, { isLoading: isLoadingEdittingPost }] = useEditPostMutation();

    const [getPostCategories, { isLoading: isLoadingCategories }] = useGetAllPostCategoriesMutation();
    const [getPostTags, { isLoading: isLoadingTags }] = useGetPostTagsMutation();
    const [editorData, setEditorData] = useState('');

    

    const formRef = useRef(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();
    const [selectedtags,setSelectedTags] = useState();
  
    const [formData, setFormData] = useState({
        author: "",
        category: [],
        title: "",
        slug: "",
        excerpts: "",
        body: "",
        tags: [],
        meta_description: "",
        meta_keywords: "",
        seo_title: "",
        status: "",
        image:""
      });

    const handleGetPosts=async()=>{

        try{
            const response = await viewPostDetail(id).unwrap();
            console.log(response)
            setPost(response)
            setFormData(
                {
                    author: response.author.id,
                    category: response.category.id,
                    title: response.title,
                    slug: response.slug,
                    excerpts: response.excerpts,
                    body: response.body,
                    tags: response.tags.map(()=>(response.tags.id)),
                    meta_description: response.meta_description,
                    meta_keywords:response.meta_keywords,
                    seo_title: response.seo_title,
                    status:response.status,
                    image:""
                  }
            )
            setEditorData(response.body)
            setSelectedTags(response.tags.map((item)=>(item.id)))

            const firstFileResponse = await fetch(`https://dev-api.shortstaybuddy.com${response.image}`);
            const firstBlob = await firstFileResponse.blob();
            const firstFile = new File([firstBlob], 'featured_image.jpg', { type: firstBlob.type });
            setFiles([{
              source: firstFile,
              options: {
                type: 'local'
              }
            }]);
      
            
        }catch(error){
            console.error(error);
        }
    }


    useEffect(()=>{
        handleGetPosts();
    },[])



    const handleChange = (e) => {
        const { name, value, options } = e.target;
    
        if (name==="tags") {
            const selectedValues = Array.from(e.target.selectedOptions, option => option.value);
            setSelectedTags(selectedValues);
      
          }
       
        
        else {
          setFormData({
            ...formData,
            [name]: value
          });
        }
      };
    
      const handleGetUsers = async () => {
        try {
          const users = await getUser().unwrap();
          setUsers(users.results);
        } catch (e) {
          console.log(e);
        }
      };
    
      const handleGetTags = async () => {
        try {
          const tags = await getPostTags().unwrap();
          setTags(tags);
          console.log(tags,'tags');
    
        } catch (e) {
          console.log(e);
        }
      }
    
      const handleGetCategories = async () => {
        try {
          const categories = await getPostCategories().unwrap();
          setCategories(categories);
        } catch (e) {
          console.log(e);
        }
      };


      useEffect(() => {
        handleGetUsers();
        handleGetCategories();
        handleGetTags();
      }, []);

      const handleAddTag = async (e) => {
        e.preventDefault();
        const isValid = formRef.current.checkValidity();
        if (isValid) {
          console.log(formData);
          const formDataObj = new FormData();
        
          
          formDataObj.append('author', formData.author);
          formDataObj.append('category', formData.category);
          formDataObj.append('title', formData.title);
          formDataObj.append('slug', formData.slug);
          formDataObj.append('excerpts', formData.excerpts);
          formDataObj.append('body', formData.body);
          formDataObj.append('meta_description', formData.meta_description);
          formDataObj.append('meta_keywords', formData.meta_keywords);
          formDataObj.append('seo_title', formData.seo_title);
          formDataObj.append('status', formData.status);
          formDataObj.append('body',editorData); 
          formDataObj.append('tags', selectedtags);
          if (files.length > 0) {
              files.forEach((file) => {
                  formDataObj.append('image', file.file);
              });

              
		for (let [key, value] of formDataObj.entries()) {
			console.log(`${key}: ${value}`);
		}
              
    
              const response = await editPost({id:post.id,formData:formDataObj}).unwrap();
              console.log(response);
              setShowSuccess(true);
    
              setFormData({
               author: "",
              category: [],
              title: "",
              slug: "",
              excerpts: "",
              body: "",
              tags: [],
              meta_description: "",
              meta_keywords: "",
              seo_title: "",
              status: "",
        image:""
            });
            setFiles([]);
            navigate("/posts");
          }
    
        
        } else {
          formRef.current.classList.add("was-validated");
        }
      };
  return (
    <>
    <NavbarAndSideBar />
    <div className="content-page" id="content-page">
      <div className="container-fluid">
        <Row style={{ marginBottom: "100px" }}>
        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
              <div className=''>
                <div className="">
                  <h3 className="card-title">Create Blog Post</h3>
                </div>
                <div className='p-2'>
                  {showSuccess &&
                    <div className="alert alert-success" role="alert">
                      <div className="iq-alert-text">Blog created successfully</div>
                      <button onClick={() => { setShowSuccess(false) }} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <i className="ri-close-line" style={{ color: "black" }}></i>
                      </button>
                    </div>
                  }

                  {showError &&
                    <div className="alert alert-danger" role="alert">
                      <div className="iq-alert-text">Enter Valid data,Try again</div>
                      <button onClick={() => { setShowError(false) }} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <i className="ri-close-line" style={{ color: "black" }}></i>
                      </button>
                    </div>
                  }
                  <form ref={formRef} className='w-100 needs-validation' noValidate>
                    <div className="row border p-2 rounded-sm py-4">
                      <div className="col">
                        <label>Author <span className='text-danger'>*</span></label>
                        <select type="text" name='author' className="form-control" required placeholder="" value={formData.author} onChange={handleChange} >
                          <option value="" hidden>select an option</option>
                          {users && users.map((user, index) => (
                            <option key={index} value={user.id}>{user.first_name} {user.last_name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <label>Post Category <span className='text-danger'>*</span></label>
                        <select type="text" name='category' className="form-control" required placeholder="" value={formData.category} onChange={handleChange} >
                          <option value="" hidden>select an option</option>
                          {categories && categories.map((category, index) => (
                            <option key={index} value={category.id}>{category.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row border p-2 rounded-sm mt-5">
                      <div className="col-md-6">
                        <label>Title <span className='text-danger'>*</span></label>
                        <input type="text" name='title' className="form-control" required placeholder="" value={formData.title} onChange={handleChange} />
                      </div>
                      <div className="col-md-6">
                        <label>Slug <span className='text-danger'>*</span></label>
                        <input type="text" name='slug' className="form-control" required placeholder="" value={formData.slug} onChange={handleChange} />
                      </div>
                      <div className="col-12 mb-2">
                        <label>Excerpt <span className='text-danger'>*</span></label>
                        <textarea className="form-control" required name="excerpts" onChange={handleChange} value={formData.excerpts}></textarea>
                      </div>
                      </div>
                      <div className="row border p-2 rounded-sm mt-5">
                      <div className='col-12 mb-2'>
                        <label>Body<span className='text-danger'>*</span></label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={editorData}
                          onReady={(editor) => {
                            console.log('Editor is ready to use!', editor);
                          }}
                          className="form-control"
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorData(data);
                            handleChange({
                              target: {
                                name: 'body',
                                value: data
                              }
                            });
                          }}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        />

<div className="col-12 mb-2">
                        <label>Featured Image  <span className='text-danger'>*</span></label>
                        <FilePond
                          files={files}
                          allowMultiple={false}
                          onupdatefiles={setFiles}
                          required
                          labelIdle='<span className="filepond--label-action">Browse One</span>'
                        />
                      </div>
                      </div>
                      </div>
                      <div className="row border p-2 rounded-sm mt-5">
                      <div className='col-12 mb-2'>
                        <label>Tags<span className='text-danger'>*</span></label>
                        <select multiple={true}  value={selectedtags&&selectedtags} className="form-control" name="tags" onChange={handleChange}>
                          {tags&&tags.map((tag, index) => (
                            <option key={index} value={tag.id}>{tag.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className='col-12 mb-2'>
                        <label>Meta Description<span className='text-danger'>*</span></label>
                        <textarea className="form-control" name="meta_description" value={formData.meta_description} onChange={handleChange}></textarea>
                      </div>
                      <div className='col-12 mb-2'>
                        <label>Meta Keywords<span className='text-danger'>*</span></label>
                        <textarea className="form-control" name="meta_keywords" value={formData.meta_keywords} onChange={handleChange}></textarea>
                      </div>
                      <div className='col-md-6 mb-2'>
                        <label>SEO Title<span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" name="seo_title" value={formData.seo_title} onChange={handleChange} />
                      </div>
                   
                      <div className='col-md-6'>

                     
                        <label>Status<span className='text-danger'>*</span></label>

                        <select type="text" name='status' className="form-control" required placeholder="" value={formData.status} onChange={handleChange} >
                          <option value="" hidden>select an option</option>
                          <option  value='' selected hidden>Select an option</option>
                            <option  value='draft'>Draft</option>
                            <option  value='published'>Publihed</option>

                        
                        </select>
                        </div>

  
                    
                     
                      </div>
                    
                    <div className='d-flex'>
                    {
                        isLoadingEdittingPost?
                        <button  className="btn btn-primary mt-2" ><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Saving...</button>
                        :
                        <button type="submit" className="btn btn-primary mt-2" onClick={handleAddTag}>Save Changes</button>
                    }
                    </div>
                  </form>
                </div>
              </div>
            </Col>
        </Row>
      </div>
    </div>
  </>
  )
}

export default EditPostPage