import React, { useEffect, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import DataTable from 'react-data-table-component';
import { Col, Row } from "reactstrap";
import AddPropertyAmenityModal from '../../components/property/amenities/addPropertyAmenityModal';
import ViewPropertyAmenityModal from '../../components/property/amenities/viewPropertyAmenityModal';
import EditPropertyAmenityModal from '../../components/property/amenities/editPropertyAmenityModal';
import DeletePropertyAmenityModal from '../../components/property/amenities/deletePropertyAmenityModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useGetAmenitiesMutation } from '../../features/property/propertySlice';

function PropertyAmenities() {
    const [getAmenities] = useGetAmenitiesMutation();
    const [amenities, setAmenities] = useState([]);
    const [amenityDetail, setAmenityDetail] = useState(null);
    const [pending, setPending] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    const handleGetAmenities = async (page, perPage) => {
        setPending(true);
        try {
            const response = await getAmenities({ page, limit: perPage }).unwrap();
            setAmenities(response.results);
            setTotalRows(response.count);
            console.log(response);
        } catch (e) {
            console.log(e);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        handleGetAmenities(page, perPage);
    }, [page, perPage]);

    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    };

    const filteredItems = amenities.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const [propertyAmenityModal, setPropertyAmenityModal] = useState(false);
    const toggleModal = () => {
        setPropertyAmenityModal(!propertyAmenityModal);
        handleGetAmenities(page, perPage);
    };

    const [viewPropertyAmenityModal, setViewPropertyAmenityModal] = useState(false);
    const [editPropertyAmenityModal, setEditPropertyAmenityModal] = useState(false);
    const [deletePropertyAmenityModal, setDeletePropertyAmenityModal] = useState(false);

    const toggleViewModal = () => {
        setViewPropertyAmenityModal(!viewPropertyAmenityModal);
    };

    const toggleEditModal = () => {
        setEditPropertyAmenityModal(!editPropertyAmenityModal);
        handleGetAmenities(page, perPage);
    };

    const toggleDeleteModal = () => {
        setDeletePropertyAmenityModal(!deletePropertyAmenityModal);
        handleGetAmenities(page, perPage);
    };

    const handleGetAmenityDetail = (row) => {
        setAmenityDetail(row);
        setViewPropertyAmenityModal(true);
    };

    const handleEditCategory = (row) => {
        setAmenityDetail(row);
        setEditPropertyAmenityModal(true);
    };

    const handleDeleteAmenity = (row) => {
        setAmenityDetail(row);
        setDeletePropertyAmenityModal(true);
    };

    const columns = [
        {
            id: 'id',
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div
                        className='text-secondary action-link'
                        size="sm"
                        onClick={() => handleGetAmenityDetail(row)}
                    >
                        <i className="ri-eye-fill mr-1"></i>
                        View
                    </div>
                    <div className='text-primary action-link mx-3' size="sm" onClick={() => handleEditCategory(row)}>
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </div>
                    <div className='text-danger action-link' onClick={() => handleDeleteAmenity(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex justify-content-between mb-4'>
                                <h4 className="card-title">Property Amenities</h4>
                                <button type="button" onClick={toggleModal} className="btn btn-primary btn-sm mr-2">New property amenity</button>
                                <AddPropertyAmenityModal propertyAmenityModal={propertyAmenityModal} toggleModal={toggleModal} />
                                <ViewPropertyAmenityModal viewPropertyAmenityModal={viewPropertyAmenityModal} toggleViewModal={toggleViewModal} amenityDetail={amenityDetail} />
                                <EditPropertyAmenityModal editPropertyAmenityModal={editPropertyAmenityModal} toggleEditModal={toggleEditModal} amenityDetail={amenityDetail} />
                                <DeletePropertyAmenityModal deletePropertyAmenityModal={deletePropertyAmenityModal} toggleDeleteModal={toggleDeleteModal} amenityDetail={amenityDetail} />
                            </div>
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className='text-primary d-flex align-content-center hover-icon'>
                                        <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                    </div>
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Name..."
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ minWidth: '1000px' }}>
                                        <DataTable
                                            columns={columns}
                                            data={filteredItems}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                        
                                            selectableRows
                                            onSelectedRowsChange={handleChange}
                                            clearSelectedRows={toggledClearRows}
                                            selectableRowsComponentProps={{
                                                type: 'checkbox',
                                            }}
                                            selectableRowsHighlight
                                            progressPending={pending}
                                            progressComponent={
                                                <div style={{ width: "100%", padding: "10px" }}>
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                </div>
                                            }
                                            defaultSortFieldId="id"
                                            defaultSortAsc={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default PropertyAmenities;
