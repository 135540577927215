import React,{useState,useEffect} from 'react'
import { Row } from 'reactstrap'
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar'
import Card3 from '../../components/report/card3'
import CityCarousel from '../../components/report/cityCarousel'
import SignupsChart from '../../components/report/signupsChart'
import { useGetDashboardDetailsMutation ,useGetReportDetailsMutation} from '../../features/property/propertySlice';
import Skeleton from 'react-loading-skeleton'


function RegistrationReport() {
  const [details,setDetails]=useState([])
  

  const [getRegistration, { isLoading }] = useGetReportDetailsMutation();
  const [registrationData,setRegistrationData]=useState();
  const today = new Date();
  const twoWeeksAgo = new Date(today);
  twoWeeksAgo.setDate(today.getDate() - 14);

  const formatDate = (date) => date.toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(formatDate(twoWeeksAgo));
  const [endDate, setEndDate] = useState(formatDate(today));

  const handleGetDetails = async (startDate, endDate) => {
    try {
      const response = await getRegistration({ start_date: startDate, end_date: endDate }).unwrap();
      console.log(response);
      setRegistrationData(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetDetails(startDate, endDate); // Fetch report details with the initial dates
  }, []);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSearch =async()=>{
    handleGetDetails(startDate, endDate); 
  }
  return (
  
    <>
    <NavbarAndSideBar />

<div className="content-page" id="content-page">
           <div className="container-fluid" style={{ marginBottom: "100px" }}>
         
           
           <Row className='px-lg-5 px-md-3 px-sm-2' >
   
   <div className='w-full w-100 d-md-flex  justify-content-between'>
   <h5 className='mb-2'>Registration/Sign ups </h5>
   <div className='iq-card-header d-md-flex justify-content-between'>
   

<div className='d-flex justify-content-between'>
                      <input 
                                          type="date" 
               className="form-control" 
               name='start_date' 
               id="start-date" 
               value={startDate} 
               onChange={handleStartDateChange} 
                      ></input>
                      <span class="badge badge-light text-center mx-2 d-flex justify-content-center align-items-center" >TO</span>
                      <input 
                         type="date" 
               className="form-control" 
               name='end_date' 
               id="end-date" 
               value={endDate} 
               onChange={handleEndDateChange} ></input>
                      <button onClick={handleSearch} type="button" class="btn btn-primary  mx-1 "><i class="ri-search-line pr-0"></i></button>
</div>
   </div>

   </div>
      </Row>

          
             
           <Row className='px-lg-5 px-md-3 px-sm-2 mt-3'>
           <div className='row w-100'>
           <div className="col-lg-3 col-md-4 col-sm-6">

            {
                isLoading? (


<Skeleton style={{width:"100%",height:"120px"}}/>                ) : registrationData&& (
                  <Card3 title="Users" total={registrationData.user_count===null?"0":registrationData.user_count} />
                ) 
              }
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">

            {
                isLoading? (


<Skeleton style={{width:"100%",height:"120px"}}/>                ) : registrationData&& (
                  <Card3 title="Hosts" total={registrationData.host_count===null?"0":registrationData.host_count} />
                ) 
              }

            </div>
            <div className="col-lg-3 col-md-4  col-sm-6">
           
            {
                isLoading? (


<Skeleton style={{width:"100%",height:"120px"}}/>                ) : registrationData&& (
                  <Card3 title="Partners" total={registrationData.partner_count===null?"0":registrationData.partner_count} />
                ) 
              }           

            </div>
           
           </div>
           </Row>


           <Row className='px-lg-5 px-md-3 px-sm-2'>
                        <div className='w-100 col-12'>

                    
                        </div>
                        </Row>
                        <Row  className='px-lg-5 px-md-3 px-sm-2'>
                      
                            <div className='w-100 col-12'>
                            <div class="iq-card">
                            {
                              isLoading? (

                                <Skeleton style={{width:"100%",height:"400px"}}/>                ) : registrationData&& (
                                  <SignupsChart data={registrationData&&registrationData}/>
                              )
                            }
                            </div>
                            </div>
                        </Row>
 </div>
 </div>

 
 



</>
  )
}

export default RegistrationReport