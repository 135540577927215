import React, { useEffect, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import DataTable from 'react-data-table-component';
import { Col, Row } from "reactstrap";
import { useGetNewsLetterMutation} from '../../features/property/propertySlice';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AddNewsLetterModal from '../../components/settings/addNewsLetterModal';
import ViewNewsLetterModal from '../../components/settings/viewNewsLetterModal';
import DeleteNewsLetterModal from '../../components/settings/deleteNewsLetterModal';
import EditNewsLetterModal from '../../components/settings/editNewsLetterModal';

function NewsLetterPage() {
    const [getNewsLetter, { isLoading }] = useGetNewsLetterMutation();
    const [newsletter, setNewsLetter] = useState([]);
    const [newsLetterDetail, setNewsLetterDetail] = useState(null);
    const [pending, setPending] = useState(true);
    const [nextNewsLetters,setNextNewsLetters]=useState()
    const [previousNewLetters,setPreviousNewsLetters]=useState()
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState()
    const [count,setCount]=useState()

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);


   



      const handleGetNewsLetter = async (page) => {
        setPending(true);
        try {
            const params = { page: page, limit: perPage };
            const response = await getNewsLetter(params).unwrap();
            setNewsLetter(response.results);
            setTotalRows(response.count);
        } catch (e) {
            console.error(e);
        } finally {
            setPending(false);
        }
    };


    useEffect(() => {
        handleGetNewsLetter(page);
    }, [page, perPage]);

    const handlePagination = (page) => {
        setPage(page);
    };

    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);
        setPage(1);
        handleGetNewsLetter(1);
    };

    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const filteredItems = newsletter.filter(
        item => item.email && item.email.toLowerCase().includes(filterText.toLowerCase())
    );

    const [addnewsletter, setAddFAQModal] = useState(false);
    const toggleModal = () => {
        setAddFAQModal(!addnewsletter);
        handleGetNewsLetter();
    }

    const [viewNewsLetterModal, setNewsLetterModal] = useState(false);
    const [editnewsletterModal, setEditFaqsModal] = useState(false);
    const [deletenewsletterModal, setDeleteNewsLetterModal] = useState(false);

    const toggleViewModal = () => {
        setNewsLetterModal(!viewNewsLetterModal);
    }

    const toggleEditModal = () => {
        setEditFaqsModal(!editnewsletterModal);
        handleGetNewsLetter();
    }

    const toggleDeleteModal = () => {
        setDeleteNewsLetterModal(!deletenewsletterModal);
        handleGetNewsLetter();
    }

    const handleGetCategoryDetail = (row) => {
        setNewsLetterDetail(row);
        setNewsLetterModal(true);
    }

    const handleEditCategory = (row) => {
        setNewsLetterDetail(row);
        setEditFaqsModal(true);
    }

    const handleDeleteCategory = (row) => {
        setNewsLetterDetail(row);
        setDeleteNewsLetterModal(true);
    }

    const columns = [
        {
            id: 'type',
            name: 'Email',
            selector: row => <div style={{ margin: "0", textTransform: 'capitalize' }}>{row.email}</div>,
            sortable: true,
            reorder: true,
            width:"33%"
        },

        {
            id: 'created_at',
            name: 'Created_at',
            selector: row => <h6 style={{ textTransform: 'capitalize' }}> {row.created_at}</h6>,
            sortable: true,
            reorder: true,
            width:"33%"
        },
        {
            id: 'actions',
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div
                        className='text-secondary action-link'
                        size="sm"
                        onClick={() => handleGetCategoryDetail(row)}
                    >
                        <i className="ri-eye-fill mr-1"></i>
                        View
                    </div>
                    <div className='text-primary action-link mx-3' size="sm" onClick={() => handleEditCategory(row)}>
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </div>
                    <div className='text-danger action-link' onClick={() => handleDeleteCategory(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
            width:"33%"
        },
    ];

    return (
      
        <>
        <NavbarAndSideBar />
        <div className="content-page" id="content-page">
            <div className="container-fluid">
                <Row style={{ marginBottom: "100px" }}>
                    <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                        <div className='d-flex justify-content-between mb-4'>
                            <h4 className="card-title">News Letters</h4>
                            <button type="button" onClick={toggleModal} className="btn btn-primary btn-sm mr-2">New news letter</button>
                            <AddNewsLetterModal addnewsletter={addnewsletter} toggleModal={toggleModal} />
                            <ViewNewsLetterModal viewNewsLetterModal={viewNewsLetterModal} toggleViewModal={toggleViewModal} newsLetterDetail={newsLetterDetail} />
                            <EditNewsLetterModal editnewsletterModal={editnewsletterModal} toggleEditModal={toggleEditModal} newsLetterDetail={newsLetterDetail} />
                            <DeleteNewsLetterModal deletenewsletterModal={deletenewsletterModal} toggleDeleteModal={toggleDeleteModal} newsLetterDetail={newsLetterDetail} />
                        </div>
                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className='text-primary d-flex align-content-center hover-icon'>
                                    <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                </div>
                                <div className="search-box d-flex justify-content-end">
                                    <input type="text" className="text search-input" placeholder="Search By Email..."
                                        value={filterText}
                                        onChange={e => setFilterText(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div style={{ overflowX: 'auto' }}>
                                <DataTable
                                    columns={columns}
                                    data={newsletter}
                                    progressPending={pending}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePagination}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    progressComponent={
                                        <div style={{ width: "100%", padding: "10px" }}>
                                            <Skeleton style={{ width: "100%", height: "30px" }} />
                                            <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                            <Skeleton style={{ width: "100%", height: "30px" }} />
                                            <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                        </div>
                                    }
                                    selectableRowsHighlight
                                    highlightOnHover
                                />
                            </div>

                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </>
    );
}

export default NewsLetterPage;
