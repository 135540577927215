import React from 'react'

function Card2({title,amount}) {
  return (
    <div className="card iq-mb-3">
                        <div className="card-body">
                           <h5 className="card-title">{title}</h5>
                           <span href="#" className="btn mb-1 iq-bg-danger">₵ {amount}</span>
                        </div>
                     </div>
  )
}

export default Card2