import React, { useEffect, useRef, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import { Row, Col } from 'reactstrap';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from 'react-router-dom';
import { useGetSettingsMutation, useEditSettingsMutation } from '../../features/property/propertySlice';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

function EditSettingsPage() {
    const [getSettings, { isLoading }] = useGetSettingsMutation();
    const [editSettings, { isLoading: isEditing }] = useEditSettingsMutation();
    const formRef = useRef(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        site_title: "",
        email: "",
        phone: "",
        address: "",
        site_logo: null,
        favicon: null,
        description: null,
        facebook: null,
        twitter: null,
        instagram: null,
        telegram: null,
        whatsapp_number: null,
        transaction_fee: null,
        dollar_rate: null,
    });
    const [siteLogo, setSiteLogo] = useState([]);
    const [favicon, setFavicon] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleGetSettings = async () => {
        try {
            const response = await getSettings().unwrap();
            console.log(response)
            setFormData({
                site_title: response.site_title,
                email: response.email,
                phone: response.phone,
                address: response.address,
                site_logo: null,
                favicon: null,
                description: response.description,
                facebook: response.facebook ||"",
                twitter: response.twitter||"",
                instagram: response.instagram ||"",
                telegram: response.telegram||"",
                whatsapp_number: response.whatsapp_number,
                transaction_fee_percent: response.transaction_fee_percent,
                dollar_rate: response.dollar_rate,
            });


if (response.site_logo){
    fetch(`https://testing.shortstaybuddy.com${response.site_logo}`)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], 'image.jpg', { type: blob.type });
      setSiteLogo([
        {
          source: file,
          options: {
            type: 'local'
          }
        }
      ]);
    });

}
           
        


if (response.favicon){
    fetch(`https://testing.shortstaybuddy.com${response.favicon}`)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], 'image.jpg', { type: blob.type });
      setFavicon([
        {
          source: file,
          options: {
            type: 'local'
          }
        }
      ]);
    });

}
     
    

        } catch (e) {
            console.log(e);
        }
    };

    const handleEditSettings = async (e) => {
        e.preventDefault();
        const isValid = formRef.current.checkValidity();
        if (isValid) {
            try {
                const formDataObj = new FormData();
                for (const key in formData) {
                    formDataObj.append(key, formData[key]);
                }
                if (siteLogo.length > 0) {
                    siteLogo.forEach((file) => {
                        formDataObj.append('site_logo', file.file);
                    });
                }
                if (favicon.length > 0) {
                    favicon.forEach((file) => {
                        formDataObj.append('favicon', file.file);
                    });
                }
                const response = await editSettings({formData:formDataObj}).unwrap();
                console.log(response);
                setShowSuccess(true);
              
               
               
            } catch (e) {
                console.log(e);
                setShowError(true);
            }
        } else {
            formRef.current.classList.add("was-validated");
        }
    };

    useEffect(() => {
        handleGetSettings();
    }, []);

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className=''>
                                <div className="">
                                    <h3 className="card-title">Edit Settings</h3>
                                </div>
                                <div className='p-2'>
                                    {showSuccess &&
                                        <div className="alert alert-success" role="alert">
                                            <div className="iq-alert-text">Settings updated successfully</div>
                                            <button onClick={() => { setShowSuccess(false) }} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <i className="ri-close-line" style={{ color: "black" }}></i>
                                            </button>
                                        </div>
                                    }
                                    {showError &&
                                        <div className="alert alert-danger" role="alert">
                                            <div className="iq-alert-text">Enter valid data, try again</div>
                                            <button onClick={() => { setShowError(false) }} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <i className="ri-close-line" style={{ color: "black" }}></i>
                                            </button>
                                        </div>
                                    }
                                    <form ref={formRef} onSubmit={handleEditSettings} className='w-100 needs-validation' noValidate>
                                        <div className="row border p-2 rounded-sm py-4">
                                            <div className="col-md-6 mt-2">
                                                <label>Site Title <span className='text-danger'>*</span></label>
                                                <input type="text" name='site_title' className="form-control" required placeholder="" value={formData.site_title} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label>Email <span className='text-danger'>*</span></label>
                                                <input type="email" name='email' className="form-control" required placeholder="" value={formData.email} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label>Phone <span className='text-danger'>*</span></label>
                                                <input type="text" name='phone' className="form-control" required placeholder="" value={formData.phone} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label>Address <span className='text-danger'>*</span></label>
                                                <input type="text" name='address' className="form-control" required placeholder="" value={formData.address} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label>Site Logo <span className='text-danger'></span></label>
                                                <FilePond
                                                    files={siteLogo}
                                                    allowMultiple={false}
                                                    name="site_logo"
                                                    stylePanelLayout="compact"
                                                    acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                                                    onupdatefiles={setSiteLogo}
                                                />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label>Favicon <span className='text-danger'></span></label>
                                                <FilePond
                                                    files={favicon}
                                                    allowMultiple={false}
                                                    name="favicon"
                                                    stylePanelLayout="compact"
                                                    acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                                                    onupdatefiles={setFavicon}
                                                />
                                            </div>
                                            <div className="col-12 mt-2">
                                                <label>Description <span className='text-danger'>*</span></label>
                                                <textarea className="form-control" required name="description" onChange={handleChange} value={formData.description}></textarea>
                                            </div>
                                        </div>
                                        <div className="row border p-2 rounded-sm mt-5">
                                            <div className='text-center' style={{ width: "100px", position: 'absolute', marginTop: "-20px", backgroundColor: "#f3f7fd" }}>Social Links</div>
                                            <div className="col-md-6 mt-2">
                                                <label>Facebook Link</label>
                                                <input type="text" name='facebook' className="form-control" placeholder="" value={formData.facebook} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label>Twitter Link</label>
                                                <input type="text" name='twitter' className="form-control" placeholder="" value={formData.twitter} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label>Instagram Link</label>
                                                <input type="text" name='instagram' className="form-control" placeholder="" value={formData.instagram} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label>Telegram Link</label>
                                                <input type="text" name='telegram' className="form-control" placeholder="" value={formData.telegram} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label>Whatsapp Number</label>
                                                <input type="text" name='whatsapp_number' className="form-control" placeholder="" value={formData.whatsapp_number} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="row border p-2 rounded-sm mt-5">
                                            <div className='text-center' style={{ width: "120px", position: 'absolute', marginTop: "-20px", backgroundColor: "#f3f7fd" }}>Configurations</div>
                                            <div className="col-md-6 mt-2">
                                                <label>Transaction Fee</label>
                                                <input type="number" name='transaction_fee_percent' className="form-control" placeholder="" value={formData.transaction_fee_percent} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label>Dollar Rate</label>
                                                <input type="number" name='dollar_rate' className="form-control" placeholder="" value={formData.dollar_rate} onChange={handleChange} />
                                            </div>

                                        <div className='col-12 mt-2' >
                                        <div class="custom-control custom-switch custom-switch-color custom-control-inline">
                              <input type="checkbox" class="custom-control-input bg-waring" id="customSwitch04" checked />
                              <label class="custom-control-label" for="customSwitch04">Maintenance mode</label>
                           </div>

                                        </div>

                                        </div>
                                        <div className="d-flex justify-content-center mt-4">
                                            <button type="submit" className="btn btn-primary">{isEditing ? 'Saving...' : 'Save Changes'}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default EditSettingsPage;
