import React, { useRef, useState,useEffect } from 'react';
import {  Modal } from 'reactstrap';
import { useEditSiteLayoutMutation} from '../../features/property/propertySlice';
import { useNavigate } from 'react-router-dom';
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

function EditLayoutModal({ editlayoutModal,layoutDetail, toggleEditModal }) {
    const [addNewLayout,{isLoading}]=useEditSiteLayoutMutation();

    const [showSuccess,setShowSuccess]=useState(false)
    const navigate = useNavigate();
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    layout: "",
    title:"",
    description:""
  });



  const [file1,setFile1]=useState();
  const [file2,setFile2]=useState();
  const [file3,setFile3]=useState();


  useEffect(()=>{

    setFormData({
        layout: layoutDetail&&layoutDetail.layout,
        title: layoutDetail&&layoutDetail.title,
        description: layoutDetail&&layoutDetail.description,


    })


    fetch(layoutDetail?.image1)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], 'first_image.jpg', { type: blob.type });
      
      setFile1([
        {
          source: file,
          options: {
            type: 'local'
          }
        }
      ]);
    });

    if (layoutDetail?.image2){
        fetch(layoutDetail.image2)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'first_image.jpg', { type: blob.type });
          
          setFile2([
            {
              source: file,
              options: {
                type: 'local'
              }
            }
          ]);
        });
    }

    if (layoutDetail?.image3){
        fetch(layoutDetail.image3)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'first_image.jpg', { type: blob.type });
          
          setFile3([
            {
              source: file,
              options: {
                type: 'local'
              }
            }
          ]);
        });
    }

    
  },[layoutDetail])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      try{


        const formDataToSend = new FormData();
        if (file1.length > 0) {
          formDataToSend.append('image1',file1[0].file);
  
      }

      if (file2?.length > 0) {
        formDataToSend.append('image2',file2[0].file);

    }

    
    if (file3?.length > 0) {
      formDataToSend.append('image3',file3[0].file);

  }

  formDataToSend.append('title',formData.title);
  formDataToSend.append('layout',formData.layout);
  formDataToSend.append('description',formData.description);



      
      
        console.log(formData)
        const response= await addNewLayout({id:layoutDetail.id,formData:formDataToSend}).unwrap();
        console.log(response)
        setShowSuccess(true)
        setFormData({
            
            Layout: "",
            title:"",
            description:""
          })

          

          toggleEditModal()
      }catch(e){
        console.log(e)
        setShowSuccess(false)
      }

      
    } else {
      formRef.current.classList.add("was-validated");
    }
  };




  return (
        <Modal size='lg' className='modal-content' isOpen={editlayoutModal} toggle={toggleEditModal}>
      <div className="modal-header">
        <h3 className="card-title">Create New Layout</h3>
        <button type="button" className="close" onClick={toggleEditModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <form  className='p-2 needs-validation' noValidate ref={formRef}>
        <div className='modal-body'>

{
    showSuccess?   

    <div class="alert alert-success" role="alert">
                             
                             <div class="iq-alert-text">Layout edited successfully</div>
                             <button onClick={()=>{setShowSuccess(false)}} type="button" class="close" data-dismiss="alert" aria-label="Close">
                             <i class="ri-close-line" style={{color:"black"}}></i>
                             </button>
                          </div>

    :""
}

        



<div className="row  border  p-2 rounded-sm">
         
          <div className="col-12">
            <label>Layout <span className='text-danger'>*</span></label>
            <input type="number" min={1} name='layout' onChange={handleChange} className="form-control" required placeholder="" value={formData.layout}  />
          </div>

          <div className="col-12">
            <label>Title <span className='text-danger'>*</span></label>
            <input type="text" name='title' onChange={handleChange} className="form-control" required placeholder="" value={formData.title}  />
          </div>

          <div className="col-12">
            <label>Description <span className='text-danger'>*</span></label>
            <textarea
                            className="form-control"
                            name="description"
                            rows="3"
                            required
                            value={formData.description}
                            onChange={handleChange}
                          ></textarea>
          </div>
        
        </div>

        
        <div className="row border p-2 rounded-sm mt-5">
                        <div className="col-md-6">
                          <label>Image 1 <span className='text-danger'>*</span></label>
                          <FilePond
                            files={file1}
                            allowMultiple={false}
                            name="avatar_url"
                            stylePanelLayout="compact"
                            styleButtonRemoveItemPosition="left"
                            styleButtonProcessItemPosition="right"
                            styleLoadIndicatorPosition="right"
                            styleProgressIndicatorPosition="right"
                            styleButtonRemoveItemAlign={false}
                            acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                            onupdatefiles={setFile1}
                            required
                          />
                        </div>

                        <div className="col-md-6">
                          <label>Image 2 <span className='text-danger'>*</span></label>
                          <FilePond
                            files={file2}
                            allowMultiple={false}
                            name="avatar_url"
                            stylePanelLayout="compact"
                            styleButtonRemoveItemPosition="left"
                            styleButtonProcessItemPosition="right"
                            styleLoadIndicatorPosition="right"
                            styleProgressIndicatorPosition="right"
                            styleButtonRemoveItemAlign={false}
                            acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                            onupdatefiles={setFile2}
                            
                          />
                        </div>


                        <div className="col-md-6">
                          <label>Image 3 <span className='text-danger'>*</span></label>
                          <FilePond
                            files={file3}
                            allowMultiple={false}
                            name="avatar_url"
                            stylePanelLayout="compact"
                            styleButtonRemoveItemPosition="left"
                            styleButtonProcessItemPosition="right"
                            styleLoadIndicatorPosition="right"
                            styleProgressIndicatorPosition="right"
                            styleButtonRemoveItemAlign={false}
                            acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                            onupdatefiles={setFile3}
                            
                          />
                        </div>
                      </div>

       
        </div>

        <div className='d-flex justify-content-start'>
          
          {
            isLoading?
            <button type="submit" className="btn btn-primary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Saving...</button>
            :
            <button type="button" onClick={handleSubmit} className="btn btn-primary">Save</button>
          }

        

         
          <button className="btn iq-bg-danger mx-2" onClick={toggleEditModal}>Cancel</button>
        </div>
      </form>
    </Modal>
  );
}

export default EditLayoutModal;







