import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { useDeletePostTagsMutation } from '../../features/property/propertySlice'
function DeleteBlogTagModal({deleteBlogCategoryModal,toggleDeleteModal,tagsDetail}) {

    const [deleteTag,{isLoading}]=useDeletePostTagsMutation()
    const [success,setShowSuccess]=useState(false)
    const [error,setShowError]=useState(false)


    useEffect(()=>{
        setShowError(false)
        setShowSuccess(false)
    },[])
    const handleDeleteCategory =async() =>{

        try{

            const response =await deleteTag(tagsDetail.id).unwrap();
           
            setShowError(false)
            toggleDeleteModal()
         
        }catch(e){
            console.log(e)
         
        }
    }
  return (
    <Modal  className='modal-content' isOpen={deleteBlogCategoryModal} toggle={toggleDeleteModal}>
<div className="modal-header">
      <h6 className="card-title"></h6>
      <button type="button" className="close" onClick={toggleDeleteModal} aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div className='p-2 ' >
      <div className='modal-body'>

   

         <h4>Are you sure you want to delete {tagsDetail &&tagsDetail.name}</h4>
         <p className='text-danger'>This action cannot be undone.</p>
       

     
      
      

</div>

<div className='modal-footer'>
         <button className="btn iq-bg-primary" onClick={toggleDeleteModal}>Cancel</button>

          
          {
            isLoading?
            <button type="submit" className="btn btn-danger">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Deleting...</button>
            :
            <button onClick={handleDeleteCategory} className="btn btn-danger">Confirm</button>
          }

         
        </div>
</div>
</Modal>
  )
}

export default  DeleteBlogTagModal