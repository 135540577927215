import React, { useRef, useState } from 'react';
import {  Modal } from 'reactstrap';
import { FilePond, registerPlugin } from 'react-filepond';
import { useAddEventCategoryMutation } from '../../../features/property/propertySlice';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';


registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateSize,
    FilePondPluginFileEncode
  );
  
function AddNewEventCategory({ addEventCategoryModal, toggleModal }) {
    const [addEventCategory,{isLoading}]=useAddEventCategoryMutation();
    const [showSuccess,setShowSuccess]=useState(false)
    const [files, setFiles] = useState([]);
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    title: "",
    slug:""

  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      try {
        console.log("FormData before conversion:", formData);
  
        const formDataObj = new FormData();
        for (const key in formData) {
          formDataObj.append(key, formData[key]);
        }
  
        if (files.length > 0) {
          files.forEach((file) => {
            formDataObj.append('image', file.file);
          });
        }
  
        // Log formDataObj contents
        for (const pair of formDataObj.entries()) {
          console.log(`${pair[0]}: ${pair[1]}`);
        }
  
        const response = await addEventCategory(formDataObj).unwrap();
        console.log("Response from server:", response);
        setShowSuccess(true);
  
        setFormData({
          title: "",
          slug: "",
        });
        setFiles([]);
      } catch (e) {
        console.log("Error:", e);
        setShowSuccess(false);
      }
    } else {
      formRef.current.classList.add("was-validated");
    }
  };
  

  return (
    <Modal size='lg' className='modal-content' isOpen={addEventCategoryModal} toggle={toggleModal}>
      <div className="modal-header">
        <h3 className="card-title">Create event category</h3>
        <button type="button" className="close" onClick={toggleModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <form onSubmit={handleSubmit} className='p-2 needs-validation' noValidate ref={formRef}>
        <div className='modal-body'>

{
    showSuccess?   

    <div class="alert alert-success" role="alert">
                             
                             <div class="iq-alert-text">Event Category created successfully</div>
                             <button onClick={()=>{setShowSuccess(false)}} type="button" class="close" data-dismiss="alert" aria-label="Close">
                             <i class="ri-close-line" style={{color:"black"}}></i>
                             </button>
                          </div>

    :""
}

        



<div className="row border p-2 rounded-sm">
          
          <div className="col-12 mb-1 ">
            <label>Title <span className='text-danger'>*</span></label>
            <input type="text" name='title' onChange={handleChange} className="form-control" required placeholder="" value={formData.title}  />
          </div>
          <div className="col-12 mb-1">
            <label>Slug <span className='text-danger'>*</span></label>
            <input type="text" name='slug' onChange={handleChange} className="form-control" required placeholder="" value={formData.slug}  />
          </div>

          <div className="col-12">
            <label>Image<span className='text-danger'>*</span></label>

            <FilePond
                          files={files}
                          allowMultiple={false}
                   
                          name="avatar_url"
                          stylePanelLayout="compact"
                          styleButtonRemoveItemPosition="left"
                          styleButtonProcessItemPosition="right"
                          styleLoadIndicatorPosition="right"
                          styleProgressIndicatorPosition="right"
                          styleButtonRemoveItemAlign={false}
                          acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                          onupdatefiles={setFiles}
                          
                        />
          </div>
        
        </div>
        
        </div>

        <div className='modal-footer'>
          
          {
            isLoading?
            <button type="submit" className="btn btn-primary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Creating...</button>
            :
            <button type="submit" className="btn btn-primary">Create</button>
          }

         
          <button className="btn iq-bg-danger" onClick={toggleModal}>Cancel</button>
        </div>
      </form>
    </Modal>
  );
}

export default AddNewEventCategory;







