import React, { useRef, useState ,useEffect} from 'react';
import { Button, Modal } from 'reactstrap';
import { useEditCategoryMutation } from '../../../features/property/propertySlice';

function EditPropertyCategoryModal({ editPropertyCategoryModal, toggleEditModal,categoryDetail }) {
    const [editCategory,{isLoading}]=useEditCategoryMutation();
    const [showSuccess,setShowSuccess]=useState(false)
    const [categoryId,setCategoryId]=useState()
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    "name": "",
    "icon": "",
    "slug": "",
    "description": ""
  });


useEffect(()=>{
    console.log("detail",categoryDetail)
    setFormData({
    "name": categoryDetail&& categoryDetail.name,
    "icon": categoryDetail&&categoryDetail.icon,
    "slug": categoryDetail&&categoryDetail.slug,
    "description": categoryDetail&&categoryDetail.description
    })

    setCategoryId(categoryDetail&&categoryDetail.id)
    
  },[categoryDetail])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      try{

        console.log(formData)
        const response= await editCategory({id:categoryId,body:formData}).unwrap();
        console.log(response)
        setShowSuccess(true)
        setFormData({
            "name": "",
            "icon": "",
            "slug": "",
            "description": ""
        })
      
      }catch(e){
        console.log(e)
        setShowSuccess(false)
        
      }

      
    } else {
      formRef.current.classList.add("was-validated");
    }
  };

  return (
    <Modal size='lg' className='modal-content' isOpen={editPropertyCategoryModal} toggle={toggleEditModal}>
      <div className="modal-header">
      <h3 className="card-title">{categoryDetail&&categoryDetail.name}</h3>
              <button type="button" className="close" onClick={toggleEditModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <form onSubmit={handleSubmit} className='p-2 needs-validation' noValidate ref={formRef}>
        <div className='modal-body'>

{
    showSuccess?   

    <div class="alert alert-success" role="alert">
                             
                             <div class="iq-alert-text">Category edited successfully</div>
                             <button onClick={()=>{setShowSuccess(false)}} type="button" class="close" data-dismiss="alert" aria-label="Close">
                             <i class="ri-close-line" style={{color:"black"}}></i>
                             </button>
                          </div>

    :""
}

        



          <div className="row border p-2 rounded-sm">
            <div className="col">
              <label>Name <span className='text-danger'>*</span></label>
              <input type="text" name='name' className="form-control" required placeholder="" value={formData.name} onChange={handleChange} />
            </div>
            <div className="col">
              <label>Slug <span className='text-danger'>*</span></label>
              <input type="text" name='slug' className="form-control" required placeholder="" value={formData.slug} onChange={handleChange} />
            </div>
            <div className='col-12 mt-2'>
              <div className="form-group">
                <label>Icon <span className='text-danger'>*</span></label>
                <select className="form-control" required name="icon" value={formData.icon} onChange={handleChange}>
                  <option selected disabled={true} hidden={true} value="">Select an option</option>
              
                  <option value="fi-real-estate-house">
                            Real Estate House
                        </option>
                                            <option value="fi-apartment">
                            Apartment
                        </option>
                                            <option value="fi-shop">
                            Shop
                        </option>
                                            <option value="fi-rent">
                            Rent
                        </option>
                                            <option value="fi-house-chosen">
                            House Chosen
                        </option>
                                            <option value="fi-single-bed">
                            Single Bed
                        </option>
                                            <option value="fi-computer">
                            Computer
                        </option>
                                            <option value="fi-real-estate-buy">
                            Real Estate Buy
                        </option>
                                            <option value="fi-parking">
                            Parking
                        </option>
                                            <option value="fi-car">
                            Car
                        </option>
                                            <option value="fi-building">
                            Building
                        </option>
                                            <option value="fi-swimming-pool">
                            Swimming Pool
                        </option>
                </select>
              </div>
            </div>
          </div>

          <div className="row border p-2 rounded-sm mt-5">
            <div className="col-12">
              <label>Description</label>
              <textarea className="form-control" name="description" rows="5" value={formData.description} onChange={handleChange}></textarea>
            </div>
          </div>
        </div>

        <div className='modal-footer'>
          
          {
            isLoading?
            <button type="submit" className="btn btn-primary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Editing...</button>
            :
            <button type="submit" className="btn btn-primary">Edit</button>
          }

         
          <button className="btn iq-bg-danger" onClick={toggleEditModal}>Cancel</button>
        </div>
      </form>
    </Modal>
  );
}

export default EditPropertyCategoryModal;







