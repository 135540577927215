import React, { useEffect, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import DataTable from 'react-data-table-component';
import { Col, Row } from "reactstrap";
import { useGetContactsMutation } from '../../features/property/propertySlice';
import AddPropertyCategoryModal from '../../components/property/category/addPropertyCategoryModal';
import EditPropertyCategoryModal from '../../components/property/category/editPopertyCategoryModal';
import DeleteContactsModal from '../../components/enquiryandfaqs/contacts/deleteContactsModal';
import ViewContactsModal from '../../components/enquiryandfaqs/contacts/viewContactsModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { format } from 'date-fns';
import CustomPagination from '../../components/basic/customPagination';

function Contact() {
    const [getContacts, { isLoading }] = useGetContactsMutation();
    const [contacts, setContacts] = useState([]);
    const [contactDetail, setContactDetail] = useState(null);
    const [pending, setPending] = useState(true);

    const handleGetCategories = async () => {
        try {
            const response = await getContacts().unwrap();
            setContacts(response);
            console.log(response);
        } catch (e) {
            console.log(e);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        handleGetCategories();
    }, []);

    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const filteredItems = contacts.filter(
        item => item.subject && item.subject.toLowerCase().includes(filterText.toLowerCase())
    );

    const [propertyCategoryModal, setPropertyCategoryModal] = useState(false);
    const toggleModal = () => {
        setPropertyCategoryModal(!propertyCategoryModal);
        handleGetCategories();
    }

    const [viewContactModal, setViewContactModal] = useState(false);
    const [editPropertyCategoryModal, setEditPropertyCategoryModal] = useState(false);
    const [deletecontactsModal, setDeletePropertyCategoryModal] = useState(false);

    const toggleViewModal = () => {
        setViewContactModal(!viewContactModal);
    }

    const toggleEditModal = () => {
        setEditPropertyCategoryModal(!editPropertyCategoryModal);
        handleGetCategories();
    }

    const toggleDeleteModal = () => {
        setDeletePropertyCategoryModal(!deletecontactsModal);
        handleGetCategories();
    }

    const handleGetCategoryDetail = (row) => {
        setContactDetail(row);
        setViewContactModal(true);
    }

    

    const handleDeleteCategory = (row) => {
        setContactDetail(row);
        setDeletePropertyCategoryModal(true);
    }

    const columns = [
        {
            id: 'id',  
            name: 'Full Name',
            selector: row => <h6>{row.full_name}</h6>,
            width: '200px', // Set the width as needed
        },
        {
            name: 'Email',
            selector: row => <h6 className='text-muted'>{ row.email}</h6>,
            width: '250px', // Set the width as needed
        },
        {
            name: 'Subject',
            selector: row => row.subject,
            width: '300px', // Set the width as needed
        },
        {
            name: 'Creation Date',
            selector: row => format(new Date(row.created_at), 'dd/MM/yyyy HH:mm'),
            width: '200px', // Set the width as needed
        },
        {
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div
                        className='text-secondary action-link'
                        size="sm"
                        onClick={() => handleGetCategoryDetail(row)}
                    >
                        <i className="ri-eye-fill mr-1"></i>
                        View
                    </div>
                    <div className='text-danger action-link mx-2' onClick={() => handleDeleteCategory(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
            width: '150px', // Set the width as needed
        },
    ];
    

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex justify-content-between mb-4'>
                                <h4 className="card-title">Property Categories</h4>
                                <button type="button" onClick={toggleModal} className="btn btn-primary btn-sm mr-2">New property category</button>
                                <AddPropertyCategoryModal propertyCategoryModal={propertyCategoryModal} toggleModal={toggleModal} />
                                <ViewContactsModal viewContactModal={viewContactModal} toggleViewModal={toggleViewModal} contactDetail={contactDetail} />
                                <EditPropertyCategoryModal editPropertyCategoryModal={editPropertyCategoryModal} toggleEditModal={toggleEditModal} contactDetail={contactDetail} />
                                <DeleteContactsModal deletecontactsModal={deletecontactsModal} toggleDeleteModal={toggleDeleteModal} contactDetail={contactDetail} />
                            </div>
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className='text-primary d-flex align-content-center hover-icon'>
                                        <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                    </div>
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Subject..."
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ minWidth: '1000px' }}>
                                        <DataTable
                                            columns={columns}
                                            data={filteredItems}
                                            pagination
                                            selectableRows
                                            onSelectedRowsChange={handleChange}
                                            clearSelectedRows={toggledClearRows}
                                            selectableRowsComponentProps={{
                                                type: 'checkbox',
                                            }}
                                            selectableRowsHighlight
                                            progressPending={pending}
                                            progressComponent={
                                                <div style={{ width: "100%",padding:"10px" }}>
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                </div>
                                            }
                                            
                                            defaultSortFieldId="id" // Sorting by 'Name' column
                                            defaultSortAsc={false}
                                            paginationComponent={CustomPagination}

                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Contact;
