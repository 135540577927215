import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        login:builder.mutation({
            query:credentials=>({
                url:'/auth/login/',
                method: 'POST',
                body:{...credentials}
            })
        }),       
        getprofile:builder.mutation({
            query:body=>({
                url:'/auth/profile/',
                method: 'GET',
                
            })
        }),

        updateprofile:builder.mutation({
            query:body=>({
                url:'/auth/profile/',
                method: 'PATCH',
                body:body

                
            }),
            
        

        
    }),
    changepassword:builder.mutation({
        query:body=>({
            url:'/auth/password_change/',
            method: 'PUT',
            body:{...body}

            
        })
}),



})

})
  

export const authApiSlice1=apiSlice.injectEndpoints({
    endpoints:builder=>({
    updateprofile1:builder.mutation({
        query:(body)=>({
            url:'/auth/profile/',
            method: 'PATCH',
            body:body
            
        })
    })
}),

})

export const {
    useLoginMutation,
    useRegisterMutation,
    useVerifynumberMutation,
    useResendotpMutation,
    useGetprofileMutation,
    useUpdateprofileMutation,
    useChangepasswordMutation,
  

}=authApiSlice;


export  const {
useUpdateprofile1Mutation
}=authApiSlice1
