import React, { useEffect, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import DataTable from 'react-data-table-component';
import { Col, Row } from "reactstrap";
import { useGetAllPostMutation } from '../../features/property/propertySlice';
import DeleteBlogModal from '../../components/blog/deleteBlogModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CustomPagination from '../../components/basic/customPagination';
import { useNavigate,Link } from 'react-router-dom';
import { format } from 'date-fns';


function Posts() {
    const [getPosts, { isLoading }] = useGetAllPostMutation();
    const [posts, setPosts] = useState([]);
    const [postDetail, setpostDetail] = useState(null);
    const [pending, setPending] = useState(true);
    const navigate = useNavigate();

    const handleGetPosts = async () => {
        try {
            const response = await getPosts().unwrap();
            setPosts(response.results);
            console.log(response.results);
        } catch (e) {
            console.log(e);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        handleGetPosts();
    }, []);

    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const filteredItems = posts.filter(
        item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
    );

    const [addfaqModal, setAddFAQModal] = useState(false);
    const toggleModal = () => {
        setAddFAQModal(!addfaqModal);
        handleGetPosts();
    }

    const [viewfaqsModal, setViewFaqModal] = useState(false);
    const [editfaqsModal, setEditFaqsModal] = useState(false);
    const [deleteBlogModal, setDeleteFaqsModal] = useState(false);

    const toggleViewModal = () => {
        setViewFaqModal(!viewfaqsModal);
    }

    const toggleEditModal = () => {
        setEditFaqsModal(!editfaqsModal);
        handleGetPosts();
    }

    const toggleDeleteModal = () => {
        setDeleteFaqsModal(!deleteBlogModal);
        handleGetPosts();
    }

    const handleGetCategoryDetail = (row) => {
        setpostDetail(row);
        setViewFaqModal(true);
    }

    const handleEditCategory = (row) => {
        setpostDetail(row);
        setEditFaqsModal(true);
    }

    const handleDeleteCategory = (row) => {
        setpostDetail(row);
        setDeleteFaqsModal(true);
    }

    const columns = [
      { 
        id: 'title',
        name: 'Title',
        selector: row => {
            // Convert the HTML string from CKEditor into a DOM element
            let div = document.createElement("div");
            div.innerHTML = row.body; // Assuming 'row.answer' is the HTML string

            // Find the first paragraph in the HTML and truncate its text content
            let paragraph = div.querySelector("p");
            if (paragraph) {
                let text = paragraph.innerText || paragraph.textContent;
                paragraph.innerText = text.length > 38 ? text.slice(0, 38) + "..." : text;
            }

            // Generate the final HTML string
            let truncatedHtml = div.innerHTML;

            return (
                <div className='py-1' style={{ width: "300px" }}>
                    <h5>{row.title.length > 30 ? row.title.slice(0, 30) + "..." : row.title}</h5>
                    <div dangerouslySetInnerHTML={{ __html: truncatedHtml }} />
                </div>
            );
        },
        sortable: true,
        reorder: true,
        width: "25%"
    },

    
    {
        id: 'Category',
        name: 'Category',
        selector: row => <div> {row.category.name}</div>,
        sortable: true,
        reorder: true,
        width:"12%"
    },
    {
        id: 'Featured',
        name: 'Featured',
        selector: row => (
            <div style={{ width: "45px", height: "45px" }} className='rounded-circle my-2'>
                <img src={row.image} style={{ objectFit: "cover", height: "100%", width: "100%" }} alt="event" />
            </div>
        ),
        sortable: true,
        reorder: true,
        width:"12%"
    },
        {
            id: 'status',
            name: 'Status',
            selector: row => <h6 style={{ textTransform: 'capitalize' }}> {row.status}</h6>,
            sortable: true,
            reorder: true,
            width:"10%"
        },
        {
            id: 'created_at',
            name: 'Created At',
            selector: row => <h6 > {format(new Date(row.created_at), 'dd/MM/yyyy HH:mm')}</h6>,
            sortable: true,
            reorder: true,
            width:"15%"
        },
        {
            id: 'actions',
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Link to={`/posts/${row.id}`}
                        className='text-secondary action-link'
                        size="sm"
                        
                    >
                        <i className="ri-eye-fill mr-1"></i>
                        View
                    </Link>
                    <Link to={`/posts/edit/${row.id}`} className='text-primary action-link mx-3' size="sm" >
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </Link>
                    <div className='text-danger action-link' onClick={() => handleDeleteCategory(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
            width:"30%"
        },
    ];

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex justify-content-between mb-4'>
                                <h4 className="card-title">Blog Posts</h4>
                                <button type="button" onClick={()=>{navigate('/admin/posts/create')}} className="btn btn-primary btn-sm mr-2">New Post</button>

                                <DeleteBlogModal deleteBlogModal={deleteBlogModal} toggleDeleteModal={toggleDeleteModal} postDetail={postDetail} />
                            </div>
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className='text-primary d-flex align-content-center hover-icon'>
                                        <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                    </div>
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Title..."
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <DataTable
                                        columns={columns}
                                        data={filteredItems}
                                        progressPending={pending}
                                        pagination
                                        paginationComponent={CustomPagination}
                                        selectableRows
                                        progressComponent={
                                                <div style={{ width: "100%", padding: "10px" }}>
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                </div>
                                            }
                                        selectableRowsHighlight
                                        onSelectedRowsChange={handleChange}
                                        clearSelectedRows={toggledClearRows}
                                        highlightOnHover
                                        
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Posts;
