import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import Card from '../../components/report/card';
import Card2 from '../../components/report/card2';
import FinancialChart from '../../components/report/financialChart';
import { useGetFinancialDetailsMutation} from '../../features/property/propertySlice';
import Skeleton from 'react-loading-skeleton';

function FinancialReport() {
  const [getFinancialDetails, { isLoading }] = useGetFinancialDetailsMutation();
  const [financialDetails,setFinancialDetails]=useState();
  const today = new Date();
  const twoWeeksAgo = new Date(today);
  twoWeeksAgo.setDate(today.getDate() - 14);

  const formatDate = (date) => date.toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(formatDate(twoWeeksAgo));
  const [endDate, setEndDate] = useState(formatDate(today));

  const handleGetDetails = async (startDate, endDate) => {
    try {
      const response = await getFinancialDetails({ start_date: startDate, end_date: endDate }).unwrap();
      console.log(response);
      setFinancialDetails(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetDetails(startDate, endDate); // Fetch report details with the initial dates
  }, []);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };


  const handleSearch =async()=>{
    handleGetDetails(startDate, endDate); 
  }

  return (
    <>
      <NavbarAndSideBar />

      <div className="content-page" id="content-page">
        <div className="container-fluid" style={{ marginBottom: "100px" }}>
        

        <Row className='px-lg-5 px-md-3 px-sm-2' >
   
   <div className='w-full w-100 d-md-flex  justify-content-between'>
   <h5 className='mb-2'>Financial Report </h5>
   <div className='iq-card-header d-md-flex justify-content-between'>
   

<div className='d-flex justify-content-between'>
                      <input 
                                          type="date" 
               className="form-control" 
               name='start_date' 
               id="start-date" 
               value={startDate} 
               onChange={handleStartDateChange} 
                      ></input>
                      <span class="badge badge-light text-center mx-2 d-flex justify-content-center align-items-center" >TO</span>
                      <input 
                         type="date" 
               className="form-control" 
               name='end_date' 
               id="end-date" 
               value={endDate} 
               onChange={handleEndDateChange} ></input>
                      <button onClick={handleSearch} type="button" class="btn btn-primary  mx-1 "><i class="ri-search-line pr-0"></i></button>
</div>
   </div>

   </div>
      </Row>
          <Row className='px-lg-5 px-md-3 px-sm-2 mt-3'>
            <div className='row w-100'>
              <div className="col-lg-3 col-md-4 col-sm-6">
              {
                isLoading? (


<Skeleton style={{width:"100%",height:"150px"}}/>                ) : financialDetails&& (
                  <Card title="Total Received" amount={financialDetails.total_amount_received===null?"0":financialDetails.total_amount_received} />
                ) 
              }

              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">

              {
                isLoading? (


<Skeleton style={{width:"100%",height:"150px"}}/>                ) : financialDetails&& (
                  <Card title="Host Payments" amount={financialDetails.host_payments===null?"0":financialDetails.host_payments} />
                ) 
              }
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
              {
                isLoading? (


<Skeleton style={{width:"100%",height:"150px"}}/>                ) : financialDetails&& (
                  <Card title="Platform Fees (Users)" amount={financialDetails.platform_fees_users===null?"0":financialDetails.platform_fees_users} />
                ) 
              }
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 ">

              {
                isLoading? (


<Skeleton style={{width:"100%",height:"150px"}}/>                ) : financialDetails&& (
                  <Card title="Platform Fees (Host)" amount={financialDetails.platform_fees_host===null?"0":financialDetails.platform_fees_host} />
                ) 
              }
              </div>
            </div>
          </Row>

          <Row className='px-lg-5 px-md-3 px-sm-2 mt-3'>
            <h5 className='mb-2'>Outgoing Payment</h5>
            <div className='row w-100'>
              <div className="col-lg-3 col-md-4 col-sm-6">

              {
                isLoading? (


<Skeleton style={{width:"100%",height:"150px"}}/>                ) : financialDetails&& (
                  <Card2 title="Amount WIthdrawn(Host)" amount={financialDetails.amount_withdrawn_host===null?"0":financialDetails.amount_withdrawn_host} />
                ) 
              }
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">



              {
                isLoading? (


<Skeleton style={{width:"100%",height:"150px"}}/>                ) : financialDetails&& (
                  <Card2 title="Refunds" amount={financialDetails.refunds===null?"0":financialDetails.refunds} />
                ) 
              }




              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">

          
              </div>
            </div>
          </Row>

          <Row className='px-lg-5 px-md-3 px-sm-2'>
            <div className='w-100 col-12'>
              <div className="iq-card">
              {
                financialDetails &&(
                  <FinancialChart data={financialDetails&&financialDetails} />
                )
              }
              </div>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
}

export default FinancialReport;
