import React,{useEffect,useState} from 'react'
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar'
import { Card, CardBody, CardHeader, CardTitle, Col, Row, UncontrolledCollapse } from "reactstrap";
import DataTable from "react-data-table-component";

const columns = [
	{
		name: 'Title',
		selector: row => row.title,
	},
	{
		name: 'Year',
		selector: row => row.year,
	},
];

const data = [
  	{
		id: 1,
		title: 'Beetlejuice',
		year: '1988',
	},
	{
		id: 2,
		title: 'Ghostbusters',
		year: '1984',
	},
]
function Roles() {
  

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row>
                        <Col sm="12">
                            <Card className="iq-card">
                                <CardHeader className="iq-card-header d-flex justify-content-between">
                                    <CardTitle className="iq-header-title">
                                        <h4 className="card-title">React Datatable</h4>
                                    </CardTitle>
                                    <span className="text-primary float-right" id="collapse1">
                                        <i className="ri-code-s-slash-line" />
                                    </span>
                                </CardHeader>
                                <CardBody className="iq-card-body">
                                    <UncontrolledCollapse toggler="#collapse1">
                                        <Card>
                                            <kbd className="bg-dark">
                                                <span data-copy-target="#basic-table1" title="Copy" data-toggle="tooltip" className="float-right" data-copy="true">
                                                    Copy
                                                </span>
                                                <pre className="text-white">
                                                    <code id="basic-table1">{`
                                                        // Example DataTable usage
                                                        <DataTable
                                                            columns={tableData.header}
                                                            data={tableData.body}
                                                            pagination
                                                        />
                                                    `}</code>
                                                </pre>
                                            </kbd>
                                        </Card>
                                    </UncontrolledCollapse>
                                    <p>
                                        React Data Table Component makes it easy to use. It has <code> pagination </code> attribute for adding the pagination. It's also set responsive by default.
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="12">
                        <DataTable
			columns={columns}
			data={data}
            pagination
            expandableRows


		/>


                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Roles;
