import React, { useRef, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { useAddAmenityMutation } from '../../../features/property/propertySlice';

function AddPropertyAmenityModal({ propertyAmenityModal, toggleModal }) {
    const [addAmenity,{isLoading}]=useAddAmenityMutation();
    const [showSuccess,setShowSuccess]=useState(false)
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    "name": "",
  
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      try{

        console.log(formData)
        const response= await addAmenity(formData).unwrap();
        console.log(response)
        setShowSuccess(true)
        setFormData({
            "name": "",
           
        })
      }catch(e){
        console.log(e)
        setShowSuccess(false)
      }

      
    } else {
      formRef.current.classList.add("was-validated");
    }
  };

  return (
    <Modal  className='modal-content' isOpen={propertyAmenityModal} toggle={toggleModal}>
      <div className="modal-header">
        <h3 className="card-title">Create property amenity</h3>
        <button type="button" className="close" onClick={toggleModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <form onSubmit={handleSubmit} className='p-2 needs-validation' noValidate ref={formRef}>
        <div className='modal-body'>

{
    showSuccess?   

    <div class="alert alert-success" role="alert">
                             
                             <div class="iq-alert-text">Amenity created successfully</div>
                             <button onClick={()=>{setShowSuccess(false)}} type="button" class="close" data-dismiss="alert" aria-label="Close">
                             <i class="ri-close-line" style={{color:"black"}}></i>
                             </button>
                          </div>

    :""
}

        



          <div className="row p-2 rounded-sm">
          
            <div className='col-12 mt-2'>
              <div className="form-group">
                <label>Name <span className='text-danger'>*</span></label>
                <input type="text" name='name' className="form-control" required placeholder="" value={formData.name} onChange={handleChange} />
              </div>
            </div>
          </div>

       
        </div>

        <div className='modal-footer'>
          
          {
            isLoading?
            <button type="submit" className="btn btn-primary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Creating...</button>
            :
            <button type="submit" className="btn btn-primary">Create</button>
          }

         
          <button className="btn iq-bg-danger" onClick={toggleModal}>Cancel</button>
        </div>
      </form>
    </Modal>
  );
}

export default AddPropertyAmenityModal;







