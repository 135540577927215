import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 520 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 520, min: 0 },
    items: 1,
  },
};

function RegionCarousel2({ data  }) {
console.log(data,"data");


  return (
    <Carousel
    responsive={responsive}
    infinite={true}
    autoPlay={false}
    keyBoardControl={true}
    showDots={false}
    arrows={true}
    customLeftArrow={<i className="las la-caret-left" />}
    customRightArrow={<i className="las la-caret-right " />}
    removeArrowOnDeviceType={["tablet", "mobile"]}
    itemClass="carousel-item-padding-40-px"
  >
      {data && data.map((region, index) => (
        <div key={index} className="card iq-mb-3 shadow-sm" style={{ width: '250px' }}>
          <div className="card-body text-center">
            <h6>{region.region}</h6>
          </div>
          <div className="card-footer text-center">{region.count}</div>
        </div>
      ))}
    </Carousel>
  );
}

export default RegionCarousel2;
