import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom';

function PageLoader() {
    const [isActive, setIsActive] = useState(false);
    const location = useLocation(); // Hook from React Router to detect route changes
  
    useEffect(() => {
      // Function to handle the activation and fade-out of the loading screen
      const activateLoading = () => {
        setIsActive(true); // Activate loading
  
        // Set a timeout to deactivate loading after 2 seconds
        setTimeout(() => {
          setIsActive(false);
        }, 2000);
      };
  
      // Call activateLoading when the component mounts
      activateLoading();
  
      // Additionally, call activateLoading whenever the location changes
      return () => {}; // Clean-up function does nothing here
    }, [location]); // Dependency array includes location to react to route changes
  
    // Do not render the component if not active
    if (!isActive) return null;
  
  return (
   
    <div id="loading" className={` ${isActive ? 'active' : ''}`}>
    <div id="loading-center">
       <div className="loader">
          <div className="cube">
             <div className="sides">
                <div className="top"></div>
                <div className="right"></div>
                <div className="bottom"></div>
                <div className="left"></div>
                <div className="front"></div>
                <div className="back"></div>
             </div>
          </div>
       </div>
    </div>
 </div>
  )
}

export default PageLoader