import React, { useRef, useState,useEffect } from 'react'
import logo from "../../assets/images/site_logo.png"
import  avatar from "../../assets/images/user/1.jpg"
import { logOut } from '../../features/auth/authSlice'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useGetProfileMutation } from '../../features/property/propertySlice'
function Navbar({toogleSidebar,handletoggleSidebar}) {

const [searchdiv,setSearchDiv]=useState(false)
const [profilediv,setProfileDiv]=useState(false)
const searchdivRef=useRef(null)
const profiledivRef=useRef(null)
const dispatch=useDispatch()
const [getprofile,{isLoading}]=useGetProfileMutation()
const handleGetProfle=async()=>{
   try{

      const response = await getprofile().unwrap();
      

   }
   catch(e){
      console.log(e)
   }

}

useEffect(()=>{

   handleGetProfle()

},[])

const handleClickOutsideSearch = (event) => {
   if (searchdivRef.current && !searchdivRef.current.contains(event.target)) {
     setSearchDiv(false);
   }
 };

 useEffect(() => {
   document.addEventListener('mousedown', handleClickOutsideSearch);

   return () => {
     document.removeEventListener('mousedown', handleClickOutsideSearch);
   };
 }, []);




 const handleClickOutsideProfile = (event) => {
   if (profiledivRef.current && !profiledivRef.current.contains(event.target)) {
     setProfileDiv(false);
   }
 };

 useEffect(() => {
   document.addEventListener('mousedown', handleClickOutsideProfile);

   return () => {
     document.removeEventListener('mousedown', handleClickOutsideProfile);
   };
 }, []);

 const handleLogout=()=>{
   dispatch(logOut())
   
   
 
 }

  return (
    <div className="iq-top-navbar">

    
            <div className="iq-navbar-custom">
            
           
               <div className="iq-sidebar-logo " style={{marginLeft:"50px"}} >
                  <div className="top-logo">
                     <a href="index.html" className="logo">
                     <img src={logo} alt=""/>
                     <span></span>
                     </a>
                  </div>
               </div>
               <div onClick={handletoggleSidebar}   style={{position:"absolute",left:"10px" ,width:"50px"}}  className="iq-menu-bt align-self-center togglebar">
                     <div className={`wrapper-menu ${toogleSidebar?"open":""}`}>
                        <div className="line-menu half start"></div>
                        <div className="line-menu"></div>
                        <div className="line-menu half end"></div>
                     </div>
                  </div>


            

              
               <nav className="navbar navbar-expand-lg navbar-light p-0 ">
                  
                 
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  
                     <ul className="navbar-nav ml-auto navbar-list">
                    
                        <li className={`nav-item ${searchdiv?"iq-show":""}`}>
                           <a onClick={()=>{setSearchDiv(true)}}   className="search-toggle iq-waves-effect " href="#"><i className="ri-search-line"></i></a>
                           <form action="#" className="search-box" ref={searchdivRef}>
                              <input type="text" className="text search-input" placeholder="Type here to search..." />
                           </form>
                        </li>
                       
                   
                        <li className="nav-item iq-full-screen"><a href="#" className="iq-waves-effect" id="btnFullscreen"><i className="ri-fullscreen-line"></i></a></li>
                     </ul>
                  </div>
                  <ul className="navbar-list">
                     <li className={`${profilediv?"iq-show":""}`}>
                        <a onClick={()=>{setProfileDiv(true)}} href="#" className="search-toggle iq-waves-effect bg-primary text-white"><img src={avatar} className="img-fluid rounded" alt="user"/></a>
                        <div ref={profiledivRef} className="iq-sub-dropdown iq-user-dropdown">
                           <div className="iq-card iq-card-block iq-card-stretch iq-card-height shadow-none m-0">
                              <div className="iq-card-body p-0 ">
                                 <div className="bg-primary p-3">
                                    <h5 className="mb-0 text-white line-height">Super Admin</h5>
                                    <span className="text-white font-size-12">Available</span>
                                 </div>
                         
                               
                             
                                 <div className="d-inline-block w-100 text-center p-3">
                                    <a className="iq-bg-danger iq-sign-btn"  onClick={handleLogout} role="button">Sign out<i className="ri-login-box-line ml-2"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </li>
                  </ul>
               </nav>
            </div>
         </div>
  )
}

export default Navbar