import React, { useEffect, useState } from 'react'
import logo from "../../assets/images/site_logo.png"
import { Link, useLocation,matchPath,useNavigate  } from 'react-router-dom'
import { Collapse } from 'reactstrap'

function Sidebar({handletoggleSidebar}) {
    const [dashbaordActive,setDashboardActive] =useState()
    const [userManagementActive,setUserManagementActive] = useState()
    const [propertyManagementActive,setPropertyManagementActive] = useState()
    const [enquiyandeventActive,setEnquiryandEventActive] = useState()
    const [manageSiteActive,setManageSiteActive] = useState()
    const [manageBlogActive,setManageBlogActive] = useState()
    const [reportActive,setReportActive] = useState()
    const [partnerActive,setPartnerActive] = useState()


    const navigate=useNavigate()



    const [toggleUser,setToggleUser] = useState(false)
    const [toggleProperty,setToggleProperty] = useState(false)
    const [toggleEnquiryandEvents,setToggleEnquiryandEvents] = useState(false)
    const [toggleManageSite,setToggleManageSite] = useState(false)
    const [toggleReport,setToggleReport] = useState(false)
    const [togglePartner,setTogglePartner] = useState(false)


    const [toggleManageBlog,setToggleManageBlog] = useState(false)
   const  handleNavigate=(path) =>{
       handletoggleSidebar()
       navigate(path)
       
   }



    const location=useLocation()


    const handletoggleUser=()=>{

        setToggleUser(!toggleUser)

    }

    const handletoggleProperty=()=>{

      setToggleProperty(!toggleProperty)

  }

  const handletoggleEnquiryandEvents=()=>{

   setToggleEnquiryandEvents(!toggleEnquiryandEvents)

}

const handletoggleSettings=()=>{

   setToggleManageSite(!toggleManageSite)


}

const handletoggleBlog=()=>{

   setToggleManageBlog(!toggleManageBlog)

}


const handletoggleReport=()=>{

   setToggleReport(!toggleReport)

}


const handletogglePartner=()=>{

   setTogglePartner(!togglePartner)

}

    useEffect(()=>{
        if (location.pathname==="/") {
            setDashboardActive(true)
        }
        if (location.pathname==="/users/roles"){
            setUserManagementActive(true)
            setToggleUser(true)
        }
   

     if (location.pathname==="/users/list"){
      setUserManagementActive(true)
      setToggleUser(true)
  }
  const pathsToMatch = [
   '/property-categories',
   '/property-amenities',
   '/property-previleges',
   '/properties',
   '/properties/edit/:id'
 ];

 const isPropertyManagementPath = pathsToMatch.some(path =>
   matchPath({ path, exact: true, strict: false }, location.pathname)
 );

 if (isPropertyManagementPath) {
   setPropertyManagementActive(true);
   setToggleProperty(true);
 }

 if (isPropertyManagementPath) {
   setPropertyManagementActive(true);
   setToggleProperty(true);
 }

  if (location.pathname==="/faqs" || location.pathname==="/events" || location.pathname==="/events-category" || location.pathname==="/contacts" || location.pathname.match(/^\/events\/edit\/(\d+)$/)){

   setEnquiryandEventActive(true)
   setToggleEnquiryandEvents(true)
}

if (location.pathname==="/settings" || location.pathname==="/news-letters" || location.pathname==="/text-messages"||location.pathname==='/website-layout'){

   setManageSiteActive(true)
   setToggleManageSite(true)
}


if (location.pathname==="/posts" || location.pathname==="/post-categories" ||location.pathname==="/post-tags"){

   setManageBlogActive(true)
   setToggleManageBlog(true)
}



if (location.pathname==="/report/financial" || location.pathname==="/report/registration" ||location.pathname==="/report/property"){

   setReportActive(true)
   setToggleReport(true)
}



if (location.pathname==="/partner/add" || location.pathname==="/partner/list"){

   setPartnerActive(true)
   setTogglePartner(true)
}


    },[])
  return (
    <div class="iq-sidebar">
            <div class="iq-sidebar-logo d-flex justify-content-between">
               <a href="index.html">
               <img src={logo}  alt=""/>
               
               </a>
               <div class="iq-menu-bt align-self-center">
                  <div class="wrapper-menu">
                     <div class="line-menu half start"></div>
                     <div class="line-menu"></div>
                     <div class="line-menu half end"></div>
                  </div>
               </div>
            </div>
            <div id="sidebar-scrollbar" style={{overflowY:"scroll",marginTop:"20px"}} >
               <nav class="iq-sidebar-menu" style={{marginBottom:"80px"}}>
                  <ul id="iq-sidebar-toggle" class="iq-menu" >
                                 

                  <li class="iq-menu-title"><i class="ri-separator"></i><span>Main</span></li>
                    
                      
                  <hr/> 
                  <li className={`${dashbaordActive?"active":""}`}><a onClick={()=>{handleNavigate("/")}}  class="iq-waves-effect"><i  class="ri-home-4-line"></i><span>Dashboard</span></a></li> 

                     <li className={`${userManagementActive?"active":""}`}>
                        <a href="#user-info" onClick={handletoggleUser} class={` iq-waves-effect  ${toggleUser?"":"collapsed"}`}  data-toggle="collapse" aria-expanded="false"><i class="ri-user-line"></i><span>User Management</span><i class="ri-arrow-right-s-line iq-arrow-right"    style={{
        transition: 'transform 0.5s ease',
        transform: toggleUser ? 'rotate(90deg)' : 'rotate(0deg)',
        cursor: 'pointer',
      }}></i></a>
      <Collapse isOpen={toggleUser} >
                        <ul id="user-info" class={`iq-submenu  `} >
                           <li><a onClick={()=>{handleNavigate("/users/list")}}   >Users</a></li>
                         
                        </ul>
                        </Collapse>
                     </li>

                     <li className={`${partnerActive?"active":""}`}>
                        <a href="#user-info" onClick={handletogglePartner} class={` iq-waves-effect  ${togglePartner?"":"collapsed"}`}  data-toggle="collapse" aria-expanded="false"><i class="ri-group-2-line"></i><span>Partner</span><i class="ri-arrow-right-s-line iq-arrow-right"    style={{
        transition: 'transform 0.5s ease',
        transform: togglePartner ? 'rotate(90deg)' : 'rotate(0deg)',
        cursor: 'pointer',
      }}></i></a>
      <Collapse isOpen={togglePartner} >
                        <ul id="user-info" class={`iq-submenu  `} >
                           <li><a onClick={()=>{handleNavigate("/partner/list")}}  >List</a></li>
                           <li><a onClick={()=>{handleNavigate("/partner/add")}}   >Add New Partner</a></li>
                         
                        </ul>
                        </Collapse>
                     </li>

                     <li className={`${propertyManagementActive?"active":""}`}>
                        <a href="#ecommerce" onClick={handletoggleProperty}  class={` iq-waves-effect  ${toggleProperty?"":"collapsed"}`}   data-toggle="collapse" aria-expanded="false"> <i class=""><div data-icon="g " class="icon me-2"></div></i><span>Manage Property</span><i class="ri-arrow-right-s-line iq-arrow-right"
                        
                        style={{
        transition: 'transform 0.5s ease',
        transform: toggleProperty? 'rotate(90deg)' : 'rotate(0deg)',
        cursor: 'pointer',
      }}></i></a>

                        <Collapse isOpen={toggleProperty} >
                        <ul id="ecommerce" class="iq-submenu " data-parent="#iq-sidebar-toggle">
                        <li><a  onClick={()=>{handleNavigate("/property-amenities")}}  style={{cursor:"pointer"}}  >Property Amenities</a></li>
                           <li><a onClick={()=>{handleNavigate("/property-categories")}}  style={{cursor:"pointer"}}   >Property Categories</a></li>
                           <li><a  onClick={()=>{handleNavigate("/property-previleges")}}  style={{cursor:"pointer"}}   >Property previleges</a></li>
                          

                           <li><a onClick={()=>{handleNavigate("/properties")}}   style={{display:"flex",justifyContent:"space-between",cursor:"pointer"}}>Properties
                            
                           </a></li>
                           
                        </ul>
                        </Collapse>
                     </li>
                     <li className={`${enquiyandeventActive?"active":""}`}>
                        <a href="#menu-design" class="iq-waves-effect " onClick={handletoggleEnquiryandEvents}  data-toggle="collapse" aria-expanded="false"><i class="ri-calendar-event-fill"></i><span>Enquiry & Events</span><i class="ri-arrow-right-s-line iq-arrow-right"
                                      style={{
        transition: 'transform 0.5s ease',
        transform: toggleEnquiryandEvents? 'rotate(90deg)' : 'rotate(0deg)',
        cursor: 'pointer',
      }}
                        ></i></a>
                        <Collapse isOpen={toggleEnquiryandEvents}>
                        <ul id="menu-design" class="iq-submenu " data-parent="#iq-sidebar-toggle">
                           <li><a style={{cursor:"pointer"}}  onClick={()=>{handleNavigate("/faqs")}}  >Faqs</a></li>
                           <li><a style={{cursor:"pointer"}}  onClick={()=>{handleNavigate('/events-category')}}  >Event Category</a></li>

                           <li><a style={{cursor:"pointer"}}  onClick={()=>{handleNavigate('/events')}}  >Events</a></li>
                        </ul>
                        </Collapse>
                     </li>


                     <li className={`${manageBlogActive?"active":""}`}>
                        <a href="#menu-design" class="iq-waves-effect " onClick={handletoggleBlog}  data-toggle="collapse" aria-expanded="false"><i class="ri-profile-line"></i><span>Manage Blog</span><i class="ri-arrow-right-s-line iq-arrow-right"
                                      style={{
        transition: 'transform 0.5s ease',
        transform: toggleManageBlog? 'rotate(90deg)' : 'rotate(0deg)',
        cursor: 'pointer',
      }}
                        ></i></a>
                        <Collapse isOpen={toggleManageBlog}>
                        <ul id="menu-design" class="iq-submenu " data-parent="#iq-sidebar-toggle">
                           <li><a style={{cursor:"pointer"}} onClick={()=>{handleNavigate("/post-categories")}} >Post Categories</a></li>
                           <li><a style={{cursor:"pointer"}}  onClick={()=>{handleNavigate("/post-tags")}}>Post Tags</a></li>

                           <li><a style={{cursor:"pointer"}}  onClick={()=>{handleNavigate('/posts')}}>  Posts</a></li>
                        </ul>
                        </Collapse>
                     </li>


                     <li className={`${reportActive?"active":""}`}>
                        <a href="#menu-design" class="iq-waves-effect " onClick={handletoggleReport}  data-toggle="collapse" aria-expanded="false"><i class="ri-folder-chart-line"></i><span>Reports</span><i class="ri-arrow-right-s-line iq-arrow-right"
                                      style={{
        transition: 'transform 0.5s ease',
        transform: toggleReport? 'rotate(90deg)' : 'rotate(0deg)',
        cursor: 'pointer',
      }}
                        ></i></a>
                        <Collapse isOpen={toggleReport}>
                        <ul id="menu-design" class="iq-submenu " data-parent="#iq-sidebar-toggle">
                           <li><a style={{cursor:"pointer"}} onClick={()=>{handleNavigate("/report/financial")}}>Financial</a></li>
                           <li><a style={{cursor:"pointer"}} onClick={()=>{handleNavigate('/report/registration')}}  >Registration</a></li>
                           <li><a style={{cursor:"pointer"}} onClick={()=>{handleNavigate('/report/property')}}  >Property</a></li>
 
                        </ul>
                        </Collapse>
                     </li>

                     <li className={`${manageSiteActive?"active":""}`}>
                        <a href="#menu-design" class="iq-waves-effect " onClick={handletoggleSettings}  data-toggle="collapse" aria-expanded="false"><i class="ri-pages-line"></i><span>Manage site</span><i class="ri-arrow-right-s-line iq-arrow-right"
                                      style={{
        transition: 'transform 0.5s ease',
        transform: toggleManageSite? 'rotate(90deg)' : 'rotate(0deg)',
        cursor: 'pointer',
      }}
                        ></i></a>
                        <Collapse isOpen={toggleManageSite}>
                        <ul id="menu-design" class="iq-submenu " data-parent="#iq-sidebar-toggle">
                           <li><a style={{cursor:"pointer"}} onClick={()=>{handleNavigate("/settings")}}>General Settings</a></li>
                           <li><a style={{cursor:"pointer"}} onClick={()=>{handleNavigate('/website-layout')}}  >Website Layout</a></li>
                           <li><a style={{cursor:"pointer"}} onClick={()=>{handleNavigate('/news-letters')}}  >News Letters</a></li>
                        </ul>
                        </Collapse>
                     </li>
                 
                  
                  </ul>
               </nav>
               <div class="p-3"></div>
            </div>
         </div>
  )
}

export default Sidebar