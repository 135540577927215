import React, { useEffect, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import DataTable from 'react-data-table-component';
import { Col, Row } from "reactstrap";
import ViewPropertyPrevilegeModal from '../../components/property/previlege/viewPropertyPrevilegeModal';
import DeletePropertyPrevilegeModal from '../../components/property/previlege/deletePropertyPrevivlegeModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useGetPrevilegesMutation } from '../../features/property/propertySlice';
import AddPropertyPrevilegeModal from '../../components/property/previlege/addPropertyPrevilegeModal';
import EditPropertPrevilegeModal from '../../components/property/previlege/editPropertyPrevilegeModal';
import CustomPagination from '../../components/basic/customPagination';
function PropertyAmenities() {
    const [getPrevileges, { isLoading }] = useGetPrevilegesMutation();
    const [previleges, setPrevileges] = useState([]);
    const [previlegeDetail, setPrevilegeDetail] = useState(null);
    const [pending, setPending] = useState(true);

    const handlegetPrevileges = async () => {
        try {
            const response = await getPrevileges().unwrap();
            setPrevileges(response);
            console.log(response);
        } catch (e) {
            console.log(e);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        handlegetPrevileges();
    }, []);

    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const filteredItems = previleges.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const [propertyPrevilegeModal, setPropertyPrevilegeModal] = useState(false);
    const toggleModal = () => {
        setPropertyPrevilegeModal(!propertyPrevilegeModal);
        handlegetPrevileges();
        
    }

    const [viewPropertyPrevilegeModal, setViewPropertyPrevilegeModal] = useState(false);
    const [editPropertyPrevilegeModal, setEditPropertyPrevilegeModal] = useState(false);
    const [deletePropertyPrevilegeModal, setDeletePropertyPrevilegeModal] = useState(false);

    const toggleViewModal = () => {
        setViewPropertyPrevilegeModal(!viewPropertyPrevilegeModal);
       
    }

    const toggleEditModal = () => {
        setEditPropertyPrevilegeModal(!editPropertyPrevilegeModal);
        handlegetPrevileges();
    }

    const toggleDeleteModal = () => {
        setDeletePropertyPrevilegeModal(!deletePropertyPrevilegeModal);
        handlegetPrevileges();
    }

    const handleGetAmenityDetail = (row) => {
        setPrevilegeDetail(row);
        setViewPropertyPrevilegeModal(true);
    }

    const handleEditCategory = (row) => {
        setPrevilegeDetail(row);
        setEditPropertyPrevilegeModal(true);
    }

    const handleDeleteAmenity = (row) => {
        setPrevilegeDetail(row);
        setDeletePropertyPrevilegeModal(true);
    }

    const columns = [
        {
            id: 'id',  
            name: 'Name',
            selector: row => row.name,
        },
    
        {
            name: 'Actions',
            cell: row => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div
                        className='text-secondary action-link'
                        size="sm"
                        onClick={() => handleGetAmenityDetail(row)}
                    >
                        <i className="ri-eye-fill mr-1"></i>
                        View
                    </div>
                    <div className='text-primary action-link mx-3' size="sm" onClick={() => handleEditCategory(row)}>
                        <i className="ri-pencil-fill mr-1"></i>
                        Edit
                    </div>
                    <div className='text-danger action-link' onClick={() => handleDeleteAmenity(row)}>
                        <i className="ri-delete-bin-fill mr-1"></i>Delete
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <NavbarAndSideBar />
            <div className="content-page" id="content-page">
                <div className="container-fluid">
                    <Row style={{ marginBottom: "100px" }}>
                        <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
                            <div className='d-flex justify-content-between mb-4'>
                                <h4 className="card-title">Property Previleges</h4>
                                <button type="button" onClick={toggleModal} className="btn btn-primary btn-sm mr-2">New property Previlege</button>
                                <AddPropertyPrevilegeModal propertyPrevilegeModal={propertyPrevilegeModal} toggleModal={toggleModal} />
                                <ViewPropertyPrevilegeModal viewPropertyPrevilegeModal={viewPropertyPrevilegeModal} toggleViewModal={toggleViewModal} previlegeDetail={previlegeDetail} />
                                <EditPropertPrevilegeModal editPropertyPrevilegeModal={editPropertyPrevilegeModal} toggleEditModal={toggleEditModal} previlegeDetail={previlegeDetail} />
                                <DeletePropertyPrevilegeModal deletePropertyPrevilegeModal={deletePropertyPrevilegeModal} toggleDeleteModal={toggleDeleteModal} previlegeDetail={previlegeDetail} />
                            </div>
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className='text-primary d-flex align-content-center hover-icon'>
                                        <i className="ri-more-2-fill" style={{ fontSize: "25px", display: `${selectedRows.length > 0 ? '' : "none"}` }}></i>
                                    </div>
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Name..."
                                            value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <div style={{ minWidth: '1000px' }}>
                                        <DataTable
                                            columns={columns}
                                            data={filteredItems}
                                            pagination
                                            selectableRows
                                            onSelectedRowsChange={handleChange}
                                            clearSelectedRows={toggledClearRows}
                                            selectableRowsComponentProps={{
                                                type: 'checkbox',
                                            }}
                                            selectableRowsHighlight
                                            progressPending={pending}
                                            progressComponent={
                                                <div style={{ width: "100%" ,padding:"10px" }}>
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                </div>
                                            }

                                            defaultSortFieldId="id" // Sorting by 'Name' column
                                            defaultSortAsc={false}

                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default PropertyAmenities;
