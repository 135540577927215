import React,{useState,useEffect, useRef} from 'react'
import { Modal } from 'reactstrap'
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useEditEventCategoryMutation } from '../../../features/property/propertySlice';
import { useNavigate } from 'react-router-dom';


// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);




function EditEventCategoryModal({editEventCategoryModal,toggleEditModal ,eventCategoryDetail}) {
const [editEventCategory,{isLoading}]=useEditEventCategoryMutation();

    const [files, setFiles] = useState([]);
    const [formData, setFormData] = useState({
        title: "",
        slug: "",
        image:"",
      
        
    
      });
      const navigate=useNavigate()
    
      useEffect(() => {
        if (eventCategoryDetail) {
          setFormData({
            title: eventCategoryDetail.title,
            image: eventCategoryDetail.image,
            slug: eventCategoryDetail.slug,

          });
    
          // Convert image URL to a File object
          fetch(eventCategoryDetail.image)
            .then(res => res.blob())
            .then(blob => {
              const file = new File([blob], 'image.jpg', { type: blob.type });
              setFiles([
                {
                  source: file,
                  options: {
                    type: 'local'
                  }
                }
              ]);
            });
        }
      }, [eventCategoryDetail]);

const formRef=useRef()

      const handleEditEvent = async (e) => {
        e.preventDefault();
        const isValid = formRef.current.checkValidity();
        if (isValid) {
          try {
            const formDataObj = new FormData();
            for (const key in formData) {
              formDataObj.append(key, formData[key]);
            }
            if (files.length > 0) {
              files.forEach((file) => {
                formDataObj.append('image', file.file);
              });
            }
      
            // Log formData contents
            for (const pair of formDataObj.entries()) {
              console.log(`${pair[0]}: ${pair[1]}`);
            }
      
            const response = await editEventCategory({ id: eventCategoryDetail.id, formData: formDataObj }).unwrap();
            console.log(response);
      
            setFormData({
              title: "",
              slug: "",
            });
            setFiles([]);
            toggleEditModal()
          } catch (e) {
            console.log(e);
          }
        } else {
          formRef.current.classList.add("was-validated");
        }
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

  return (

    <Modal size='lg' className='modal-content' isOpen={editEventCategoryModal} toggle={toggleEditModal}>
    <div className="modal-header">
      <h3 className="card-title">Edit Event category</h3>
      <button type="button" className="close" onClick={toggleEditModal} aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <form  className='p-2 needs-validation' onSubmit={handleEditEvent} ref={formRef} noValidate >
      <div className='modal-body'>


      



<div className="row border p-2 rounded-sm">
        
        <div className="col-12 mb-1 ">
          <label>Title <span className='text-danger'>*</span></label>
          <input type="text" name='title' onChange={handleChange} className="form-control" required placeholder="" value={formData.title}  />
        </div>
        <div className="col-12 mb-1">
          <label>Slug <span className='text-danger'>*</span></label>
          <input type="text" name='slug' onChange={handleChange}  className="form-control" required placeholder="" value={formData.slug}  />
        </div>

        <div className="col-12">
          <label>Image<span className='text-danger'>*</span></label>

          <FilePond
                        files={files}
                        allowMultiple={false}
                 
                        name="avatar_url"
                        stylePanelLayout="compact"
                        styleButtonRemoveItemPosition="left"
                        styleButtonProcessItemPosition="right"
                        styleLoadIndicatorPosition="right"
                        styleProgressIndicatorPosition="right"
                        styleButtonRemoveItemAlign={false}
                        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                        onupdatefiles={setFiles}
                        
                      />
        </div>
      
      </div>
      
      </div>

      <div className='modal-footer justify-content-start d-flex  flex ' >
        
       
      {
            isLoading?
            <button type="submit" className="btn btn-primary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Saving...</button>
            :
            <button type="submit" className="btn btn-primary">Save</button>
          }

         
          <button className="btn iq-bg-danger" onClick={toggleEditModal}>Cancel</button>        
       
      </div>
    </form>
  </Modal>
)
}

export default EditEventCategoryModal