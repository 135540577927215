import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import image2 from "../../assets/images/page-img/34.png";
import image3 from "../../assets/images/page-img/35.png";
import { logOut } from '../../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useGetDashboardDetailsMutation } from '../../features/property/propertySlice';
import Card3 from '../../components/report/card3';
import RegionCarousel from '../../components/report/regionCarousel';
import PropertyList from '../../components/dashboard/propertyList';

const Dashboard = () => {
  const [details, setDetails] = useState([]);

  const [dashboardDetails, { isLoading: isLoadingDashboardDetails }] = useGetDashboardDetailsMutation();

  const handlegetDetails = async () => {
    try {
      const response = await dashboardDetails().unwrap();
      console.log(response);
      setDetails(response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handlegetDetails();
  }, []);

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logOut());
  };

  return (
    <>
      <NavbarAndSideBar />

      <div className="content-page" id="content-page">
        <div className="container-fluid" style={{ marginBottom: "100px" }}>
          <Row className="px-lg-5 px-md-3 px-sm-2">
           
          </Row>

          <Row className="px-lg-5 px-md-3 px-sm-2">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Card3 title="Users Total" total={details?.user_count} />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Card3 title="Hosts Total" total={details?.host_count} />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Card3 title="Properties Total" total={details?.total_property} />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Card3 title="Pending Total" total={details?.pending_property_counts} />
            </div>
          </Row>

          <Row className="px-lg-5 px-md-3 px-sm-2">
            <div className="w-100 col-12">
              <RegionCarousel />
            </div>
          </Row>

          <PropertyList />

          <div className="row row-eq-height"></div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
