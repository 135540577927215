import React,{useState} from 'react'
import DataTable from 'react-data-table-component'
import Skeleton from 'react-loading-skeleton'
import { format } from 'date-fns'

function TableTabComponent({userBooking,isLoadingUserBooking}) {

   const [wishlistactive,setWishlistActive] = useState(true)
   const [bookingsactive,setBookingsActive] = useState(false)
   const [reviewsactive,setReviewsActive] = useState(false)
   const[transactionsactive,setTransactionsActive] = useState(false)
   const [payoutsactive,setPayoutsActive] = useState(false)
   const [filterTextBooking, setFilterTextBooking] = useState('');
   console.log(userBooking,"USER")

   const handleToggle = (section) => {
       setWishlistActive(section === 'wishlist');
       setBookingsActive(section === 'bookings');
       setReviewsActive(section === 'reviews');
       setTransactionsActive(section === 'transactions');
       setPayoutsActive(section === 'payouts');
     };




    const  columnsBooking=[

      {
         id: 'Property',
         name: 'Property',
         selector: row => <div className='text-wrap mb-0 my-1'><div className='h6 text-wrap'>{row.property.title}</div>
         <div className='text-muted fs-xs'>
         {row.property.region}
         </div>
          </div>,
         sortable: true,
         reorder: true,
         width:"30%"
     },

     {
      name: 'check in-check out',
      selector: row => <div className='text-wrap'><span>{format(new Date(row.check_in), 'dd/MM/yyyy HH:mm')}</span>

      <br/>

      <span>{format(new Date(row.check_out), 'dd/MM/yyyy HH:mm')}</span>
       </div>,
      sortable: true,
      reorder: true,
      width:"20%"
  },

     {
      name: 'Total Amount',
      selector: row => <div className='text-wrap'> ₵ {row.total_amount}</div>,
      sortable: true,
      reorder: true,
      width:"20%"
  },

  {
   name: 'Status',
   selector: row => <div className='text-wrap'> <button type="button" class={`btn mb-1 ${row.status==="declined"?"iq-bg-danger":"iq-bg-primary"}`}>
   {row.status}
   </button></div>,
   sortable: true,
   reorder: true,
   width:"20%"
},


      ]






      const filteredItemsbooking = userBooking.filter(
         item => item.property.title && item.property.title.toLowerCase().includes(filterTextBooking.toLowerCase())
     );



  return (
    <div class="iq-card">
                       
    <div class="iq-card-body">
       <ul class="nav nav-tabs justify-content-center" id="myTab-2" role="tablist">
          <li class="nav-item">
             <a class={ `nav-link ${wishlistactive?"active":""}`}  onClick={() => handleToggle('wishlist')} id="home-tab-justify" data-toggle="tab"  style={{cursor:"pointer"}} role="tab" aria-controls="home" aria-selected="false">Wishlists</a>
          </li>
          <li class="nav-item">
             <a class= { `nav-link ${bookingsactive?"active":""}`}  onClick={() => handleToggle('bookings')} id="profile-tab-justify" data-toggle="tab"  role="tab" style={{cursor:"pointer"}}  aria-controls="profile" aria-selected="true">Booking</a>
          </li>
          <li class="nav-item">
             <a class={ `nav-link ${transactionsactive?"active":""}`}  onClick={() => handleToggle('transactions')}     id="contact-tab-justify" data-toggle="tab"  style={{cursor:"pointer"}}  role="tab" aria-controls="contact" aria-selected="false">Transactions</a>
          </li>
          <li class="nav-item">
             <a class={ `nav-link ${reviewsactive?"active":""}`} onClick={() => handleToggle('reviews')} id="contact-tab-justify" data-toggle="tab"  role="tab"  style={{cursor:"pointer"}} aria-controls="reviews" aria-selected="false">Reviews</a>
          </li>
          <li class="nav-item">
             <a class={ `nav-link ${payoutsactive?"active":""}`} onClick={() => handleToggle('payouts')}  id="contact-tab-justify" data-toggle="tab"  role="tab" style={{cursor:"pointer"}} aria-controls="reviews" aria-selected="false">Payouts</a>
          </li>
       </ul>
       <div class="tab-content" id="myTabContent-3">
          <div class={`tab-pane fade ${wishlistactive?"active show":""}`} id="home-justify" role="tabpanel" aria-labelledby="home-tab-justify">
             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          </div>
          <div class={`tab-pane fade ${bookingsactive?"active show":""} `}  id="profile-justify" role="tabpanel" aria-labelledby="profile-tab-justify">


          <div className="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden">
                                <div className="iq-card-header d-flex justify-content-end">
                                    
                                    <div className="search-box d-flex justify-content-end">
                                        <input type="text" className="text search-input" placeholder="Search By Title..."
                                            value={filterTextBooking}
                                            onChange={e => setFilterTextBooking(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <DataTable
                                        columns={columnsBooking}
                                        data={filteredItemsbooking}
                                        progressPending={isLoadingUserBooking}
                                        pagination
                                       
                                        selectableRows
                                        progressComponent={
                                                <div style={{ width: "100%", padding: "10px" }}>
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} />
                                                    <Skeleton style={{ width: "100%", height: "30px" }} className='my-3' />
                                                </div>
                                            }
                                        selectableRowsHighlight
                                        
                                        highlightOnHover
                                        
                                    />
                                </div>
                            </div>
          </div>
          <div class={`tab-pane fade ${transactionsactive?"active show":""} `}  id="contact-justify" role="tabpanel" aria-labelledby="contact-tab-justify">
             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          </div>
          <div class={`tab-pane fade ${reviewsactive?"active show":""} `}  id="contact-justify" role="tabpanel" aria-labelledby="contact-tab-justify">
             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          </div>
          <div class={`tab-pane fade ${payoutsactive?"active show":""} `}  id="contact-justify" role="tabpanel" aria-labelledby="contact-tab-justify">
             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          </div>
       </div>
    </div>
 </div>
  )
}

export default TableTabComponent