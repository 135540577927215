import React, { useEffect, useState, useRef } from 'react';
import { FilePond } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useGetUserDetailMutation,useEditUserMutation } from '../../features/property/propertySlice';
import { useParams } from 'react-router-dom';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import { Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

function EditPartnerPage() {
  const [files, setFiles] = useState([]);
  const [getuserDetail, { isLoading }] = useGetUserDetailMutation();
  const [editUser, { isLoading:isEditing }] = useEditUserMutation();
  const [userDetail, setUserDetail] = useState({});
  const { id } = useParams();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [heading,setHeading]=useState()
  const handleGetUserDetail = async () => {
    try {
      const response = await getuserDetail(id).unwrap();
      console.log(response);
      setUserDetail(response);
      setHeading(response.first_name +" "+response.last_name)

      if (response.profile) {
        fetch(`https://dev-api.shortstaybuddy.com${response.profile}`)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], 'first_image.jpg', { type: blob.type });

            setFiles([
              {
                source: file,
                options: {
                  type: 'local'
                }
              }
            ]);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetail((prevDetail) => ({
      ...prevDetail,
      [name]: value,
    }));
  };


  const handleSubmit = async(e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      const formData = new FormData();
      
      // Append fields that are in the form but not in userDetail
      formData.append('role', "partner");
      

      formData.append('first_name', userDetail.first_name || '');
      formData.append('last_name', userDetail.national_id_number || '');
      formData.append('phone_number', userDetail.phone_number || '');
      formData.append('email', userDetail.email || '');
      formData.append('promo_code', userDetail.promo_code || '');
      formData.append('promo_code', userDetail.start_date || '');
      formData.append('promo_code', userDetail.end_date || '');





      
      // Handle the profile file (if a new file is uploaded)
      if (files.length > 0) {
        formData.append('profile', files[0].file);
      }
      
      // You can now use formData to make an API request
      console.log('Form Data prepared:', formData);
      // Example: You can send this formData via an API request here
      try {

        const response=await editUser({id:userDetail.id,formData:formData});
        console.log(response)
        

      }
      catch (error) {
        console.error('Error editing user:', error);
      }
  
    } else {
      formRef.current.classList.add("was-validated");
    }
  };
  

  useEffect(() => {
    handleGetUserDetail();
  }, []);

  return (
    <>
      <NavbarAndSideBar />
      <div className="content-page" id="content-page">
        <div className="container-fluid">
          <Row style={{ marginBottom: "100px" }}>
            <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
              <div className='d-flex justify-content-between'>
                <div>
                  <h3 className="card-title">
                    {heading&&heading}
                  </h3>
                </div>
              </div>
            </Col>
            <Col sm="12 mb-5">
              <form onSubmit={handleSubmit} ref={formRef} className='w-100 needs-validation px-2' noValidate>
                <div className='w-100'>
               
                  <div className="row border p-2 rounded-sm mt-3">
                    <div className="col-12">
                      <label>Image <span className='text-danger'>*</span></label>
                      <FilePond
                        files={files}
                        allowMultiple={false}
                        name="profile"
                        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                        onupdatefiles={setFiles}
                      />
                    </div>

                    <div className='col-md-6'>
                      <label>First Name<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='first_name'
                        className="form-control"
                        value={userDetail?.first_name || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Last Name<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='last_name'
                        className="form-control"
                        value={userDetail?.last_name || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Email<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='email'
                        className="form-control"
                        value={userDetail?.email || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Phone<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='phone_number'
                        className="form-control"
                        value={userDetail?.phone_number || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Promo Code<span className='text-danger'>*</span></label>
                      <input
                        type="text"
                        name='promo_code'
                        className="form-control"
                        value={userDetail?.promo_code|| ''}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='col-md-6'>
                      <label>Start Date<span className='text-danger'>*</span></label>
                      <input
                        type="date"
                        name='start_date'
                        className="form-control"
                        value={userDetail?.start_date|| ''}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='col-md-6'>
                      <label>End Date<span className='text-danger'>*</span></label>
                      <input
                        type="date"
                        name='start_date'
                        className="form-control"
                        value={userDetail?.end_date|| ''}
                        onChange={handleChange}
                      />
                    </div>
                   
                  </div>

               

                 

                 
                  <div className='my-3 px-2'>
                  {
                    isEditing?

                    <button type="submit" className="btn btn-primary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Saving...</button>
                    :
                    <button type="submit" className="btn mb-3 btn-primary">Save Changes</button>


                  }
                  </div>
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default EditPartnerPage;
