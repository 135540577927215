import React from 'react';
import ApexCharts from 'react-apexcharts';

function SignupsChart({ data }) {
    const options = {
        chart: {
            height: 350,
            type: 'line',
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [5, 7, 5],
            curve: 'smooth',
            dashArray: [0, 0, 5],
        },
        xaxis: {
            categories: Object.keys(data.user_monthly_signups),
        },
        yaxis: {
            title: {
                text: 'Sign-ups',
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        colors: ['#FF5733', '#33FF57', '#3357FF'],
    };

    const series = [
        {
            name: 'User Sign-ups',
            data: Object.values(data.user_monthly_signups),
        },
        {
            name: 'Host Sign-ups',
            data: Object.values(data.host_monthly_signups),
            stroke: {
                dashArray: 5, // Dashed line for Host Sign-ups
            },
        },
        {
            name: 'Partner Sign-ups',
            data: Object.values(data.partner_monthly_signups),
        },
    ];

    return (
        <div>
            <ApexCharts
                options={options}
                series={series}
                type="line"
                height={400}
            />
        </div>
    );
}

export default SignupsChart;
