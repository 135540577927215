import React, { useRef, useState } from 'react';
import {  Modal } from 'reactstrap';
import { useAddFAQMutation } from '../../../features/property/propertySlice';

function AddNewFAQModal({ addfaqModal, toggleModal }) {
    const [addFAQ,{isLoading}]=useAddFAQMutation();
    const [showSuccess,setShowSuccess]=useState(false)
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    faq_type: "",
    question: "",
    answer: "",
    status: ""
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      try{

        console.log(formData)
        const response= await addFAQ(formData).unwrap();
        console.log(response)
        setShowSuccess(true)
        setFormData({
            faq_type: "",
            question: "",
            answer: "",
            status: ""
          })
      }catch(e){
        console.log(e)
        setShowSuccess(false)
      }

      
    } else {
      formRef.current.classList.add("was-validated");
    }
  };

  return (
    <Modal size='lg' className='modal-content' isOpen={addfaqModal} toggle={toggleModal}>
      <div className="modal-header">
        <h3 className="card-title">Create property category</h3>
        <button type="button" className="close" onClick={toggleModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <form onSubmit={handleSubmit} className='p-2 needs-validation' noValidate ref={formRef}>
        <div className='modal-body'>

{
    showSuccess?   

    <div class="alert alert-success" role="alert">
                             
                             <div class="iq-alert-text">Category created successfully</div>
                             <button onClick={()=>{setShowSuccess(false)}} type="button" class="close" data-dismiss="alert" aria-label="Close">
                             <i class="ri-close-line" style={{color:"black"}}></i>
                             </button>
                          </div>

    :""
}

        



<div className="row border p-2 rounded-sm">
          <div className="col">
            <label>Type <span className='text-danger'>*</span></label>
            <select className="form-control" required name="faq_type" onChange={handleChange} value={formData.faq_type} >
                <option selected disabled={true} hidden={true} value="">Select an option</option>
                    
                    <option value="general">
    General
</option>
                    <option value="booking">
    Booking
</option>
                    <option value="refund">
    Refund
</option>
                    <option value="payment">
    Payment
</option>
                    <option value="cancellation">
    Cancellation
</option>
                    <option value="other">
    Other
</option>
             
              </select>          </div>
          <div className="col">
            <label>Question <span className='text-danger'>*</span></label>
            <input type="text" name='question' onChange={handleChange} className="form-control" required placeholder="" value={formData.question}  />
          </div>
          <div className='col-12 mt-2'>
            <div className="form-group">
              <label>Answer <span className='text-danger'>*</span></label>
              <textarea className="form-control" onChange={handleChange}  required  name="answer" rows="5" value={formData.answer} ></textarea>

            </div>
          </div>
        </div>
        <div className="row border p-2 rounded-sm mt-5">
          <div className="col-12">
            <label>Status <span className='text-danger'>*</span></label>
            <select className="form-control" onChange={handleChange} required name="status" value={formData.status} >
                <option selected  hidden={true} value="">Select an option</option>
            
              
                <option value="active">
                            Active
                        </option>
                                            <option value="inactive">
                            Inactive
                        </option>
                                  
              </select>
          </div>
        </div>
        </div>

        <div className='modal-footer'>
          
          {
            isLoading?
            <button type="submit" className="btn btn-primary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Creating...</button>
            :
            <button type="submit" className="btn btn-primary">Create</button>
          }

         
          <button className="btn iq-bg-danger" onClick={toggleModal}>Cancel</button>
        </div>
      </form>
    </Modal>
  );
}

export default AddNewFAQModal;







