import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { useEditPostTagsMutation } from '../../features/property/propertySlice';


function EditPostTagModal({ editCategoryModal, toggleEditModal,tagsDetail }) {
    const [editTags,{isLoading}]=useEditPostTagsMutation();
    const [showSuccess,setShowSuccess]=useState(false)
    console.log("DETAIL",tagsDetail)
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    "name":tagsDetail&& tagsDetail.name,
          
  });

  useEffect(()=>{

    setFormData({
        "name":tagsDetail&& tagsDetail.name,
            
      })
  },[tagsDetail])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      try{

        console.log(formData)
        const response= await editTags({id:tagsDetail.id,body:formData}).unwrap();
        console.log(response)
        setShowSuccess(true)
        setFormData({
          "name":"",
          
        })
        
      }catch(e){
        console.log(e)
        setShowSuccess(false)
      }

      
    } else {
      formRef.current.classList.add("was-validated");
    }
  };






  return (
    <Modal size='lg' className='modal-content' isOpen={editCategoryModal} toggle={toggleEditModal}>
      <div className="modal-header">
        <h3 className="card-title">Edit Post Tag</h3>
        <button type="button" className="close" onClick={toggleEditModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <form onSubmit={handleSubmit} className='p-2 needs-validation' noValidate ref={formRef}>
        <div className='modal-body'>

{
    showSuccess?   

    <div class="alert alert-success" role="alert">
                             
                             <div class="iq-alert-text">Post Category created successfully</div>
                             <button onClick={()=>{setShowSuccess(false)}} type="button" class="close" data-dismiss="alert" aria-label="Close">
                             <i class="ri-close-line" style={{color:"black"}}></i>
                             </button>
                          </div>

    :""
}

        



          <div className="row  p-2 rounded-sm">
          
            <div className="col-12">
              <label>Name <span className='text-danger'>*</span></label>
              <input type="text" name='name' className="form-control" required placeholder="" value={formData.name} onChange={handleChange} />
            </div>
           
          </div>

         
        </div>

        <div className='modal-footer'>
          
          {
            isLoading?
            <button type="submit" className="btn btn-primary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Editing...</button>
            :
            <button type="submit" className="btn btn-primary">Edit</button>
          }

         
          <button className="btn iq-bg-danger" onClick={toggleEditModal}>Cancel</button>
        </div>
      </form>
    </Modal>
  );
}

export default EditPostTagModal;







