import React,{useEffect, useState} from 'react'
import { Modal } from 'reactstrap'
import { useViewCategoryDetailMutation } from '../../../features/property/propertySlice';
function ViewContactsModal({viewContactModal,toggleViewModal,contactDetail}) {





    const [formData, setFormData] = useState({
        full_name: " ",
        email: " ",
        phone_number: " ",
        subject: " ",
        message: " "
      });
  

      useEffect(()=>{

        setFormData(contactDetail)

        console.log(contactDetail)
      },[contactDetail])




  return (
    <Modal size='lg' className='modal-content' isOpen={viewContactModal} toggle={toggleViewModal}>
    <div className="modal-header">
      <h5 className="card-title">{contactDetail&&contactDetail.subject}</h5>
      <button type="button" className="close" onClick={toggleViewModal} aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div className='p-2 ' >
      <div className='modal-body'>


      



        <div className="row  p-2 rounded-sm">
          <div className="col">
            <label>Full Name <span className='text-danger'>*</span></label>
            <input type="text" name='name' className="form-control" required placeholder="" value={ formData&& formData.full_name} disabled/>
          </div>
          <div className="col">
            <label>Email <span className='text-danger'>*</span></label>
            <input type="text" name='slug' className="form-control" required placeholder="" value={formData&& formData.email} disabled />
          </div>

          
          
        </div>
        <div className='row p-2'>
        <div className="col">
            <label>Phone<span className='text-danger'>*</span></label>
            <input type="text" name='name' className="form-control" required placeholder="" value={ formData&& formData.phone_number} disabled/>
          </div>
          <div className="col">
            <label>Subject<span className='text-danger'>*</span></label>
            <input type="text" name='slug' className="form-control" required placeholder="" value={formData&& formData.subject} disabled />
          </div>

        </div>

        <div className="row border p-2 rounded-sm mt-5">
          <div className="col-12">
            <label>Message<span className='text-danger'>*</span></label>
            <textarea className="form-control" disabled name="description" rows="5" value={formData&&formData.message} ></textarea>
          </div>
        </div>
      </div>

      <div className='modal-footer'>
      

       
        <button className="btn iq-bg-primary" onClick={toggleViewModal}>Close</button>
      </div>
    </div>
  </Modal>
  )
}

export default ViewContactsModal