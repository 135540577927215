import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'reactstrap';

function ViewPropertyPrevilegeModal({ viewPropertyPrevilegeModal, toggleViewModal,previlegeDetail }) {
    const [showSuccess,setShowSuccess]=useState(false)
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    "name": " ",
  
  });

  useEffect(()=>{
    setFormData(previlegeDetail)
  },[previlegeDetail])


  return (
    <Modal  className='modal-content' isOpen={viewPropertyPrevilegeModal} toggle={toggleViewModal}>
      <div className="modal-header">
        <h3 className="card-title">{previlegeDetail&&previlegeDetail.name}</h3>
        <button type="button" className="close" onClick={toggleViewModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div  className='p-2 needs-validation' noValidate ref={formRef}>
        <div className='modal-body'>



        



          <div className="row p-2 rounded-sm">
          
            <div className='col-12 mt-2'>
              <div className="form-group">
                <label>Name <span className='text-danger'>*</span></label>
                <input type="text"  disabled name='name' className="form-control" required placeholder="" value={formData&&formData.name} />
              </div>
            </div>
          </div>

       
        </div>

        <div className='modal-footer'>
       
         
          <button className="btn iq-bg-danger" onClick={toggleViewModal}>Close</button>
        </div>
      </div>
    </Modal>
  );
}

export default ViewPropertyPrevilegeModal;







