import React,{useEffect, useState} from 'react'
import { Modal } from 'reactstrap'
import { useViewCategoryDetailMutation } from '../../../features/property/propertySlice';
function ViewFAQSModal({viewfaqsModal,toggleViewModal,faqDetail}) {





    const [formData, setFormData] = useState({
        Type: " ",
        Question: " ",
        Answer: " ",
        Status: " "
      });
  

      useEffect(()=>{

        setFormData({
            Type: faqDetail&& faqDetail.faq_type,
            Question:faqDetail&& faqDetail.question,
            Answer: faqDetail&&faqDetail.answer,
            Status:faqDetail&&faqDetail.status

        })

        console.log(faqDetail)
      },[faqDetail])




  return (
    <Modal size='lg' className='modal-content' isOpen={viewfaqsModal} toggle={toggleViewModal}>
    <div className="modal-header">
      <h3 className="card-title">{faqDetail&&faqDetail.question}</h3>
      <button type="button" className="close" onClick={toggleViewModal} aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div className='p-2 ' >
      <div className='modal-body'>


      



        <div className="row border p-2 rounded-sm">
          <div className="col">
            <label>Type <span className='text-danger'>*</span></label>
            <select className="form-control" required name="icon" value={formData&& formData.Type} disabled>
                <option selected disabled={true} hidden={true} value="">Select an option</option>
                    
                    <option value="general">
    General
</option>
                    <option value="booking">
    Booking
</option>
                    <option value="refund">
    Refund
</option>
                    <option value="payment">
    Payment
</option>
                    <option value="cancellation">
    Cancellation
</option>
                    <option value="other">
    Other
</option>
             
              </select>          </div>
          <div className="col">
            <label>Question <span className='text-danger'>*</span></label>
            <input type="text" name='slug' className="form-control" required placeholder="" value={formData&& formData.Question} disabled />
          </div>
          <div className='col-12 mt-2'>
            <div className="form-group">
              <label>Answer <span className='text-danger'>*</span></label>
              <textarea className="form-control" disabled name="description" rows="5" value={formData&&formData.Answer} ></textarea>

            </div>
          </div>
        </div>

        <div className="row border p-2 rounded-sm mt-5">
          <div className="col-12">
            <label>Status <span className='text-danger'>*</span></label>
            <select className="form-control" required name="icon" value={formData&& formData.Status} disabled>
                <option selected disabled={true} hidden={true} value="">Select an option</option>
            
              
                <option value="active">
                            Active
                        </option>
                                            <option value="inactive">
                            Inactive
                        </option>
                                  
              </select>
          </div>
        </div>
      </div>

      <div className='modal-footer'>
      

       
        <button className="btn iq-bg-primary" onClick={toggleViewModal}>Close</button>
      </div>
    </div>
  </Modal>
  )
}

export default ViewFAQSModal





