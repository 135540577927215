import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { useDeleteUserMutation} from '../../features/property/propertySlice';
function DeleteUserModal({deleteUserModal,toggleDeleteModal,userDetail}) {

    const [deleteuser,{isLoading}]=useDeleteUserMutation();

    const handleDeleteUser =async() =>{

        try{

            const response =await deleteuser(userDetail.id).unwrap();
            console.log(response)
         
            toggleDeleteModal()
         
        }catch(e){
            console.log(e)
         
        }
    }
  return (
    <Modal  className='modal-content' isOpen={deleteUserModal} toggle={toggleDeleteModal}>
<div className="modal-header">
      <button type="button" className="close" onClick={toggleDeleteModal} aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div className='p-2 ' >
      <div className='modal-body'>

   

         <h4>Are you sure you want to delete {userDetail &&userDetail.first_name +" "+userDetail.last_name}</h4>
         <p className='text-danger'>This action cannot be undone.</p>
       

     
      
      

</div>

<div className='modal-footer'>
         <button className="btn iq-bg-primary" onClick={toggleDeleteModal}>Cancel</button>

          
          {
            isLoading?
            <button type="submit" className="btn btn-danger">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Deleting...</button>
            :
            <button onClick={handleDeleteUser} className="btn btn-danger">Confirm</button>
          }

         
        </div>
</div>
</Modal>
  )
}

export default DeleteUserModal