import React,{useState,useEffect} from 'react'
import { Row } from 'reactstrap'
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar'
import { useGetReportDetailsMutation } from '../../features/property/propertySlice';
import RegionCarousel2 from '../../components/report/regionCarousel2';
import Skeleton from 'react-loading-skeleton';
import CategoriesCarousel from '../../components/report/categoriesCarousel';
import Card3 from '../../components/report/card3';
function PropertyReport() {

   const [getReportDetails, { isLoading }] = useGetReportDetailsMutation();
   const [reportDetails, setReportDetails] = useState();
   const [region_counts, setRegionCounts]=useState([]);
   const [showByCategory,setShowByCategory]=useState(true);
   const [showByRegion,setShowByRegion]=useState(true);
   const [showTotalList,setShowTotalList]=useState(true);



   const handleChange = (event) => {
      const { name, checked } = event.target;
  
      // Debugging: Log the checkbox name and its checked status
      console.log(`Checkbox Name: ${name}, Checked: ${checked}`);
  
      switch (name) {
        case 'showByCategory':
          setShowByCategory(checked);
          break;
        case 'showByRegion':
          setShowByRegion(checked);
          break;
        case 'showTotalList':
          setShowTotalList(checked);
          break;
        default:
          console.error(`Unhandled checkbox name: ${name}`);
          break;
      }
    };

   const today = new Date();
   const twoWeeksAgo = new Date(today);
   twoWeeksAgo.setDate(today.getDate() - 14);
 
   const formatDate = (date) => date.toISOString().split('T')[0];
 
   const [startDate, setStartDate] = useState(formatDate(twoWeeksAgo));
   const [endDate, setEndDate] = useState(formatDate(today));
 
   const handleGetDetails = async (startDate, endDate) => {
     try {
       const response = await getReportDetails({ start_date: startDate, end_date: endDate }).unwrap();
       console.log(response.region_counts);
       setRegionCounts(response.region_counts);
       setReportDetails(response);
     } catch (error) {
       console.error(error);
     }
   };
 
   useEffect(() => {
     handleGetDetails(startDate, endDate); // Fetch report details with the initial dates
   }, []);
 
   const handleStartDateChange = (e) => {
     setStartDate(e.target.value);
   };
 
   const handleEndDateChange = (e) => {
     setEndDate(e.target.value);
   };
 
 
   const handleSearch =async()=>{
     handleGetDetails(startDate, endDate); 
   }
 
  return (
  
    <>
    <NavbarAndSideBar />

<div className="content-page" id="content-page">
           <div className="container-fluid" style={{ marginBottom: "100px" }}>
           <Row className='px-lg-5 px-md-3 px-sm-2' >
   
        <div className='w-full w-100 d-md-flex  justify-content-between'>
        <h5 className='mb-2'>Property </h5>
        <div className='iq-card-header d-md-flex justify-content-between'>
        

<div className='d-flex justify-content-between'>
                           <input 
                                               type="date" 
                    className="form-control" 
                    name='start_date' 
                    id="start-date" 
                    value={startDate} 
                    onChange={handleStartDateChange} 
                           ></input>
                           <span className="badge badge-light text-center mx-2 d-flex justify-content-center align-items-center" >TO</span>
                           <input 
                              type="date" 
                    className="form-control" 
                    name='end_date' 
                    id="end-date" 
                    value={endDate} 
                    onChange={handleEndDateChange} ></input>
                           <button onClick={handleSearch} type="button" className="btn btn-primary  mx-1 "><i className="ri-search-line pr-0"></i></button>
</div>
        </div>

        </div>
           </Row>

          
             
           <Row className='px-lg-5 px-md-3 px-sm-2 mt-3'>
           <div className='row w-100'>
           <div className="col ">
                     <div className="card iq-mb-3">
                        <div className="card-header">
                        <h5 className="card-title mb-0">Total Property</h5>
                        </div>
                        <div className="card-body">
                           
                           <p className="card-text">
                           <div >
                              <input 
                                           type="checkbox" 
                                          name="showTotalList" 
                                          checked={showTotalList} 
                                          onChange={handleChange}
                              />          
                              <label className="mx-2" > Total List Of Property</label>
                           </div>
                           </p>
                           
                        </div>
                     </div>
                  </div>



                  <div className="col ">
                     <div className="card iq-mb-3">
                        <div className="card-header">
                        <h5 className="card-title mb-0">Region</h5>
                        </div>
                        <div className="card-body">
                           
                           <p className="card-text">
                           <div >
                              <input 
                     

                              name="showByRegion" 
                              checked={showByRegion} 
                              onChange={handleChange} 
                              type='checkbox'

                              />          
                              <label className="mx-2" > Total Property By Region</label>
                           </div>
                           </p>
                           
                        </div>
                     </div>
                  </div>




                  <div className="col  ">
                     <div className="card iq-mb-3">
                        <div className="card-header">
                        <h5 className="card-title mb-0">By Category</h5>
                        </div>
                        <div className="card-body">
                           
                           <p className="card-text">
                           <div >
                              <input type="checkbox" 
                                         
                                    name="showByCategory" 
                                    checked={showByCategory} 
                                    onChange={handleChange}
                                    className=''
                              />          
                              <label className="mx-2 "> Property By Catageory</label>
                           </div>
                           </p>
                           
                        </div>
                     </div>
                  </div>
           
           </div>
           </Row>



           <Row className='px-lg-5 px-md-3 px-sm-2 mt-3'>
           <div className='row w-100'>
           <div className="col ">
           {
         isLoading?
         <button type="button" className="btn btn-primary btn-block btn-lg">
         <span className="spinner-border spinner-border-sm me-2 mx-2" role="status" aria-hidden="true"></span>
            Loading...
            </button>:
            <button type="button" className="btn btn-primary btn-block btn-lg">

           <i className="ri-bill-fill mx-2"></i>
           Generate Report </button>

           }
           
           </div>
           
 </div>

           </Row>
           {

showByRegion?
           
           <Row className='px-lg-5 px-md-3 px-sm-2 mt-3'>
           <div className=' w-100 col-12'>
           
{
   isLoading?
   <Skeleton style={{height:'200px'}}/>
   :
   <RegionCarousel2 data={region_counts&&region_counts} />

}

           </div>

            </Row>
            :""
           }


{
   showByCategory?
   <Row className='px-lg-5 px-md-3 px-sm-2 mt-3'>
           <div className=' w-100 col-12'>
           
{
   isLoading?
   <Skeleton style={{height:'200px'}}/>
   :
   reportDetails&&<CategoriesCarousel data={reportDetails&&reportDetails.category_count}/>

}

           </div>

            </Row>
   :""
}

         
         {
            showTotalList?
            <Row className='px-lg-5 px-md-3 px-sm-2 mt-3'>
            <div className="col-lg-3 col-md-4 col-sm-6">
            {
               isLoading?
                <Skeleton height={200} width={200}/>
                :

                <Card3 title="Total Properties Created" total={reportDetails&&reportDetails.total_property
}  />
            }


</div>
</Row>

            :
            ""
         }




 </div>

 </div> 
 



</>
  )
}

export default PropertyReport