import React, { useEffect, useRef, useState } from 'react';
import NavbarAndSideBar from '../../components/basic/navbarAndSideBar';
import { Col, Row } from "reactstrap";
import { FilePond, registerPlugin } from 'react-filepond';
import { useParams, useNavigate } from 'react-router-dom';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {
  useGetAmenitiesMutation,
  useGetPrevilegesMutation,
  useGetPropertyDetailMutation,
  useEditPropertyMutation
} from '../../features/property/propertySlice';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);



function EditPropertyPage() {
  const [amenities, setAmenities] = useState([]);
  const [privileges, setPrivileges] = useState([]);
  const [getAmenities] = useGetAmenitiesMutation();
  const [getPrivileges] = useGetPrevilegesMutation();
  const [files, setFiles] = useState([]);
  const [propertyDetail, setPropertyDetail] = useState();
  const [getPropertyDetail] = useGetPropertyDetailMutation();
  const [editProperty, { isLoading: isEditing }] = useEditPropertyMutation();
  const [categories,setCategories]=useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [pricing,setPricing]=useState();

  const [otherFiles, setOtherFiles] = useState([]);
  const { id } = useParams();
  const formRef = useRef();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    files: "",
    other_files: [],
    user: "",
    category: [],
    description: "",
    address: "",
    status: "",
    latitude: "",
    longitude: "",
    map_link: "",
    bedroom: "",
    bathroom: "",
    parking_spot: "",
    minimum_stay_period: "",
    pricing: [],
    amenity: [],
    privilege: [],
    region: "",
    service_fee:""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name==="category") {
      const selectedValues = Array.from(e.target.selectedOptions, option => option.value);
      setSelectedOptions(selectedValues);

    }
    else if (name.startsWith("pricing")) {
      const field = name.split(".")[1]; 
   
      setFormData((prevState) => ({
          ...prevState,
          pricing: [{
              ...prevState.pricing[0],
              [field]: value,
          }],
      }));
  }
    
    else{

      setFormData({
        ...formData,
        [name]: value
      });
    };

    }

    const handleServiceChange = (e, index) => {
      const { name, value } = e.target;
  
      setFormData((prevState) => {
          const updatedServiceFees = [...prevState.service_fee];
          updatedServiceFees[index] = {
              ...updatedServiceFees[index],
              [name]: name === 'charge_per_day' || name === 'charge_per_week' || name === 'charge_per_month' ? parseFloat(value) : value
          };
  
          return {
              ...prevState,
              service_fee: updatedServiceFees
          };
      });
  };
  

  const handleUpdateFiles = (fileItems) => {
    setOtherFiles(fileItems.map(fileItem => fileItem.file));
  };

 
  const handleGetProperty = async () => {
    try {
      const property = await getPropertyDetail(id).unwrap();
      setPropertyDetail(property);
      setCategories(property.category);
      console.log(property)
      setSelectedOptions(property.category.map(category => category.id));

      setFormData({
        title: property.title,
        files: property.files,
        user: property.user,
        category: property.category.map(category => category.id),
        description: property.description,
        address: property.address,
        status: property.status,
        latitude: property.latitude,
        longitude: property.longitude,
        map_link: property.map_link,
        bedroom: property.bedroom,
        bathroom: property.bathroom,
        parking_spot: property.parking_spot,
        pricing: property.pricing,
        amenity: property.amenity.map(amenity => amenity.id),
        privilege: property.privilege.map(privilege => privilege.id),
        region: property.region,
        other_files: property.other_files,
        minimum_period: property.minimum_period,
        city: property.city,
        service_fee: property.service_fee,
        listing_property_as:property.listing_property_as
      });

      const firstFileResponse = await fetch(`https://dev-api.shortstaybuddy.com${property.files}`);
      const firstBlob = await firstFileResponse.blob();
      const firstFile = new File([firstBlob], 'first_image.jpg', { type: firstBlob.type });
      setFiles([{
        source: firstFile,
        options: {
          type: 'local'
        }
      }]);

      const otherFilesPromises = property.other_files.map(async (file, index) => {
        const response = await fetch(`https://dev-api.shortstaybuddy.com${file.files}`);
        const blob = await response.blob();
        return new File([blob], `other_image${index}.jpg`, { type: blob.type });
      });

      const otherFiles = await Promise.all(otherFilesPromises);
      const filePondFiles = otherFiles.map(file => ({
        source: file,
        options: {
          type: 'local'
        }
      }));
      setOtherFiles(filePondFiles);

    } catch (e) {
      console.log("Error getting property", e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      const formDataToSend = new FormData();
      if (files.length > 0) {
        formDataToSend.append('file',files[0].file);

    }
    if (otherFiles.length > 0) {
      otherFiles.forEach((imageFile, index) => {
        formDataToSend.append(`other_images`, imageFile);  
      console.log(imageFile.file)
      });
    }
    
    
   

    formDataToSend.append('title', formData.title);
    formData.category.forEach(id => formDataToSend.append('category_ids', id));
    formDataToSend.append('listing_property_as', formData.listing_property_as);
    formDataToSend.append('latitude', formData.latitude);
    formDataToSend.append('longitude', formData.longitude);
    formDataToSend.append('map_link', formData.map_link);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('region', formData.region);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('bedroom', formData.bedroom);
    formDataToSend.append('bathroom', formData.bathroom);
    formDataToSend.append('parking_spot', formData.parking_spot);
    formData.amenity.forEach((id) => formDataToSend.append(`amenity_ids`, id));
    formData.privilege.forEach((id) => formDataToSend.append(`privilege_ids`, id));
    formDataToSend.append('description', formData.description);
    formDataToSend.append('is_party_inclusive', formData.pricing[0].is_party_inclusive);
    formDataToSend.append('minimum_period', formData.minimum_period);
  	formDataToSend.append('status', formData.status);
    formDataToSend.append(`pricings`, JSON.stringify(formData.pricing));
    formDataToSend.append(`service_fees`, JSON.stringify(formData.service_fee));
    formDataToSend.append(`property_permission_ids`, []);
     
  
		for (let [key, value] of formDataToSend.entries()) {
			console.log(`${key}: ${value}`);
		}
      try {
         await editProperty({ id, formData:formDataToSend }).unwrap();
         console.log('Property updated successfully!');
        
      } catch (error) {
        console.error('Error updating property:', error);
      }
    } else {
      formRef.current.classList.add('was-validated');
    }
  };
  

  const handleGetAmenities = async () => {
    try {
      const response = await getAmenities().unwrap();
      console.log(response);
      setAmenities(response);
    } catch (e) {
      console.log(e);
    }
  };

  const handleGetPrivileges = async () => {
    try {
      const response = await getPrivileges().unwrap();
      console.log(response);
      setPrivileges(response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleGetAmenities();
    handleGetPrivileges();
    handleGetProperty();
  }, []);

  return (
    <>
      <NavbarAndSideBar />
      <div className="content-page" id="content-page">
        <div className="container-fluid">
          <Row style={{ marginBottom: "100px" }}>
            <Col sm="12" className='px-lg-5 px-md-3 px-sm-2'>
              <div className='d-flex justify-content-between mb-4'>
                <div className=''>
                  <div className="">
                    <h3 className="card-title">{propertyDetail && propertyDetail.title}</h3>
                  </div>

                  <form ref={formRef} onSubmit={handleSubmit} className='w-100 needs-validation p-2' noValidate >
                    <div className=''>
                      <div className="row border p-2 rounded-sm">
                        <div className="col-md-6 mb-2">
                          <label>User <span className='text-danger'>*</span></label>
                          <input
                            type="text"
                            name='user'
                            className="form-control"
                            required
                            placeholder=""
                            value={formData.user.first_name + " " + formData.user.last_name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6 mb-2">
                          <label>Title <span className='text-danger'>*</span></label>
                          <input
                            type="text"
                            name='title'
                            className="form-control"
                            required
                            placeholder=""
                            value={formData.title}
                            onChange={handleChange}
                          />
                        </div>

                        <div className='col-md-6'>
            <label>You are listing as?<span className='text-danger'>*</span></label>
            <select
                name='listing_property_as'
                className="form-control"
                required
                value={formData.listing_property_as}
                onChange={handleChange}
            >
                <option value="">Select Category</option>
                <option value="A private property">A private property</option>
                <option value="A registered business">A registered business</option>
            </select>
        </div>
                        <div className="col-md-6">
                          <label>Categories <span className='text-danger'>*</span></label>
                          <select
                            multiple
                            type="text"
                            name='category'
                            className="form-control"
                            required
                            placeholder=""
                            value={selectedOptions}
                            onChange={handleChange}
                          >
                            {categories && categories.map((category, index) => (
                              <option key={index} value={category.id}>{category.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="row border p-2 rounded-sm mt-5">
                        <div className="col-12">
                          <label>Image <span className='text-danger'>*</span></label>
                          <FilePond
                            files={files}
                            allowMultiple={false}
                            name="avatar_url"
                            stylePanelLayout="compact"
                            styleButtonRemoveItemPosition="left"
                            styleButtonProcessItemPosition="right"
                            styleLoadIndicatorPosition="right"
                            styleProgressIndicatorPosition="right"
                            styleButtonRemoveItemAlign={false}
                            acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                            onupdatefiles={setFiles}
                            required
                          />
                        </div>
                      </div>

                      <div className="row border p-2 rounded-sm mt-5">
                        <div className="col-12">
                          <label>Address<span className='text-danger'>*</span></label>
                          <input
                            type="text"
                            name='address'
                            className="form-control"
                            required
                            placeholder=""
                            value={formData.address}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-12">
                          <label>City<span className='text-danger'>*</span></label>
                          <input
                            type="text"
                            name='city'
                            className="form-control"
                            required
                            placeholder=""
                            value={formData.city}
                            onChange={handleChange}
                          />
                          </div>
                        <div className="col-12">
                          <label>Region<span className='text-danger'>*</span></label>
                          <input
                            type="text"
                            name='region'
                            className="form-control"
                            required
                            placeholder=""
                            value={formData.region}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-12">
                          <label>Map Link<span className='text-danger'>*</span></label>
                          <input
                            type="url"
                            name='map_link'
                            className="form-control"
                            required
                            placeholder=""
                            value={formData.map_link}
                            onChange={handleChange}
                          />
                          </div>
                        <div className="col-md-6">
                          <label>Latitude <span className='text-danger'>*</span></label>
                          <input
                            type="text"
                            name='latitude'
                            className="form-control"
                            required
                            placeholder=""
                            value={formData.latitude}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Longitude <span className='text-danger'>*</span></label>
                          <input
                            type="text"
                            name='longitude'
                            className="form-control"
                            required
                            placeholder=""
                            value={formData.longitude}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Bedroom <span className='text-danger'>*</span></label>
                          <input
                            type="text"
                            name='bedroom'
                            className="form-control"
                            required
                            placeholder=""
                            value={formData.bedroom}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Bathroom <span className='text-danger'>*</span></label>
                          <input
                            type="text"
                            name='bathroom'
                            className="form-control"
                            required
                            placeholder=""
                            value={formData.bathroom}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Parking Spot<span className='text-danger'>*</span></label>
                          <input
                            type="text"
                            name='parking_spot'
                            className="form-control"
                            required
                            placeholder=""
                            value={formData.parking_spot}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Mainimum Stay Period<span className='text-danger'>*</span></label>
                          <input
                                    type="number"
                                    name='pricing.max_days_allowed'
                                    className="form-control"
                                    required
                                    placeholder="Max Days Allowed"
                                    value={formData.pricing[0] && formData.pricing[0].max_days_allowed}
                                    onChange={handleChange}
                                />
                        </div>
                      </div>



                      {formData.service_fee&&formData.service_fee.map((service, index) => (
    <div key={service.id} className="row border p-2 rounded-sm mt-5">
        <div className='col-md-4'>
            <label>Service<span className='text-danger'>*</span></label>
            <input
                type="text"
                name='service'
                className="form-control"
                required
                placeholder="Service"
                value={service.service}
                onChange={(e) => handleServiceChange(e, index)}
            />
        </div>
        <div className='col-md-4'>
            <label>Category<span className='text-danger'>*</span></label>
            <select
                name='category'
                className="form-control"
                required
                value={service.category}
                onChange={(e) => handleServiceChange(e, index)}
            >
                <option value="">Select Category</option>
                <option value="non-refundable">Non-Refundable</option>
                <option value="refundable">Refundable</option>
            </select>
        </div>
        <div className='col-md-4'>
            <label>Charge Per Day<span className='text-danger'>*</span></label>
            <input
                type="number"
                step="0.01"
                name='charge_per_day'
                className="form-control"
                required
                placeholder="Charge Per Day"
                value={service.charge_per_day}
                onChange={(e) => handleServiceChange(e, index)}
            />
        </div>
        <div className='col-md-4'>
            <label>Charge Per Week<span className='text-danger'>*</span></label>
            <input
                type="number"
                step="0.01"
                name='charge_per_week'
                className="form-control"
                required
                placeholder="Charge Per Week"
                value={service.charge_per_week}
                onChange={(e) => handleServiceChange(e, index)}
            />
        </div>
        <div className='col-md-4'>
            <label>Charge Per Month<span className='text-danger'>*</span></label>
            <input
                type="number"
                step="0.01"
                name='charge_per_month'
                className="form-control"
                required
                placeholder="Charge Per Month"
                value={service.charge_per_month}
                onChange={(e) => handleServiceChange(e, index)}
            />
        </div>
    </div>
))}

                      <div className="row border p-2 rounded-sm mt-5">
                        <div className="col-md-6">
                          <label>Description</label>
                          <textarea
                            className="form-control"
                            name="description"
                            rows="3"
                            value={formData.description}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        <div className="col-md-6">
                          <label>Amenities</label>
                          {amenities && amenities.map((amenity, index) => (
                            <div key={index} className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`amenity-${index}`}
                                checked={formData.amenity.includes(amenity.id)}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  setFormData({
                                    ...formData,
                                    amenity: checked
                                      ? [...formData.amenity, amenity.id]
                                      : formData.amenity.filter(id => id !== amenity.id)
                                  });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={`amenity-${index}`}>{amenity.name}</label>
                            </div>
                          ))}
                        </div>
                        <div className="col-md-6">
                          <label>Privileges</label>
                          {privileges && privileges.map((privilege, index) => (
                            <div key={index} className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`privilege-${index}`}
                                checked={formData.privilege.includes(privilege.id)}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  setFormData({
                                    ...formData,
                                    privilege: checked
                                      ? [...formData.privilege, privilege.id]
                                      : formData.privilege.filter(id => id !== privilege.id)
                                  });
                                }}
                              />
                              <label className="custom-control-label" htmlFor={`privilege-${index}`}>{privilege.name}</label>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="row border p-2 rounded-sm mt-5">
                        <div className='col-md-6'>
                          <label>Status<span className='text-danger'>*</span></label>
                          <select
                            className="form-control my-4"
                            required
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                          >
                            <option value="draft" selected={formData.status === "draft"}>Draft</option>
                            <option value="approved" selected={formData.status === "approved"}>Approved</option>
                            <option value="pending" selected={formData.status === "pending"}>Pending</option>
                            <option value="rejected" selected={formData.status === "rejected"}>Rejected</option>
                            <option value="expired" selected={formData.status === "expired"}>Expired</option>
                          </select>
                        </div>
                      </div>

                      <div className="row border p-2 rounded-sm mt-5">
                        <div className='col-12'>
                          <label>Other Images <span className='text-danger'>*</span></label>
                        </div>
                      
                          <div  className='col-md-6 border p-2 rounded-sm '>
                          <div className="col-md-12">
                          <label>Other Files</label>
                          <FilePond
                            files={otherFiles}
                            allowMultiple={true}
                            onupdatefiles={handleUpdateFiles}
                          />
                        </div>
                          </div>
                      
                      </div>

                    </div>
                    <div className='col-12'>
                      <div className='mt-4'>
                        <button className="btn btn-primary ">
                          {isEditing ?<> <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> saving ... </> : 'Save'}
                        </button>
                        <button className="btn iq-bg-danger ml-3 " type='button' onClick={() => navigate(-1)}>Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default EditPropertyPage;
