import React, { useRef, useState,useEffect } from 'react';
import {  Modal } from 'reactstrap';
import { useEditNewsLetterMutation} from '../../features/property/propertySlice';
import { useNavigate } from 'react-router-dom';
function EditNewsLetterModal({ editnewsletterModal, toggleEditModal,newsLetterDetail }) {
    const [editNewsLetter,{isLoading}]=useEditNewsLetterMutation();

    const [showSuccess,setShowSuccess]=useState(false)
    const navigate = useNavigate();
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    email:""
  });


  useEffect(()=>{

    setFormData({
        email: newsLetterDetail&&newsLetterDetail.email

    })

    
  },[newsLetterDetail])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      try{

        console.log(formData)
        const response= await editNewsLetter({id:newsLetterDetail.id,body:formData}).unwrap();
        console.log(response)
        setShowSuccess(true)
        setFormData({
            
            email: ""
          })

        toggleEditModal()
      }catch(e){
        console.log(e)
        setShowSuccess(false)
      }

      
    } else {
      formRef.current.classList.add("was-validated");
    }
  };





  return (
    <Modal size='lg' className='modal-content' isOpen={editnewsletterModal} toggle={toggleEditModal}>
      <div className="modal-header">
        <h5 className="card-title">Edit {newsLetterDetail&&newsLetterDetail.email}</h5>
        <button type="button" className="close" onClick={toggleEditModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <form  className='p-2 needs-validation' onSubmit={handleSubmit} noValidate ref={formRef}>
        <div className='modal-body'>

{
    showSuccess?   

    <div class="alert alert-success" role="alert">
                             
                             <div class="iq-alert-text">News Letter edited successfully</div>
                             <button onClick={()=>{setShowSuccess(false)}} type="button" class="close" data-dismiss="alert" aria-label="Close">
                             <i class="ri-close-line" style={{color:"black"}}></i>
                             </button>
                          </div>

    :""
}

        



<div className="row  p-2 rounded-sm">
         
          <div className="col">
            <label>Email <span className='text-danger'>*</span></label>
            <input type="text" name='email' onChange={handleChange} className="form-control" required placeholder="" value={formData && formData.email}  />
          </div>
        
        </div>
       
        </div>

        <div className='d-flex justify-content-start'>
          
          {
            isLoading?
            <button type="submit" className="btn btn-primary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            saving...</button>
            :
            <button type="submit"  className="btn btn-primary">Save </button>
          }

         

         
          <button className="btn iq-bg-danger mx-2" onClick={toggleEditModal}>Cancel</button>
        </div>
      </form>
    </Modal>
  );
}

export default EditNewsLetterModal;







