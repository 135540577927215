import React, { useRef, useState } from 'react';
import {  Modal } from 'reactstrap';
import { useAddNewsLetterMutation } from '../../features/property/propertySlice';
import { useNavigate } from 'react-router-dom';
function AddNewsLetterModal({ addnewsletter, toggleModal }) {
    const [addNewsLetter,{isLoading}]=useAddNewsLetterMutation();
    const [addNewsLetter2,{isLoading:isLoadingCreate2}]=useAddNewsLetterMutation();

    const [showSuccess,setShowSuccess]=useState(false)
    const navigate = useNavigate();
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    email:""
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      try{

        console.log(formData)
        const response= await addNewsLetter(formData).unwrap();
        console.log(response)
        setShowSuccess(true)
        setFormData({
            
            email: ""
          })

        toggleModal()
      }catch(e){
        console.log(e)
        setShowSuccess(false)
      }

      
    } else {
      formRef.current.classList.add("was-validated");
    }
  };



  const handleSubmit2 = async (e) => {
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      try{

        console.log(formData)
        const response= await addNewsLetter2(formData).unwrap();
        console.log(response)
        setShowSuccess(true)
        setFormData({
            
            email: ""
          })

       
      }catch(e){
        console.log(e)
        setShowSuccess(false)
      }

      
    } else {
      formRef.current.classList.add("was-validated");
    }
  };


  return (
    <Modal size='lg' className='modal-content' isOpen={addnewsletter} toggle={toggleModal}>
      <div className="modal-header">
        <h3 className="card-title">Create news letter</h3>
        <button type="button" className="close" onClick={toggleModal} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <form  className='p-2 needs-validation' noValidate ref={formRef}>
        <div className='modal-body'>

{
    showSuccess?   

    <div class="alert alert-success" role="alert">
                             
                             <div class="iq-alert-text">News Letter created successfully</div>
                             <button onClick={()=>{setShowSuccess(false)}} type="button" class="close" data-dismiss="alert" aria-label="Close">
                             <i class="ri-close-line" style={{color:"black"}}></i>
                             </button>
                          </div>

    :""
}

        



<div className="row  p-2 rounded-sm">
         
          <div className="col">
            <label>Email <span className='text-danger'>*</span></label>
            <input type="text" name='email' onChange={handleChange} className="form-control" required placeholder="" value={formData.email}  />
          </div>
        
        </div>
       
        </div>

        <div className='modal-footer'>
          
          {
            isLoading?
            <button type="submit" className="btn btn-primary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Creating...</button>
            :
            <button type="button" onClick={handleSubmit} className="btn btn-primary">Create</button>
          }

          {
            isLoadingCreate2?
            <button type="submit" className="btn btn-secondary">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Creating...</button>
            :
            <button type="button" onClick={handleSubmit2} className="btn btn-secondary">Create & Create Another</button>
          }

         
          <button className="btn iq-bg-danger" onClick={toggleModal}>Cancel</button>
        </div>
      </form>
    </Modal>
  );
}

export default AddNewsLetterModal;







